import { BACKEND_BASE_URL } from "@/services";
import { handleResponse } from "@/services/handlers";
import Vue from "vue";
import { II18n, II18nService } from "./i18n.types";
import { IResponseError } from "@/entities/common/common.types";

export class I18nService implements II18nService {
  async getI18n(): Promise<II18n | undefined> {
    const { response, error } = await handleResponse<II18n, IResponseError>(
      Vue.$axiosNoAuth.get(`${BACKEND_BASE_URL}/api/i18n`)
    );
    if (error) {
      return undefined;
    } else {
      return response?.data;
    }
  }
}
