import "mutationobserver-shim";
import Vue from "vue";

import "material-icons/iconfont/material-icons.scss";
import "vue-loading-overlay/dist/vue-loading.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "./assets/scss/_index.scss";

import "@/plugins";

import App from "./App.vue";
import "./registerServiceWorker";

import { i18n, prepareI18n } from "./i18n";
import { useRouter } from "./router";
import { useStore } from "./store";
import { prepareServices } from "./services";

export const store = useStore();
const router = useRouter(store);

Vue.config.productionTip = false;

prepareServices(store);
prepareI18n(store).finally(() => {
  new Vue({
    store,
    router,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
});
