<template>
  <main>
    <div class="container d-flex flex-column">
      <div class="error-detail col-md-6 m-auto">
        <span class="error-icon">!</span>
        <p id="err-title" class="error-title">
          {{ $t("error404.title") }}
        </p>
        <p id="err-description" class="error-description">
          {{ $t("error404.description") }}
        </p>
        <router-link :to="{ path: '/' }">
          <span class="volver-caret">
            <span class="caret-left" />
          </span>
          <span class="volver-error">{{ $t("action.back") }}</span>
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "not-found-error-page",
  components: {},
};
</script>

<style lang="scss" scoped>
main {
  height: 70vh;
  width: 100vw;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.error-icon {
  font-size: 50px;
  font-weight: bold;
  color: #efeded;
  background: #212529;
  display: block;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  margin: auto;
  line-height: initial;
}

.error-title {
  margin-top: 1em;
  font-size: 2em;
  font-weight: 400;
}

.error-description {
  font-size: 1.1em;
  font-weight: 400;
}

.volver-caret {
  background-color: #ffd200;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  color: #22262e;
  font-size: 16px;
  display: inline-flex;
  vertical-align: text-top;

  .caret-left {
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    border-right: 5px solid;
    display: inline-block;
    height: 0;
    width: 0;
    margin: auto auto auto 5px;
  }
}

.volver-error {
  display: inline-block;
  color: #22262e;
  font-size: 1.1em;
  line-height: 1em;
  vertical-align: top;

  &:hover {
    text-decoration: underline;
  }
}
</style>
