
import { format } from "date-fns";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IDataLineFields } from "@/components/look/PDataLine/variables.types";

@Component
export class PDataLine extends Vue {
  @Prop({ required: true }) value!: any;
  @Prop({ required: false }) fields!: IDataLineFields;
  @Prop({ required: false, default: false }) showTitle!: boolean;
  @Prop({ required: false, default: true }) showBorder!: boolean;
  @Prop({ required: false, default: false }) widthContent!: boolean;
  @Prop({ required: false, default: false }) showSeparators!: boolean;
  @Prop({ required: false, default: "" }) classContainer!: string;
  @Prop({ required: false, default: "space-between" }) justifyContent!:
    | "space-between"
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-around"
    | "space-evenly";

  get giveMeClassContainer(): string {
    let returnTemp: string;
    if (this.showBorder) {
      returnTemp = "containerBorder_PDataLine";
    } else {
      returnTemp = "container_PDataLine";
    }
    if (this.widthContent) {
      returnTemp = returnTemp + " widthContent_PDataLine";
    }
    if (this.classContainer) {
      returnTemp = returnTemp + " " + this.classContainer;
    }

    return returnTemp;
  }
  get giveMeClassDataline(): string {
    return this.justifyContent + "_PDataLine";
  }
  get keys(): string[] {
    if (this.fields != undefined) {
      return Object.keys(this.fields);
    } else {
      return Object.keys(this.value);
    }
  }

  get keysActionIcons(): string[] {
    return Object.keys(this.fieldsActionIcons);
  }
  get fieldsActionIcons(): any {
    const fieldsActionIcons: any = {};

    Object.keys(this.fields).forEach((key: string) => {
      if (!key.indexOf("actionIconClick")) {
        fieldsActionIcons[key] = this.fields[key];
      }
    });
    return fieldsActionIcons;
  }
  isEmptyShow(key: string): boolean {
    if (!key.indexOf("actionIconClick")) {
      return false;
    } else
      return !(
        this.fields[key].emptyShow != undefined &&
        !this.fields[key].emptyShow &&
        (this.value[key] === "" || !this.value[key])
      );
  }
  getReturTemp(key: string, property: string): boolean {
    if (this.fields[key].showCondition.operator === "equal") {
      if (
        this.value[this.fields[key].showCondition.field] ===
        this.fields[key].showCondition.condition[property]
      ) {
        return true;
      }
    } else if (this.fields[key].showCondition.operator === "noequal") {
      if (
        this.value[this.fields[key].showCondition.field] !=
        this.fields[key].showCondition.condition[property]
      ) {
        return true;
      }
    } else if (this.fields[key].showCondition.operator === "noContains") {
      if (
        this.value[this.fields[key].showCondition.field].indexOf(
          this.fields[key].showCondition.condition[property]
        ) === -1
      ) {
        return true;
      }
    }
    return false;
  }
  showCondition(key: string): boolean {
    if (this.fields[key].showCondition != undefined) {
      let returTemp = false;
      for (const property in this.fields[key].showCondition.condition) {
        returTemp = this.getReturTemp(key, property);
      }
      return returTemp;
    } else {
      return true;
    }
    // field: "mimeTye", operator: "equal", condition: { value_1: "", value_2: "" }
  }
  getReturnTemp(key: string): string {
    if (Array.isArray(this.value[key])) {
      let returnTemp = "";
      this.value[key].forEach((element: string) => {
        returnTemp = returnTemp + element + ",  ";
      });
      return returnTemp.substring(0, returnTemp.length - 3);
    } else {
      if (this.value[key] === "") {
        return "--";
      } else {
        return this.value[key];
      }
    }
  }
  dameTexto(key: string): string {
    if (this.fields[key].format != undefined) {
      if (this.value[key] != "") {
        return format(new Date(this.value[key]), this.fields[key].format);
      } else {
        return "--";
      }
    } else if (this.fields[key].maxSizeText != undefined) {
      return this.limitarCaracteres(key);
    } else if (this.fields[key].transtaleText != undefined) {
      return this.$t(this.fields[key].transtaleText).toString();
    } else if (this.fields[key].transtaleItem != undefined) {
      return this.$t(this.fields[key].transtaleItem + this.value[key]).toString();
    } else if (this.fields[key].view != undefined) {
      return "";
    } else {
      return this.getReturnTemp(key);
    }
  }
  dameview(key: string): string {
    if (this.fields[key].view != undefined) {
      if (this.fields[key].view === "validate") {
        if (this.value[key]) {
          return this.$t(this.fields[key].trueText).toString();
        } else {
          return this.$t(this.fields[key].falseText).toString();
        }
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
  dameViewClass(key: string): string {
    if (this.fields[key].view != undefined) {
      if (this.fields[key].view === "validate") {
        if (this.value[key]) {
          return this.fields[key].trueVariant;
        } else {
          return this.fields[key].falseVariant;
        }
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
  giveMeWidthFiled(key: string): string {
    if (this.fields[key].width != undefined) {
      return "flex: 0 0 " + this.fields[key].width;
    } else {
      return "flex: 1 1 auto";
    }
  }
  dameStyles(key: string): string {
    if (this.fields[key].style != undefined) {
      return this.fields[key].style;
    } else {
      return "";
    }
  }
  limitarCaracteres(key: string): string {
    const maxSizeText = this.fields[key].maxSizeText;
    if (this.value[key]?.length > maxSizeText) {
      return this.value[key].slice(0, maxSizeText) + "...";
    } else {
      if (this.value[key] === "") {
        return "--";
      } else {
        return this.value[key];
      }
    }
  }
  giveMeDivider(key: string): string {
    if (this.fields[key].divider != undefined && this.fields[key].divider) {
      return "fieldDataLineDivider";
    } else {
      return "";
    }
  }
  giveMeTitleTitle(key: string): string {
    if (this.fields[key].title != undefined) {
      return this.fields[key].title;
    } else {
      return "";
    }
  }
  dameTop(key: string): string {
    const caracteresAncho = 56;
    return "top:-" + (this.value[key].length / caracteresAncho / 2 + 2) + "rem;";
  }
  dameClase(key: string): string {
    if (
      this.fields[key].maxSizeText != undefined &&
      this.value[key] &&
      this.value[key].length > this.fields[key].maxSizeText
    ) {
      return "datalineMaxSize";
    } else if (!key.indexOf("actionClick")) {
      return "datalineAction";
    } else if (this.fields[key].list != undefined) {
      return "datalineList";
    } else {
      return "";
    }
  }

  ampliarKey = "";
  click(key: string): void {
    if (
      this.fields[key].maxSizeText != undefined &&
      this.value[key] &&
      this.value[key].length > this.fields[key].maxSizeText
    ) {
      this.ampliarKey = key;
    } else if (!key.indexOf("actionClick") || !key.indexOf("actionIconClick")) {
      this.$emit("click", {
        id: this.value[this.fields[key].idItem],
        idItem: this.fields[key].idItem,
        ...this.fields[key].click,
      });
    }
  }
  mouseleave(): void {
    this.ampliarKey = "";
  }
  verAmplicacion(key: string): boolean {
    return this.ampliarKey === key;
  }
}
export default PDataLine;
