
import { Component, Vue } from "vue-property-decorator";
import IconDashboard from "@/components/common/icon/IconDashboard.vue";

@Component({
  name: "dashboard-header",
  components: {
    IconDashboard,
  },
})
export default class DashboardHeader extends Vue {
  constructor() {
    super();
  }
}
