
import { Component, Prop, Vue } from "vue-property-decorator";
import PIcon from "@/components/look/PIcon/PIcon.vue";

@Component({
  components: {
    PIcon,
  },
})
export class PPage extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ required: false, default: "IconLogic" }) icon!: string;
}
export default PPage;
