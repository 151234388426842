
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export class PFormInputFile extends Vue {
  @Prop({ required: true }) value!: File;
  @Prop({ required: false }) accept!: string;
  @Prop({ required: false, default: "" }) label!: string;
  @Prop({ required: false, default: "Selecionar fichero" }) placeholder!: string;
  @Prop({ required: false, default: undefined }) rules!: string;
  @Prop({ required: false, default: "" }) invalid_feedback!: string;
  file: any = "";
  fileInputValidation = "";
  click(): void {
    (this.$refs.file as any).click();
  }
  handleFileUpload(): void {
    this.file = (this.$refs.file as any).files[0];
    this.fileInputValidation = this.file.name;
    this.$emit("input", this.file);
    this.$nextTick(() => {
      //esto es para que funcione la validacion
      this.$emit("change", this.file);
    });
  }
  get textUpload(): string {
    return this.file?.name || this.placeholder;
  }
}
export default PFormInputFile;
