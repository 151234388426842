import { render, staticRenderFns } from "./IconDESC.vue?vue&type=template&id=fd8085fa&scoped=true&"
import script from "./IconDESC.vue?vue&type=script&lang=ts&"
export * from "./IconDESC.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd8085fa",
  null
  
)

export default component.exports