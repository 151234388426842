
import { Component, Vue } from "vue-property-decorator";
import POptionBarNew from "@/components/look/POptionBarNew/POptionBarNew.vue";
import AdminClientesComponent from "@/components/administracion/clientes/AdminClientesComponent.vue";
import AdminUsuariosComponent from "@/components/administracion/usuarios/AdminUsuariosComponent.vue";
import AdminColumnasComponent from "@/components/administracion/columnas/AdminColumnasComponent.vue";
import AdminCargasComponent from "@/components/administracion/cargas/AdminCargasComponent.vue";
import { IOptionBarItem, PPage } from "@/components/look";
import { Action } from "vuex-class";
import { IColumnaResponse } from "@/services";

@Component({
  components: {
    PPage,
    POptionBarNew,
    AdminClientesComponent,
    AdminUsuariosComponent,
    AdminColumnasComponent,
    AdminCargasComponent,
  },
})
export default class AdministracionPage extends Vue {
  @Action("fetchColumna")
  fetchColumna!: (codTabla: string) => Promise<IColumnaResponse | undefined>;

  columnsConfigCarga: IColumnaResponse | null = null;
  columnsConfigCliente: IColumnaResponse | null = null;
  columnsConfigUsuario: IColumnaResponse | null = null;

  async created(): Promise<void> {
    const responses = await Promise.all([
      this.fetchColumna("cargas"),
      this.fetchColumna("clientes"),
      this.fetchColumna("usuarios"),
    ]);
    this.columnsConfigCarga = responses[0] || {
      codTabla: "cargas",
      codColumna: "",
      columnas: [],
    };
    this.columnsConfigCliente = responses[1] || {
      codTabla: "clientes",
      codColumna: "",
      columnas: [],
    };
    this.columnsConfigUsuario = responses[2] || {
      codTabla: "usuarios",
      codColumna: "",
      columnas: [],
    };
  }

  get optionSelected(): string {
    return this.$route.name ?? "usuarios";
  }

  set optionSelected(option: string) {
    this.$router.push({ path: `/administracion/${option}` });
  }

  get options(): IOptionBarItem[] {
    return [
      {
        text: this.$t("label.clientes").toString(),
        value: "clientes",
        hidden: !this.$ability.can("read", "AdminClientes"),
      },
      {
        text: this.$t("label.usuarios").toString(),
        value: "usuarios",
        hidden: !this.$ability.can("read", "AdminUsuarios"),
      },
      {
        text: this.$t("label.columnas").toString(),
        value: "columnas",
        hidden: !this.$ability.can("read", "AdminColumnas"),
      },
      {
        text: this.$t("administracion.cargas.descripcion").toString(),
        value: "cargas",
        hidden: !this.$ability.can("read", "AdminCargas"),
      },
    ];
  }
}
