
import * as d3 from "d3";
import { Component, Prop, Vue } from "vue-property-decorator";
import { EventBus } from "@/event-bus";

@Component
export default class PieD3 extends Vue {
  @Prop({
    required: true,
    default: [
      { name: "P100", value: 8123674.87 },
      { name: "P500", value: 10536298.39 },
      { name: "P200", value: 4630000.24 },
      { name: "P20", value: 5839665.03 },
      { name: "P50", value: 6746453.84 },
    ],
  })
  data!: [{ name: string; value: number }];

  constructor() {
    super();
  }
  mounted() {
    this.generateArc();
    EventBus.$on("update-chart", (data: [{ name: string; value: number }]) => {
      this.data = data;
      this.generateArc();
    });
  }
  generateArc() {
    const width = 320;
    const height = 250;

    // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
    const radius = 80;

    d3.select("#p-pie").selectAll("svg").remove();
    const svg = d3
      .select("#p-pie")
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("class", "slices")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    svg.append("g").classed("labels", true);

    const data = this.data as any;

    // set the color scale
    const color = d3.scaleOrdinal().domain(data).range(["#cca701", "#ffd102", "#ffec99"]);

    // Compute the position of each group on the pie:
    const pie = d3.pie().value((d) => {
      // @ts-ignore
      return d.value;
    });
    const data_ready = pie(data);
    // filters go in defs element
    const defs = svg.append("defs");

    /********* Filter*******/
    const filter = defs.append("filter").attr("id", "drop-shadow2").attr("height", "130%");

    filter
      .append("feGaussianBlur")
      .attr("in", "SourceAlpha")
      .attr("stdDeviation", 3)
      .attr("result", "blur");

    filter.append("feOffset").attr("in", "blur").attr("result", "offsetBlur");

    filter
      .append("feFlood")
      .attr("in", "offsetBlur")
      .attr("flood-color", "#a5a5a5")
      .attr("flood-opacity", "1")
      .attr("result", "offsetColor");

    filter
      .append("feComposite")
      .attr("in", "offsetColor")
      .attr("in2", "offsetBlur")
      .attr("operator", "in")
      .attr("result", "offsetBlur");

    const feMerge = filter.append("feMerge");

    feMerge.append("feMergeNode").attr("in", "offsetBlur");
    feMerge.append("feMergeNode").attr("in", "SourceGraphic");

    const outerArc = d3
      .arc()
      .innerRadius(radius * 1.2)
      .outerRadius(radius * 1.2);

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    svg
      .selectAll("whatever")
      .data(data_ready)
      .enter()
      .append("path")
      // @ts-ignore
      .attr("d", d3.arc().innerRadius(0).outerRadius(radius))
      // @ts-ignore
      .attr("fill", function (d) {
        return color(d.data.toString());
      })
      .on("mouseenter", function () {
        // @ts-ignore
        d3.select(this)
          .transition()
          .duration(200)
          // @ts-ignore
          .attr(
            "d",
            // @ts-ignore
            d3
              .arc()
              .innerRadius(0)
              .outerRadius(radius * 1.2)
          )
          .style("filter", "url(#drop-shadow2)");
      })
      .on("mouseout", function () {
        // @ts-ignore
        d3.select(this)
          .transition()
          .duration(200)
          // @ts-ignore
          .attr("d", d3.arc().innerRadius(0).outerRadius(radius))
          .style("filter", null);
      });

    svg
      .append("g")
      .attr("font-family", "roboto")
      .attr("font-size", 11)
      .attr("text-anchor", "middle")
      .selectAll("text")
      .data(data_ready)
      .join("text")
      .attr("class", "pie-label")
      //.attr("transform", function(d) { return "translate(" + outerArc.centroid(d) + ")"; })
      .attr("transform", function (d) {
        // @ts-ignore
        const pos = outerArc.centroid(d);
        pos[0] = radius * 1.2 * (midAngle(d) < Math.PI ? 1 : -1);
        return "translate(" + pos + ")";
      })
      .style("text-anchor", function (d) {
        return midAngle(d) < Math.PI ? "start" : "end";
      })
      .call((text) =>
        text
          .append("tspan")
          .attr("x", 0)
          .attr("y", "-0.5em")
          //.attr("font-weight", "bold")
          .style("fill", "#979797")
          // @ts-ignore
          .text((d) => d.data.name)
      )
      .call((text) =>
        text
          .filter((d) => d.endAngle - d.startAngle > 0.25)
          .append("tspan")
          .attr("x", 0)
          .attr("y", "0.5em")
          .attr("fill-opacity", 0.7)
          //.style("fill","#4c4c4c")
          // @ts-ignore
          .text((d) => d.data.value.toLocaleString("es-ES"))
      );

    function midAngle(d: d3.PieArcDatum<number | { valueOf(): number }>) {
      return d.startAngle + (d.endAngle - d.startAngle) / 2;
    }
  }
}
