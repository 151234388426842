
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class IconSaldos extends Vue {
  @Prop() public width!: number;
  @Prop() public height!: number;
  @Prop() public color!: string;

  constructor() {
    super();
  }
}
