import { BACKEND_BASE_URL } from "@/services";
import { APPLICATION_JSON } from "@/constans";
import { handleResponse } from "@/services/handlers";
import { IResponseError } from "@/entities/common/common.types";
import { ICountryConfigData } from "@/entities/";
import Vue from "vue";
import { EventBus } from "@/event-bus";
import {
  IDelegacionesResponse,
  IPaisParams,
  IPaisResponse,
  IPaisService,
} from "@/services/saldosonline/pais/pais.types";

export class PaisService implements IPaisService {
  async _fetch(params?: IPaisParams): Promise<IPaisResponse | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };

    const { response, error } = await handleResponse<IPaisResponse, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/pais`, { headers, params })
    );

    if (error) {
      EventBus.$emit("on-service-pais-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }
  async _fetchDelegaciones(pais: number): Promise<IDelegacionesResponse | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const params = { pais };

    const { response, error } = await handleResponse<IDelegacionesResponse, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/delegacion`, { headers, params })
    );

    if (error) {
      EventBus.$emit("on-service-pais-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }
  async getConfig(code: string): Promise<ICountryConfigData | undefined> {
    const params: Record<string, string> = {
      code,
    };
    const { response, error } = await handleResponse<ICountryConfigData, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/configuration/v1/config`, { params })
    );
    if (error) {
      return undefined;
    } else {
      return response?.data;
    }
  }
}
