var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.isConfirm)?_c('div',{staticClass:"container_VideosPlayForm"},[_c('VideosMaximizedForm',{attrs:{"show":_vm.verModal,"nombre-video":_vm.valueLocal.nombreVideo ? _vm.valueLocal.nombreVideo : '',"url-video":_vm.valueLocal.urlVideo,"descripcion-confidencial":_vm.$t('label.videos.prosegur.confidencial')},on:{"close":function($event){_vm.verModal = false}}}),_c('PColapsable',{attrs:{"value":_vm.isColapsableDatosNovedad},on:{"change":function($event){return _vm.validarColapsable($event, 'datos-novedad')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("label.datosNovedad")))]},proxy:true}],null,false,2226213980)},[_c('PDataLine',{attrs:{"value":_vm.valuesVideo,"fields":_vm.fieldsVideo}}),(!_vm.aceptaDatosReadonly)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('PFormSwitch',{attrs:{"label":_vm.$t('label.aceptarDatos'),"readonly":_vm.aceptaDatosReadonly},on:{"change":function($event){return _vm.action('aceptarDatos')}},model:{value:(_vm.visionadoLocal.aceptaDatos),callback:function ($$v) {_vm.$set(_vm.visionadoLocal, "aceptaDatos", $$v)},expression:"visionadoLocal.aceptaDatos"}})],1):_c('div',{staticClass:"aceptaDatosReadonly_VideosPlayForm"},[_c('PDataLine',{attrs:{"justifyContent":"space-around","widthContent":true,"classContainer":_vm.visionadoLocal.aceptaDatos ? 'ok' : 'ko',"value":{ aceptaDatos: _vm.visionadoLocal.aceptaDatos },"fields":{
          aceptaDatos: {
            width: '100%',
            emptyShow: true,
            view: 'validate',
            trueText: _vm.$t('label.datosAceptados'),
            falseText: _vm.$t('label.datosRechazados'),
            trueVariant: 'ok fieldTextoDataLine',
            falseVariant: 'ko fieldTextoDataLine',
          },
        }}})],1)],1),_c('PForm',{staticClass:"aceptaDatos_VideosPlayForm",attrs:{"textAction":_vm.$t('action.aceptar'),"showCancel":false,"buttonsAlign":"right"},on:{"accept":function($event){return _vm.action('aceptar')}},model:{value:(_vm.validado),callback:function ($$v) {_vm.validado=$$v},expression:"validado"}},[(_vm.showDetailsVideo)?_c('PColapsable',{attrs:{"value":_vm.isColapsableVideo},on:{"change":function($event){return _vm.validarColapsable($event, 'video')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("label.video"))+" ")]},proxy:true}],null,false,4189710904)},[(_vm.showDetailsVideo)?_c('div',{staticClass:"textMessage_VideosPlayForm"},[_c('span',[_vm._v(" "+_vm._s(this.$t("label.videos.prosegur.confidencial"))+" ")])]):_vm._e(),(
          _vm.aceptaDatosReadonly &&
          _vm.visionadoLocal.aceptaDatos &&
          _vm.valueLocal.urlVideo.indexOf('/') > 0
        )?_c('div',{staticClass:"containerVideo_VideosPlayForm"},[_c('video',{ref:_vm.valueLocal.nombreVideo,attrs:{"autoplay":"","controls":"","disableRemotePlayback":"","controlslist":"nodownload nofullscreen noremoteplayback","width":"100%"}},[_c('source',{attrs:{"src":_vm.valueLocal.urlVideo,"type":"video/mp4"}}),_c('track',{attrs:{"kind":"subtitles","src":""}})])]):_vm._e(),_c('div',{staticClass:"buttons_VideosMaximizar"},[_c('PButton',{attrs:{"variant":"secondary"},on:{"click":_vm.verVideoMaximizado}},[_c('b-icon-fullscreen',{staticClass:"align-middle mr-1"}),_vm._v(" "+_vm._s(_vm.$t("label.maximizar")))],1)],1),(_vm.aceptaDatosReadonly && _vm.visionadoLocal.aceptaDatos)?_c('div',{staticClass:"text-center"},[(!_vm.aceptaNovedadReadonly)?_c('PFormRadioButton',{attrs:{"label":_vm.$t('label.aceptarNovedades')},model:{value:(_vm.visionadoLocal.aceptaNovedad),callback:function ($$v) {_vm.$set(_vm.visionadoLocal, "aceptaNovedad", $$v)},expression:"visionadoLocal.aceptaNovedad"}}):_vm._e()],1):_vm._e(),(_vm.aceptaNovedadReadonly && _vm.visionadoLocal.aceptaDatos)?_c('div',{staticClass:"aceptaDatosReadonly_VideosPlayForm"},[_c('PDataLine',{attrs:{"justifyContent":"space-around","widthContent":true,"classContainer":_vm.visionadoLocal.aceptaNovedad ? 'ok' : 'ko',"value":{
            aceptaNovedad: _vm.visionadoLocal.aceptaNovedad,
          },"fields":{
            aceptaNovedad: {
              width: '100%',
              emptyShow: true,
              view: 'validate',
              trueText: _vm.$t('label.novedadAceptada'),
              falseText: _vm.$t('label.novedadRechazada'),
              trueVariant: 'ok fieldTextoDataLine',
              falseVariant: 'ko fieldTextoDataLine',
            },
          }}})],1):_vm._e()]):_vm._e(),_c('PRow',[_c('PCol',{attrs:{"md":"12"}},[(!_vm.aceptaNovedadReadonly)?_c('PFormInputText',{attrs:{"label":_vm.$t('label.observaciones'),"type":"textarea","rows":5,"rules":'max:2000'},model:{value:(_vm.visionadoLocal.observaciones),callback:function ($$v) {_vm.$set(_vm.visionadoLocal, "observaciones", $$v)},expression:"visionadoLocal.observaciones"}}):_c('PDataLine',{attrs:{"showBorder":false,"value":{ observaciones: _vm.visionadoLocal.observaciones },"fields":{
            observaciones: {
              title: this.$t('label.observaciones'),
              width: '100%',
              emptyShow: true,
            },
          }}})],1)],1)],1)],1):_c('div',[_c('PConfirm',{attrs:{"textButtonAccept":_vm.$t('action.aceptar')},on:{"accept":function($event){return _vm.action('aceptarConfirm')},"cancel":function($event){_vm.isConfirm = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("title.videos.noAcepta"))+" ")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("title.videos.descripcionConfirm"))+" ")]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }