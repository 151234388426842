
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IUsuarioFilter } from "@/services";
import VueMethods from "@/vue-methods";
import { Getter } from "vuex-class";
import { Locale } from "@/store";

@Component({
  mixins: [VueMethods],
})
export class UsuarioFilterForm extends Vue {
  @Prop({ required: true }) value!: IUsuarioFilter;
  @Getter("getLocale") locale!: Locale;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }
  optionsActiva = [
    { label: this.$t("label.si"), value: "SI" },
    { label: this.$t("label.no"), value: "NO" },
  ];
  optionsTipoUsuario = [
    { label: this.$t("label.prosegur"), value: "CORPORATE" },
    { label: this.$t("label.cliente"), value: "CUSTOMER" },
  ];
  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;
  valueLocal = {
    tipoUsuario: "",
    codEmail: "",
    codUsuario: "",
    nombreApellido: "",
    celular: "",
    fechaBaja: "",
    fechaCreacion: "",
    fechaModificacion: "",
    ntfActiva: false,
    active: false,
    cliente: 0,
    ntfActivaDesc: "",
    activeDesc: "",
  };
  initialValueLocal = {
    tipoUsuario: "",
    codEmail: "",
    codUsuario: "",
    nombreApellido: "",
    celular: "",
    fechaBaja: "",
    fechaCreacion: "",
    fechaModificacion: "",
    ntfActiva: false,
    active: false,
    ntfActivaDesc: "",
    activeDesc: "",
  };
  limpiar(): void {
    this.valueLocal.tipoUsuario = "";
    this.valueLocal.codEmail = "";
    this.valueLocal.codUsuario = "";
    this.valueLocal.nombreApellido = "";
    this.valueLocal.celular = "";
    this.valueLocal.fechaBaja = "";
    this.valueLocal.fechaCreacion = "";
    this.valueLocal.fechaModificacion = "";
    this.valueLocal.ntfActiva = false;
    this.valueLocal.active = false;
    this.valueLocal.ntfActivaDesc = "";
    this.valueLocal.activeDesc = "";
    if (!this.isInitialObject(this.value, this.initialValueLocal)) {
      this.$emit("input", this.valueLocal);
      this.$emit("cancel", { action: "clean" });
    }
  }
  action(action: string): void {
    this.$emit("input", this.valueLocal);
    this.$emit("action", { data: this.valueLocal, action: action });
  }
  validado = false;
}
export default UsuarioFilterForm;
