import { IPageable, ISort } from "@/entities";

export const BACKEND_BASE_URL = process.env.VUE_APP_SVC_BACKEND_BASE_URL as string;

export interface IResponse<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: IPageable;
  size: number;
  sort: ISort;
  totalElements: number;
  totalPages: number;
}

export enum EDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export interface IPaged {
  direction?: EDirection;
  orderBy?: string;
  page?: number;
  size?: number;
  totalElements?: number;
}
export const iniPaged: IPaged = {
  direction: EDirection.ASC,
  orderBy: "fecCrear",
  page: 1,
  size: 10,
  totalElements: 0,
};
