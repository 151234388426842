import Vue from "vue";
import Vue2Filters from "vue2-filters";

Vue.use(Vue2Filters, {
  currency: {
    decimalDigits: 2,
    thousandsSeparator: ".",
    decimalSeparator: ",",
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
  },
});
