
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export class PSidebar extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Watch("value", { immediate: true })
  onChangeValue(): void {
    this.showSidebar = this.value;
  }
  @Prop({ required: false, default: "40%" }) width!: string;
  @Prop({ required: false, default: "" }) title!: string;
  @Prop({ required: false }) icon!: string;
  @Prop({ required: false, default: "text" }) iType!: string;
  @Prop({ required: false, default: "#6f6f6f" }) iColor!: string;
  @Prop({ required: false, default: "25" }) iSize!: number;
  @Prop({ required: true }) textButton!: string;
  @Prop({ required: false, default: "secondary" }) bVariant!: string;

  showSidebar = false;

  get widthComp(): string {
    if (this.width != "") {
      return "width:" + this.width + ";";
    } else {
      return "";
    }
  }
  showSidebarFunction(): void {
    this.showSidebar = true;
    this.$emit("input", this.showSidebar);
  }
  close(): void {
    this.showSidebar = false;
    this.$emit("input", this.showSidebar);
  }
}
export default PSidebar;
