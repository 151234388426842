import { APPLICATION_JSON } from "@/constans";
import {
  BACKEND_BASE_URL,
  ICargaParams,
  ICargaResponse,
  ICargaService,
  serialize,
} from "@/services";
import { handleResponse } from "@/services/handlers";
import { IResponseError } from "@/entities/common/common.types";
import { EventBus } from "@/event-bus";
import Vue from "vue";
import { ICarga, IParametro } from "@/entities";

export class CargaService implements ICargaService {
  async fetch(params: ICargaParams): Promise<ICargaResponse | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<ICargaResponse, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/carga`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );
    if (error) {
      EventBus.$emit("on-service-carga-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async fetchEstadosCarga(): Promise<IParametro[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IParametro[], IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/carga/estado`, { headers })
    );
    if (error) {
      EventBus.$emit("on-service-carga-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async removeAll(cargas: ICarga[]): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<any, IResponseError>(
      Vue.$axios.post(`${BACKEND_BASE_URL}/api/carga/remove`, cargas, { headers })
    );
    if (error || response!.status != 200) {
      EventBus.$emit("on-service-remove-error", error!.data);
      return undefined;
    } else {
      return response!.data;
    }
  }
}
