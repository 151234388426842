
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { ICliente, IClienteAll } from "@/entities";
import { IDocumentacionParams } from "@/services";
import { todosCliente } from "@/constans";

@Component({
  name: "quick-filter",
})
export default class QuickFilter extends Vue {
  @Prop() public params!: IDocumentacionParams;
  @Prop({ required: false, default: false }) public showOptionTodos!: boolean;
  @Prop({ required: false, default: "" }) public documentType!: string;
  @Action("setClienteSelected") setClienteSelected!: (cliente?: ICliente) => Promise<void>;

  @Getter("getClientesAll") getClientesAll!: IClienteAll[];
  @Getter("getClienteSelected") getClienteSelected!: ICliente;

  public admin = false;
  private optionTodos = todosCliente;

  get clienteSelected(): number {
    const cliente = this.getClienteSelected;
    return (
      cliente?.id ??
      this.clientes.find((c) => c.codCliente === this.params.codCliente)?.id ??
      this.clientes.find((c) => c.id?.toString() === this.params.pkCliente)?.id ??
      this.optionTodos.id!
    );
  }

  set clienteSelected(clienteId: number) {
    if (clienteId && clienteId !== this.optionTodos.id) {
      this.setClienteSelected(this.getCliente(clienteId)).then(() => {
        return this.onChangeCliente(this.clienteSelected);
      });
    } else {
      this.setClienteSelected().then(() => {
        if (this.showOptionTodos) {
          this.onChangeCliente();
        }
      });
    }
  }

  get clientes(): ICliente[] {
    let clientes = Object.assign([], this.getClientesAll);
    if (clientes.length > 1 && this.showOptionTodos) {
      clientes.unshift(this.optionTodos);
    }

    return clientes;
  }

  getCliente(clienteId: number): ICliente {
    return this.clientes.find((cliente) => cliente.id === clienteId) as ICliente;
  }

  async mounted(): Promise<void> {
    this.admin = this.clientes.length > 1;

    if (!this.getClienteSelected) {
      if (this.params.codCliente || this.params.pkCliente) {
        const clienteAll = this.clientes.find(
          (cliente) =>
            cliente.codCliente === this.params.codCliente ||
            cliente.id?.toString() === this.params.pkCliente
        );
        if (clienteAll && clienteAll.id !== this.optionTodos.id) {
          await this.setClienteSelected(clienteAll as ICliente);
        } else {
          await this.loadFirstCliente();
        }
      } else {
        await this.loadFirstCliente();
      }

      if (!this.showOptionTodos) {
        this.$emit("change", this.params);
      }
    }
  }

  async loadFirstCliente(): Promise<void> {
    const cliente = this.clientes[0] as ICliente;
    if (cliente.id !== this.optionTodos.id) {
      await this.setClienteSelected(cliente);
    } else {
      await this.setClienteSelected();
    }
  }

  async onChangeCliente(clienteId?: number): Promise<void> {
    if (clienteId && clienteId !== -1) {
      if (this.documentType == "ByImportacion" || this.documentType == "ByNonHistorical") {
        this.params.pkCliente = clienteId.toString();
      } else {
        this.params.codCliente = this.getClienteSelected.codCliente;
      }
    }
    this.$emit("change", this.params);
  }
}
