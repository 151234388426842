
import { Component, Prop, Vue } from "vue-property-decorator";
import PIcon from "@/components/look/PIcon/PIcon.vue";

@Component({
  components: {
    PIcon,
  },
})
export class VideosMaximizedForm extends Vue {
  @Prop({ required: true }) show!: boolean;
  @Prop({ required: true }) nombreVideo!: string;
  @Prop({ required: true }) urlVideo!: string;
  @Prop({ required: true }) descripcionConfidencial!: string;
}

export default VideosMaximizedForm;
