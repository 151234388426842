
import VueFilters from "@/vue-filters";
import { BvTableFieldArray } from "bootstrap-vue";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Action } from "vuex-class";
import { IDetallesSaldo, ISaldo } from "@/entities";

@Component({
  name: "detalle-saldo",
  mixins: [VueFilters],
})
export default class DetalleSaldo extends Vue {
  @Prop() public saldo!: ISaldo;

  @Action("fetchSaldoDetalle") fetchDetalle!: (id: number) => Promise<IDetallesSaldo[] | undefined>;

  @Action("setLoading") setLoading!: (loading: boolean) => void;

  detalles: IDetallesSaldo[] | null = null;

  get fields(): BvTableFieldArray {
    return [
      {
        key: "calidadDenominacion.denominacion.mercancia.desParametro",
        label: "MERCANCÍA",
        sortable: true,
        thClass: "table-header-detail",
        tdClass: "table-cell-detail",
      },
      {
        key: "calidadDenominacion.denominacion.codDenominacion",
        label: "DENOMINACIÓN",
        sortable: true,
        thClass: "table-header-detail",
        tdClass: "table-cell-detail",
      },
      {
        key: "calidadDenominacion.desCalidad",
        label: "CALIDAD",
        sortable: true,
        thClass: "table-header-detail",
        tdClass: "table-cell-detail",
      },
      {
        key: "saldoAnterior",
        label: "SALDO ANTERIOR",
        sortable: true,
        thClass: "table-header-detail",
        tdClass: "table-cell-detail",
        formatter: (value: string, key: string, item: IDetallesSaldo) =>
          this.filterNumber(item.saldoAnterior as number, 2),
      },
      {
        key: "numEntradas",
        label: "ENTRADAS",
        sortable: true,
        thClass: "table-header-detail",
        tdClass: "table-cell-detail",
        formatter: (value: string, key: string, item: IDetallesSaldo) =>
          this.filterNumber(item.entradas as number, 2),
      },
      {
        key: "salidas",
        label: "SALIDAS",
        sortable: true,
        thClass: "table-header-detail",
        tdClass: "table-cell-detail",
        formatter: (value: string, key: string, item: IDetallesSaldo) =>
          this.filterNumber(item.salidas as number, 2),
      },
      {
        key: "cantidad",
        label: "CANTIDAD",
        sortable: true,
        thClass: "table-header-detail",
        tdClass: "table-cell-detail",
        formatter: (value: string, key: string, item: IDetallesSaldo) =>
          this.filterNumber(item.cantidad as number, 2),
      },
      {
        key: "saldoActual",
        label: "SALDO ACTUAL",
        sortable: true,
        thClass: "table-header-detail",
        tdClass: "table-cell-detail",
        formatter: (value: string, key: string, item: IDetallesSaldo) =>
          this.filterNumber(item.saldoActual as number, 2),
      },
    ];
  }
  constructor() {
    super();
  }
  mounted(): void {
    this.fetch();
  }
  async fetch(): Promise<void> {
    const aux = await this.fetchDetalle(this.saldo.id);
    if (aux) {
      this.detalles = aux;
    }
  }
}
