
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "icon-filter",
  components: {},
})
export class IconFilter extends Vue {
  @Prop({ default: 48 }) size!: number;
  @Prop({ default: "#ffffff" }) color!: string;
  get sizeComp(): number {
    return typeof this.size == "string" ? parseInt(this.size) + 4 : this.size + 4;
  }
  constructor() {
    super();
  }
}
export default IconFilter;
