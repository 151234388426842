import Vue from "vue";
import Vuex, { Store } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { IState } from "./store.types";

Vue.use(Vuex);

const state: IState = {
  version: process.env.VUE_APP_VERSION as string,
  locale: "",
  currentLocation: "",
  paises: [],
  // User
  signedIn: false,
  loggedUser: undefined,
  columnas: [],
  // Saldos
  saldosPageQuickFilter: {
    id: 0,
    divisa: [],
  },
  //Transferencias
  transferenciasPageQuickFilter: {
    id: 0,
    divisa: [],
    tipo: "",
  },
  // Clientes
  clientes: [],
  clientesAll: [],
  clienteSelected: undefined,
  clienteXDivisa: [],
  delegacionesCliente: [],
  // Divisas
  divisas: [],
  costes: [],
  disclaimer: "",
};

export const useStore = (): Store<IState> =>
  new Vuex.Store<IState>({
    state,
    actions,
    mutations,
    getters,
    plugins: [createPersistedState({ paths: ["currentLocation", "locale"] })],
  });
