import { ActionContext } from "vuex";
import { IRootState, IState } from "@/store";
import { IActionsMock } from "@/store/actions/actions.types";
import { mockGetters } from "@/store/getters/getters.mock";
import { mockRootState, mockState } from "@/store/store.mock";

export const mockActionsContext = (): ActionContext<IState, IRootState> => ({
  commit: jest.fn(),
  dispatch: jest.fn(),
  state: mockState(),
  getters: mockGetters(),
  rootState: mockRootState(),
  rootGetters: {},
});

export const mockActions = (): IActionsMock =>
  <IActionsMock>{
    initialize: jest.fn(),
    setLoading: jest.fn(),
    setCurrentLocation: jest.fn(),
    setCurrentLocale: jest.fn(),
    fetchLoggedUser: jest.fn(),
    fetchSaldos: jest.fn(),
    fetchSaldosBCR: jest.fn(),
    fetchSaldoActual: jest.fn(),
    fetchSaldosActuales: jest.fn(),
    fetchSaldoDetalle: jest.fn(),
    setSaldosPageQuickFilter: jest.fn(),
    fetchClienteXDivisa: jest.fn(),
    fetchAllDivisas: jest.fn(),
    logout: jest.fn(),
    fetchEntradas: jest.fn(),
    fetchSalidas: jest.fn(),
    fetchEntradasBCR: jest.fn(),
    fetchSalidasBCR: jest.fn(),
    fetchColumna: jest.fn(),
    saveColumna: jest.fn(),
    fetchCliente: jest.fn(),
    saveCliente: jest.fn(),
    fetchUsuarios: jest.fn(),
    fetchPais: jest.fn(),
    updateUsuario: jest.fn(),
    fetchI18n: jest.fn(),
    fetchDocumentacion: jest.fn(),
    fetchConfColumnasPais: jest.fn(),
    saveColumnaConfPais: jest.fn(),
    fetchPropias: jest.fn(),
    setTransferenciasPageQuickFilter: jest.fn(),
    fetchPublicas: jest.fn(),
    fetchDenominacionesCliente: jest.fn(),
    fetchTiposCoste: jest.fn(),
    setClienteSelected: jest.fn(),
    saveDemanda: jest.fn(),
    saveOferta: jest.fn(),
    fetchByOferta: jest.fn(),
    fetchByDemanda: jest.fn(),
    rejectTransferencia: jest.fn(),
    cancelDemandaPrivada: jest.fn(),
    aceptarDemandaPrivada: jest.fn(),
    demandasCompatibles: jest.fn(),
    ofertasCompatibles: jest.fn(),
    fetchDisclaimer: jest.fn(),
    updateAcceptDisclaimer: jest.fn(),
    fetchClientesAll: jest.fn(),
    saveDocumentacionImportacion: jest.fn(),
  };
