
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ICarga, IConfColumnaPais, IField, IParametro, ISoConfColumnasUpdateDTO } from "@/entities";
import {
  EDirection,
  ICargaFilter,
  ICargaParams,
  ICargaResponse,
  IColumnaResponse,
  IPaged,
} from "@/services";
import VueFilters from "@/vue-filters";
import Pagination from "@/components/common/Pagination.vue";
import { Action } from "vuex-class";
import SelectColumns from "@/components/common/SelectColumns/SelectColumns.vue";
import { SweetAlertResult } from "sweetalert2";
import { i18n } from "@/i18n";
import CargaFilterForm from "@/components/administracion/cargas/CargaFilterForm.vue";
import { Dictionary } from "vue-router/types/router";
import VueMethods from "@/vue-methods";

@Component({
  components: { CargaFilterForm, SelectColumns, Pagination },
  mixins: [VueFilters, VueMethods],
})
export class CargaTable extends Vue {
  @Prop({ required: true }) columnsConfig!: IColumnaResponse;
  @Prop({ required: true }) params!: ICargaParams;
  @Action("fetchCarga")
  fetchCarga!: (params?: ICargaParams) => Promise<ICargaResponse | undefined>;
  @Action("saveColumna") saveColumna!: (
    params: ISoConfColumnasUpdateDTO
  ) => Promise<IColumnaResponse | undefined>;

  public pagination: IPaged = {
    direction: EDirection.DESC,
    orderBy: "id",
    page: 1,
    size: 10,
  };

  public filtersCargas: ICargaFilter = {
    codFilename: this.params.codFilename ?? "",
    fecCarga: this.params.fecCarga ?? "",
    estadoSelected: this.params.estado ?? "",
    paises: this.params.paises ?? [],
    codCliente: this.params.codCliente ?? "",
    codDelegacion: this.params.codDelegacion ?? "",
    fecFichero: this.params.fecFichero ?? "",
    fechaNormalizacion: this.params.fechaNormalizacion || "",
    tipoCertificado: this.params.tipoCertificado ?? "",
    codigoCertificado: this.params.codigoCertificado ?? "",
  };

  public certificados: { key: string; value: string }[] = [
    { key: "DE", value: "DE" },
    { key: "PC", value: "PC" },
  ];

  mostrarFiltros = false;
  codTable = "cargas";
  private sortDesc = true;
  estados: IParametro[] = [];
  cargas: ICarga[] = [];
  totalElements = 0;
  public checkedAll = false;
  showTooltip: boolean[] = [];

  async getEstados(): Promise<void> {
    this.estados = (await this.$services.carga.fetchEstadosCarga()) || [];
  }

  async mounted(): Promise<void> {
    await this.getEstados();
    await this.getCargas();
  }
  async search(): Promise<void> {
    this.pagination.page = 1;
    await this.getCargas();
  }

  get dameTextoBotonFiltros(): string {
    let numFiltros = 0;
    Object.keys(this.filtersCargas).forEach((key: string) => {
      if (this.filtersCargas[key] != "" && this.filtersCargas[key] != null) {
        numFiltros = numFiltros + 1;
      }
    });

    return numFiltros
      ? `(${numFiltros}) ${this.$t("label.filtros")}`
      : this.$t("label.filtros").toString();
  }

  get fields(): IField[] {
    const visibleKeys = this.columnsConfig?.codColumna?.split(",").filter((s) => s !== "") || [];
    let columnas: IField[] = [];
    this.columnsConfig.columnas?.forEach((column: IConfColumnaPais) => {
      columnas.push({
        key: "actions-left",
        label: "",
        sortable: false,
        thClass: "table-header table-header-add-columnas pb-0",
        tdClass: "table-cell-middle actions",
        visible: true,
        class: "table-cell",
        aux: true,
        code: "",
      });
      columnas.push({
        key: column.keyFront,
        label: this.$t("label." + column.codColumna) as string,
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        thStyle: column.widthFront ? { width: column.widthFront } : {},
        visible: this.isVisible(visibleKeys, column.codColumna),
        class: "table-cell",
        aux: false,
        code: column.codColumna,
        formatter: (value: any): string => {
          if (column.formatType == "Date") {
            return this.filterDate(value);
          } else if (column.formatType == "Number") {
            return this.filterNumber(value, 2);
          }
          return value;
        },
      });
    });
    columnas.push({
      key: "actions-right",
      label: "",
      sortable: false,
      thClass: "table-header table-header-add-columnas pb-0",
      tdClass: "table-cell-middle actions",
      visible: true,
      class: "table-cell",
      aux: true,
      code: "",
    });
    return columnas;
  }

  set fields(fields: IField[]) {
    this.columnsConfig.codColumna =
      fields
        .filter((item) => item.visible && !item.aux)
        .map((item) => item.code)
        .join(",") || "";
  }

  isVisible(visibleKeys: string[], column: string): boolean {
    return !visibleKeys.length || !!visibleKeys.find((key) => key === column);
  }

  get visibleFields(): IField[] {
    return this.fields.filter((item) => item.visible);
  }

  async getCargas(): Promise<void> {
    const params: ICargaParams = Object.assign({}, this.params);
    params.page = this.pagination.page! - 1;
    params.size = this.pagination.size;
    params.orderBy = this.pagination.orderBy;
    params.direction = this.pagination.direction;

    const response: ICargaResponse | undefined = await this.$services.carga.fetch(params);

    if (response) {
      this.cargas = response.content;
      this.totalElements = response.totalElements;
      this.showTooltip = Array(this.cargas.length).fill(true);
    }
  }

  onSortingChange(): void {
    this.checkedAll = false;
    this.$nextTick(async () => {
      this.pagination.direction = this.sortDesc ? EDirection.DESC : EDirection.ASC;
      await this.getCargas();
      await this.saveColumnsConfig();
    });
  }

  onChangeCurrentPage(currentPage: number): void {
    this.checkedAll = false;
    this.pagination.page = currentPage;
    this.getCargas();
  }

  async saveColumnsConfig(): Promise<void> {
    const params: ISoConfColumnasUpdateDTO = {
      codColumna: this.columnsConfig.codColumna || "",
      codTabla: this.columnsConfig.codTabla,
    };
    if (this.pagination) {
      params.codTipoOrden = this.pagination.direction;
      params.numPagElements = this.pagination.size;
      params.codOrden = this.pagination.orderBy;
    }
    await this.saveColumna(params);
  }

  get selectAllLenght(): number {
    return this.cargas.filter((carga) => carga.checked).length;
  }

  selectOne(index: number): void {
    this.$set(this.showTooltip, index, false);
    setTimeout(() => {
      this.$set(this.showTooltip, index, true);
    }, 1000);
    this.checkedAll = this.cargas.every((carga) => carga.checked);
  }

  selectAllCerts(): void {
    if (!this.checkedAll) {
      this.cargas.forEach((carga) => (carga.checked = true));
    } else {
      this.cargas.forEach((carga) => (carga.checked = false));
    }
    this.checkedAll = !this.checkedAll;
  }

  async modalConfirm(): Promise<SweetAlertResult> {
    return this.$swal({
      title: this.$t("message.selectDelete.confirm.title", [this.selectAllLenght]).toString(),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: i18n.t("label.yes").toString(),
      cancelButtonText: i18n.t("label.no").toString(),
    });
  }

  async removeCertificado(): Promise<void> {
    const { isConfirmed } = await this.modalConfirm();
    if (isConfirmed) {
      let cargasDelete: ICarga[] = [];

      this.cargas.forEach((carga) => {
        if (carga.checked) {
          if (carga.codCliente && carga.id) {
            cargasDelete.push(carga);
          }
        }
      });
      await this.$services.carga.removeAll(cargasDelete);
      this.makeToast("success", i18n.t("validation.messages.cert.ok").toString());
      await this.getCargas();
    }
  }

  actions(obj: { action: string; data: ICargaFilter }): void {
    if (obj.action === "filtrar" || obj.action === "clean") {
      this.params.fecCarga = this.filtersCargas.fecCarga
        ? this.getStartDate(this.filtersCargas.fecCarga)
        : this.getThreeDate(new Date().toISOString());
      this.params.idCarga = this.filtersCargas.idCarga ? this.filtersCargas.idCarga : undefined;
      this.params.codFilename = this.filtersCargas.codFilename
        ? this.filtersCargas.codFilename
        : undefined;
      this.params.estado = this.filtersCargas.estadoSelected
        ? this.filtersCargas.estadoSelected
        : undefined;
      this.params.paises = this.filtersCargas.paises ? this.filtersCargas.paises : undefined;
      this.params.codCliente = this.filtersCargas.codCliente
        ? this.filtersCargas.codCliente
        : undefined;
      this.params.codDelegacion = this.filtersCargas.codDelegacion
        ? this.filtersCargas.codDelegacion
        : undefined;
      this.params.fecFichero = this.filtersCargas.fecFichero
        ? this.getStartDate(this.filtersCargas.fecFichero)
        : undefined;
      this.params.fechaNormalizacion = this.filtersCargas.fechaNormalizacion
        ? this.getStartDate(this.filtersCargas.fechaNormalizacion)
        : undefined;
      this.params.tipoCertificado = this.filtersCargas.tipoCertificado
        ? this.filtersCargas.tipoCertificado
        : undefined;
      this.params.codigoCertificado = this.filtersCargas.codigoCertificado
        ? this.filtersCargas.codigoCertificado
        : undefined;

      this.replaceQueryParams();
      this.search();

      this.mostrarFiltros = false;
    }
  }

  private replaceQueryParams(): void {
    this.$router
      .replace({
        path: this.$route.path,
        query: { ...this.params } as Dictionary<any>,
      })
      .catch(() => {
        // Do nothing
      });
  }

  makeToast(variant: string, message: string): void {
    this.$bvToast.toast(message, {
      autoHideDelay: 4000,
      variant,
      toaster: "b-toaster-bottom-center",
      noCloseButton: true,
    });
  }
}
export default CargaTable;
