
import { Component, Prop, Vue } from "vue-property-decorator";
import { ITransferencia, IDetalleTransferenciaParams } from "@/entities";
import { ITransferenciaParams } from "@/services";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import VueFilters from "@/vue-filters";
import TransferenciaDetalle from "@/pages/transferencias/common/TransferenciaDetalle.vue";

@Component({
  name: "transferir-modal",
  mixins: [VueFilters],
  components: {
    TransferenciaDetalle,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class AceptarDemanda extends Vue {
  @Prop({ required: true }) transferencia!: ITransferencia;
  isShowBankField = false;

  $refs!: {
    formTransferir: InstanceType<typeof ValidationObserver>;
  };

  mounted(): void {
    this.isShowBankField =
      this.transferencia.soVOferta.clientexdel.cliente.pais.hasInfoBancaria || false;
    if (this.transferencia.soVDemanda.checkParcial) {
      this.transferencia.soVDetalleTransferencias.forEach((value) => {
        this.$set(value.soCalidadDenominacion, "importe", 0);
      });
    }
  }

  get continuarCondition(): boolean {
    return !(
      !this.transferencia.soVDemanda.checkParcial ||
      (this.saldoOfertar > 0 && this.transferencia.soVDemanda.checkParcial)
    );
  }

  get fields_PTableSimple(): { key: string; label: string }[] {
    return [
      { key: "denominacion", label: this.$t("label.denominacion") as string },
      { key: "disponible", label: this.$t("label.disponible") as string },
      { key: "ofertado", label: this.$t("label.ofertado") as string },
    ];
  }

  get saldoRestante(): number {
    return this.transferencia.soVOferta.cantidadDisponible - this.saldoOfertar;
  }

  get saldoOfertar(): number {
    let sumaImportes = 0;
    if (this.transferencia.soVDemanda.checkParcial) {
      this.transferencia.soVDetalleTransferencias.forEach((value) => {
        if (value.soCalidadDenominacion.importe) {
          sumaImportes = Number(sumaImportes) + Number(value.soCalidadDenominacion.importe);
        }
      });
      return sumaImportes;
    } else {
      return this.transferencia.cantidadDemandada;
    }
  }

  cancelar(): void {
    this.$emit("cancel");
  }

  async ofertar(): Promise<void> {
    const detalles: IDetalleTransferenciaParams[] = [];
    if (this.transferencia.soVDemanda.checkParcial) {
      this.transferencia.soVDetalleTransferencias.forEach((value) => {
        if (value.soCalidadDenominacion.importe > 0) {
          detalles.push({ id: value.id, importe: value.soCalidadDenominacion.importe });
        }
      });
    }
    const transferenciaParams: ITransferenciaParams = {
      id: this.transferencia.id,
      detalles: detalles,
    };
    this.$emit("action", {
      action: "transferir",
      data: transferenciaParams,
    });
  }
}
