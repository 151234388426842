
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "icon-pie-percentage",
  components: {},
})
export class IconPiePercentage extends Vue {
  @Prop({ default: 20 }) public size!: number;
  @Prop({ default: "#ffd102" }) public color!: string;
  @Prop({ default: 65 }) public percentage!: number;
  @Prop({ default: "#997d01" }) public colorBackground!: string;

  constructor() {
    super();
  }

  getPath(): string {
    return this.describeArc(50, 50, 50, 0, this.getAngleFromPercentage(this.percentage));
  }

  getAngleFromPercentage(percentage: number): number {
    return percentage * 3.6;
  }

  polarToCartesian(
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number
  ): { x: number; y: number } {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }

  describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number): string {
    const start = this.polarToCartesian(x, y, radius, endAngle);
    const end = this.polarToCartesian(x, y, radius, startAngle);

    const arcSweep = endAngle - startAngle <= 180 ? "0" : "1";

    return `M ${start.x} ${start.y}
                A ${radius} ${radius} 0 ${arcSweep} 0 ${end.x} ${end.y}
                L ${x} ${y}
                L ${start.x} ${start.y} `;
  }
}
export default IconPiePercentage;
