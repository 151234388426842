
import { Component, Prop, Vue } from "vue-property-decorator";
import Multiselect from "vue-multiselect";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import VueMethods from "@/vue-methods";

@Component({
  name: "importar-form",
  mixins: [VueMethods],
  components: {
    Multiselect,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class ImportacionForm extends Vue {
  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;

  files: File[] = [];

  validado = true;

  $refs!: {
    formImportar: InstanceType<typeof ValidationObserver>;
  };

  async accept(action: string): Promise<void> {
    if (action == "importar") {
      await this.importFile();
    }
  }

  async importFile(): Promise<void> {
    this.$emit("action", { action: "importacionConfirm", data: this.files });
  }

  cancel(): void {
    this.$emit("cancel");
  }
}
