import { IRootState, IState, IStoreMock } from "./store.types";
import { mockActions } from "./actions";
import { mockMutations } from "./mutations";
import { mockGetters } from "./getters";
import { mockAbility, mockProvider, mockUsuario } from "@/services";

export const mockRootState = (): IRootState => ({
  version: "0.0.0",
  locale: "",
  currentLocation: "",
  paises: [],
});

export const mockState = (): IState => ({
  version: process.env.VUE_APP_VERSION as string,
  locale: "",
  currentLocation: "",
  paises: [],
  signedIn: false,
  columnas: [],
  saldosPageQuickFilter: {
    id: 0,
    divisa: [],
  },
  transferenciasPageQuickFilter: {
    id: 0,
    divisa: [],
    tipo: "",
  },
  clientes: [],
  clientesAll: [],
  clienteXDivisa: [],
  delegacionesCliente: [],
  divisas: [],
  costes: [],
  loggedUser: mockUsuario(),
  disclaimer: "",
});

export const mockStore = (): IStoreMock => ({
  $services: mockProvider(),
  $ability: mockAbility(),
  state: mockState(),
  actions: mockActions(),
  mutations: mockMutations(),
  getters: mockGetters(),
  replaceState: jest.fn(),
  dispatch: jest.fn(),
  commit: jest.fn(),
  subscribe: jest.fn(),
  subscribeAction: jest.fn(),
  watch: jest.fn(),
  registerModule: jest.fn(),
  unregisterModule: jest.fn(),
  hotUpdate: jest.fn(),
  hasModule: jest.fn(),
});
