
import { Component, Prop, Vue } from "vue-property-decorator";
import CargaTable from "./CargaTable.vue";
import { ICargaParams, IColumnaResponse } from "@/services";
import CargaFilterForm from "@/components/administracion/cargas/CargaFilterForm.vue";
import VueMethods from "@/vue-methods";

@Component({
  components: { CargaTable, CargaFilterForm },
  mixins: [VueMethods],
})
export default class AdminCargasComponent extends Vue {
  @Prop({ required: true }) columnsConfigCarga!: IColumnaResponse;

  queryParams = this.$route.query;

  params: ICargaParams = {
    codFilename: this.queryParams.codFilename?.toString(),
    fecCarga: this.queryParams.fecCarga?.toString()
      ? this.queryParams.fecCarga?.toString()
      : this.getThreeDate(new Date().toISOString()),
    estado: this.queryParams.estado?.toString(),
    paises: this.queryParams.paises?.toString().split(",") ?? [],
    codCliente: this.queryParams.codCliente?.toString(),
    codDelegacion: this.queryParams.codDelegacion?.toString(),
    fecFichero: this.getStartDate(this.queryParams.fecFichero?.toString()),
    fechaNormalizacion: this.getStartDate(this.queryParams.fechaNormalizacion?.toString()),
    tipoCertificado: this.queryParams.tipoCertificado?.toString(),
    codigoCertificado: this.queryParams.codigoCertificado?.toString(),
  };
}
