
import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";

@Component
export default class PInfoBar extends Vue {
  @Prop({ required: false, default: null })
  title!: string;
  @Prop({ required: false, default: true })
  border!: boolean;
  @Prop({
    required: true,
    default: [
      { label: "Código cliente", text: "5-000" },
      { label: "Delegación", text: "Rio Grande" },
      { label: "Fecha certificado", text: "01/03/2020" },
      { label: "Divisa", text: "ARS" },
      { label: "Saldo actual", text: "1.000.000.000,00" },
      { label: "saldo anterior", text: "1.000.000.000,00" },
      { label: "entradas", text: "1.000.000.000,00" },
      { label: "salidas", text: "1.000.000.000,00" },
    ],
  })
  items!: { label: string; text: string }[];
}
