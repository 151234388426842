import {
  BACKEND_BASE_URL,
  IEntradaData,
  IEntradaParams,
  IMovimientosService,
  ISalidaData,
  ISalidaParams,
  serialize,
} from "@/services";
import { APPLICATION_JSON } from "@/constans";
import { handleResponse } from "@/services/handlers";
import Vue from "vue";
import { EventBus } from "@/event-bus";
import { IResponseError } from "@/entities/common/common.types";
import { IDetallesEntrada, IDetallesSalida } from "@/entities";

export class MovimientosService implements IMovimientosService {
  async _fetchEntradas(params: IEntradaParams): Promise<IEntradaData | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IEntradaData, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/entrada`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );
    if (error) {
      EventBus.$emit("on-service-saldosonline-movimientos-fetchentradas-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _fetchSalidas(params: ISalidaParams): Promise<ISalidaData | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    params.fecMovimientoFin = params.fecMovimientoFin?.replace("/", "-");
    params.fecMovimientoInicio = params.fecMovimientoInicio?.replace("/", "-");
    const { response, error } = await handleResponse<ISalidaData, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/salida`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );
    if (error) {
      EventBus.$emit("on-service-saldosonline-movimientos-fetchentradas-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _fetchEntradasBCR(params: IEntradaParams): Promise<IEntradaData | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IEntradaData, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/entrada/BCR`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );
    if (error) {
      EventBus.$emit("on-service-saldosonline-movimientos-fetchentradas-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _fetchSalidasBCR(params: ISalidaParams): Promise<ISalidaData | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    params.fecMovimientoFin = params.fecMovimientoFin?.replace("/", "-");
    params.fecMovimientoInicio = params.fecMovimientoInicio?.replace("/", "-");
    const { response, error } = await handleResponse<ISalidaData, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/salida/BCR`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );
    if (error) {
      EventBus.$emit("on-service-saldosonline-movimientos-fetchentradas-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _fetchDetallesEntradaByIdEntrada(
    idEntrada: number
  ): Promise<IDetallesEntrada[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };

    const { response, error } = await handleResponse<IDetallesEntrada[], IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/entrada/` + idEntrada, { headers })
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-entradas-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _fetchDetallesSalidaByIdSalida(idSalida: number): Promise<IDetallesSalida[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };

    const { response, error } = await handleResponse<IDetallesSalida[], IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/salida/` + idSalida, { headers })
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-entradas-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _downloadEntradas(params: IEntradaParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getExcelEntradas`, { headers, params })
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-movimientos-downloadentradas-error", error.data);
    }
  }

  async _downloadSalidas(params: ISalidaParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getExcelSalidas`, { headers, params })
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-movimientos-downloadsalidas-error", error.data);
    }
  }

  async _downloadEntradasBCR(params: IEntradaParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getExcelEntradas/BCR`, { headers, params })
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-movimientos-downloadentradas-error", error.data);
    }
  }

  async _downloadSalidasBCR(params: ISalidaParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getExcelSalidas/BCR`, { headers, params })
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-movimientos-downloadsalidas-error", error.data);
    }
  }

  async _downloadEntradasWithoutDenominations(params: IEntradaParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getExcelEntradasWithoutDenominations`, {
        headers,
        params,
      })
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-movimientos-downloadentradas-error", error.data);
    }
  }

  async _downloadEntradasWithoutDenominationsBCR(params: IEntradaParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getExcelEntradasWithoutDenominations/BCR`, {
        headers,
        params,
      })
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-movimientos-downloadentradas-error", error.data);
    }
  }

  async _downloadEntradasPDFWithoutDenominations(params: IEntradaParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getPDFEntradasWithoutDenominations`, {
        headers,
        params,
      })
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-movimientos-downloadentradas-error", error.data);
    }
  }

  async _downloadEntradasPDFWithoutDenominationsBCR(params: IEntradaParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getPDFEntradasWithoutDenominations/BCR`, {
        headers,
        params,
      })
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-movimientos-downloadentradas-error", error.data);
    }
  }

  async _downloadEntradasPDF(params: IEntradaParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getPDFEntradas`, { headers, params })
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-movimientos-downloadentradas-error", error.data);
    }
  }

  async _downloadEntradasPDFBCR(params: IEntradaParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getPDFEntradas/BCR`, { headers, params })
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-movimientos-downloadentradas-error", error.data);
    }
  }

  async _downloadSalidasWithoutDenominations(params: ISalidaParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getExcelSalidasWithoutDenominations`, {
        headers,
        params,
      })
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-movimientos-downloadsalidas-error", error.data);
    }
  }

  async _downloadSalidasWithoutDenominationsBCR(params: ISalidaParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getExcelSalidasWithoutDenominations/BCR`, {
        headers,
        params,
      })
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-movimientos-downloadsalidas-error", error.data);
    }
  }

  async _downloadSalidasPDFBCR(params: ISalidaParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getPDFSalidas/BCR`, { headers, params })
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-movimientos-downloadsalidas-error", error.data);
    }
  }

  async _downloadSalidasPDF(params: ISalidaParams): Promise<void> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getPDFSalidas`, { headers, params })
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-movimientos-downloadsalidas-error", error.data);
    }
  }
}
