
import { Component, Vue } from "vue-property-decorator";

import LineChart from "@/components/common/widgets/LineChart.vue";
import { Action, Getter } from "vuex-class";
import { IDelegacion, IDivisa, LineChartData } from "@/entities";
import { ISaldoData, ISaldoParams } from "@/services";

@Component({
  components: { LineChart },
})
export default class SaldosActualesPorDelegacion extends Vue {
  @Getter("getDelegacionesCliente")
  public delegations!: IDelegacion[];
  @Getter("getClienteXDivisa")
  public divisas!: IDivisa[];
  @Action("fetchSaldos") fetchSaldosPage!: (
    params: ISaldoParams
  ) => Promise<ISaldoData | undefined>;

  public delegationSelected: IDelegacion | null = null;
  public divisaSelected: IDivisa | null = null;
  public data: LineChartData[] = [];

  mounted(): void {
    this.delegationSelected = this.delegations?.length ? this.delegations[0] : null;
    this.divisaSelected = this.divisas?.length ? this.divisas[0] : null;
    this.getSaldos();
  }

  async getSaldos(): Promise<void> {
    if (this.delegationSelected && this.divisaSelected) {
      const params: ISaldoParams = {
        pkDelegacion: [this.delegationSelected.id],
        pkDivisa: this.divisaSelected.id,
      };
      params.page = 0;
      params.size = 10;
      const response: ISaldoData | undefined = await this.fetchSaldosPage(params);
      this.data = [];
      if (response) {
        this.data.push(
          ...response.saldos.content.map((saldo) => {
            return { dateString: saldo.fecCertificado, value: saldo.saldoActual } as LineChartData;
          })
        );
      }
    }
  }

  setDelegacion(delegacion: IDelegacion): void {
    this.delegationSelected = delegacion;
    this.getSaldos();
  }

  setDivisa(divisa: IDivisa): void {
    this.divisaSelected = divisa;
    this.getSaldos();
  }
}
