
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "pagination",
})
export default class Pagination extends Vue {
  @Prop() public pageSize!: number;
  @Prop() public totalElements!: number;
  @Prop({ default: 1 }) public currentPage!: number;

  constructor() {
    super();
  }

  get lastPageNumber(): number {
    return Math.ceil(this.totalElements / this.pageSize);
  }

  get isFirstPageNumber(): boolean {
    return this.currentPage === 1;
  }

  get isLastPageNumber(): boolean {
    return this.currentPage === this.lastPageNumber;
  }

  onClickDirection(factor: number): void {
    let page = this.currentPage;
    if (factor === 0) {
      page = 1;
    } else if (factor === this.lastPageNumber) {
      page = this.lastPageNumber;
    } else if (factor > 0) {
      page++;
    } else if (factor < 0) {
      page--;
    }

    this.$emit("change", page);
  }
}
