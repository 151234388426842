
import { Component, Vue } from "vue-property-decorator";
import DashboardHeader from "@/components/dashboard/DashboardHeader.vue";
import { SelectGroupType } from "@/components/look/PFormSelect/pFormSelect.types";

@Component({
  name: "dashboard-page",
  components: {
    DashboardHeader,
  },
})
export default class DashboardPage extends Vue {
  constructor() {
    super();
  }
  file: File | string = "";
  validado = false;
  limpiar(): void {
    alert("limpiar");
  }
  action(): void {
    alert("action");
  }
  valuePFormSelectMulti = [];
  actionName = "filtrar";
  textActionName = "boton";
  valueMultiSelectGroup = [];
  optionsPFormSelectMulti = [
    { value: "1", label: "pwewpkewlkel" },
    { value: "123", label: "aaaadaddad" },
    { value: "1232", label: "bbbbbbbb" },
    { value: "12323", label: "aaababbababab" },
    { value: "3251", label: "adfsgtsfdfd" },
    { value: "145245", label: "ffshhd" },
    { value: "12454235", label: "rtwtrewtwr" },
    { value: "5231", label: "rtwretrt" },
    { value: "2352451", label: "rtryuyu" },
    { value: "15432", label: "wewyfr" },
    { value: "123532", label: "wtrwrtrt" },
  ];
  optionsMultiSelectGroup: SelectGroupType[] = [
    {
      name: "gurpo 1",
      items: [
        { value: "1", text: "pwewpkewlkel" },
        { value: "11", text: "dfd df df" },
      ],
    },
    { name: "gurpo 2", items: [{ value: "2", text: "dsdsdsd s sds" }] },
    { name: "gurpo 3", items: [{ value: "3", text: " sdsdsds" }] },
  ];
  valueMultiSelectGroup2 = [];
  optionsMultiSelectGroup2: SelectGroupType[] = [
    {
      name: "gurpo 1",
      items: [
        { value: "1", text: "pwewpkewlkel" },
        { value: "11", text: "dfd df df" },
      ],
    },
    { name: "gurpo 2", items: [{ value: "2", text: "dsdsdsd s sds" }] },
    { name: "gurpo 3", items: [{ value: "3", text: " sdsdsds" }] },
  ];
  fecha = null;
}
