import { IUsuario } from "@/entities";
import { Ability, AbilityBuilder, AbilityClass } from "@casl/ability";
import { RawRuleOf } from "@casl/ability/dist/types/RuleIndex";
import { ROLE_CLIENTE, ROLE_DELEGACION, ROLE_PAIS } from "@/constans";

type Actions = "manage" | "create" | "read" | "update" | "delete";
type Subjects =
  | "SaldosPage"
  | "SaldosTodos"
  | "SaldosEntradas"
  | "SaldosSalidas"
  | "TransferenciasPage"
  | "DocumentacionPage"
  | "ByDocuments"
  | "ByDelegation"
  | "ByImportacion"
  | "ByDocumentsNonHistorical"
  | "AdministracionPage"
  | "AdminUsuarios"
  | "AdminClientes"
  | "AdminColumnas"
  | "AdminCargas"
  | "VideosPage";

export type AppAbilityType = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbilityType>;

function loadAbilityAdministration(user: IUsuario | undefined, can: any): void {
  if (user?.roles?.find((role) => role.codRol === "ROLE_ADMINISTRACION")) {
    if (user?.nivel === ROLE_PAIS) {
      can("read", "AdministracionPage");
      can("read", "AdminClientes");
      can("read", "AdminColumnas");
      can("read", "AdminUsuarios");
      can("read", "AdminCargas");
    } else if (user?.nivel === ROLE_CLIENTE || user?.nivel === ROLE_DELEGACION) {
      can("read", "AdministracionPage");
      can("read", "AdminUsuarios");
    }
  }
}

function loadAbilitySaldos(user: IUsuario | undefined, can: any): void {
  if (
    (user?.nivel === ROLE_PAIS ||
      user?.nivel === ROLE_CLIENTE ||
      user?.nivel === ROLE_DELEGACION) &&
    user?.roles?.find((role) => role.codRol === "ROLE_SALDOS")
  ) {
    can("read", "SaldosPage");
    can("read", "SaldosTodos");
    can("read", "SaldosEntradas");
    can("read", "SaldosSalidas");
  } else if (user?.roles?.find((role) => role.codRol === "ROLE_SALDOS")) {
    can("read", "SaldosPage");
    can("read", "SaldosEntradas");
    can("read", "SaldosSalidas");
  }
}

function loadAbilityTransferencias(user: IUsuario | undefined, can: any): void {
  if (
    (user?.nivel === ROLE_PAIS ||
      user?.nivel === ROLE_CLIENTE ||
      user?.nivel === ROLE_DELEGACION) &&
    user?.roles?.find((role) => role.codRol === "ROLE_TRANSFERENCIAS")
  ) {
    can("read", "TransferenciasPage");
  }
}

function loadAbilityVideos(user: IUsuario | undefined, can: any): void {
  if (
    (user?.nivel === ROLE_PAIS || user?.nivel === ROLE_CLIENTE) &&
    user?.roles?.find((role) => role.codRol === "ROLE_VIDEOS")
  ) {
    can("read", "VideosPage");
  }

  if (user?.nivel === ROLE_PAIS && user?.roles?.find((role) => role.codRol === "ROLE_VIDEOS")) {
    can("manage", "VideosPage");
  }
}

function loadAbilityDocumentos(user: IUsuario | undefined, can: any): void {
  if (user?.roles?.find((role) => role.codRol === "ROLE_DOCUMENTOS")) {
    can("read", "DocumentacionPage");
    can("read", "ByDocuments");
    can("read", "ByDelegation");
    can("read", "ByDocumentsNonHistorical");
    if (user?.nivel === ROLE_PAIS) {
      can("read", "ByImportacion");
    }
  }
}

export function defineRulesFor(user?: IUsuario): RawRuleOf<Ability<[Actions, Subjects]>>[] {
  const { can, rules } = new AbilityBuilder(AppAbility);

  loadAbilityAdministration(user, can);
  loadAbilitySaldos(user, can);
  loadAbilityTransferencias(user, can);
  loadAbilityVideos(user, can);
  loadAbilityDocumentos(user, can);

  return rules;
}

export function buildAbilityFor(user: IUsuario): AppAbilityType {
  return new AppAbility(defineRulesFor(user));
}

export const mockAbility = (user?: IUsuario): AppAbilityType =>
  new AppAbility(defineRulesFor(user));
