import { FlowReportTypeData } from "@/entities/centros-efectivo-backend/reporte.types";
import { APPLICATION_JSON } from "@/constans";
import { IResponseError } from "@/entities";
import { BACKEND_BASE_URL } from "@/services";
import { handleResponse } from "@/services/handlers";
import Vue from "vue";
import { IReportesService } from "@/services/saldosonline/reportes/reportes.types";

export class ReportesService implements IReportesService {
  async getFlowReportTypes(): Promise<FlowReportTypeData[] | undefined> {
    const headers: Record<string, unknown> = {
      Accept: APPLICATION_JSON,
    };

    const { response, error } = await handleResponse<
      Promise<FlowReportTypeData[] | undefined>,
      IResponseError
    >(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/configuration/v1/report/flows/types`, {
        headers,
      })
    );
    if (error) {
      return undefined;
    } else {
      return response?.data;
    }
  }

  async getFlowsReportTypesForDelivery(): Promise<FlowReportTypeData[] | undefined> {
    const headers: Record<string, unknown> = {
      Accept: APPLICATION_JSON,
    };

    const { response, error } = await handleResponse<
      Promise<FlowReportTypeData[] | undefined>,
      IResponseError
    >(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/configuration/v1/report/flows/types-for-delivery`, {
        headers,
      })
    );
    if (error) {
      return undefined;
    } else {
      return response?.data;
    }
  }

  async getFlowsReportTypesOfDelivery(): Promise<FlowReportTypeData[] | undefined> {
    const headers: Record<string, unknown> = {
      Accept: APPLICATION_JSON,
    };

    const { response, error } = await handleResponse<
      Promise<FlowReportTypeData[] | undefined>,
      IResponseError
    >(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/configuration/v1/report/flows/types-of-delivery`, {
        headers,
      })
    );
    if (error) {
      return undefined;
    } else {
      return response?.data;
    }
  }
}
