export interface IUsuarioxVideo {
  fkVideo: number;
  aceptaDatos?: boolean;
  aceptaNovedad?: boolean;
  observaciones?: string;
  timezone: string;
}

export const iniUsuarioxVideo: IUsuarioxVideo = {
  fkVideo: 0,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  aceptaDatos: false,
  aceptaNovedad: false,
  observaciones: "",
};
