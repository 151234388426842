
import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { ISaldosQuickFilter } from "../../types";
import { mapGetters } from "vuex";
import QuickFilter from "../common/QuickFilter.vue";
import ColapsableSalidasTable from "./ColapsableSalidasTable.vue";
import SaldosSalidasFiltersForm from "./SaldosSalidasFiltersForm.vue";
import { IColumnaResponse, IMovimientoParams } from "@/services";
import Vue from "vue";
import { Dictionary } from "vue-router/types/router";
import { ICliente } from "@/entities";
import VueMethods from "@/vue-methods";
import { Locale } from "@/store";
import { SweetAlertResult } from "sweetalert2";
import { FlowReportTypeData } from "@/entities/centros-efectivo-backend/reporte.types";

@Component({
  name: "salidas",
  components: {
    SaldosSalidasFiltersForm,
    ColapsableSalidasTable,
    QuickFilter,
  },
  mixins: [VueMethods],
  computed: {
    ...mapGetters(["getClienteXDivisa"]),
  },
})
export class SaldosSalidas extends Vue {
  @Getter("getClienteSelected") clienteSelected: ICliente | undefined;
  @Getter("getSaldosPageQuickFilter") getSaldosPageQuickFilter!: ISaldosQuickFilter;
  @Getter("getLocale") locale!: Locale;

  @Prop({ required: true, default: {} }) public salidasConfig!: IColumnaResponse;
  @Prop({ required: false, default: false }) isBCR!: boolean;
  @Prop() public params!: IMovimientoParams;

  mostrarFiltros = false;

  filtersSaldos: any = {
    tipoFechaSelected: null,
    fechaInicio: null,
    fechaFin: null,
    delegationsSelected: [],
    puntosSelected: [],
  };

  flowReportTypes: FlowReportTypeData[] = [];

  beforeMount(): void {
    this.params.pkCliente = this.clienteSelected?.id ?? this.params.pkCliente;
    this.params.idioma = this.locale;

    const fechaInicio =
      this.params.fecMovimientoInicio ??
      this.params.fecServicioInicio ??
      this.params.fecContableInicio ??
      this.params.fecEntregaInicio ??
      this.params.fecCertificadoInicio;

    const fechaFin =
      this.params.fecMovimientoFin ??
      this.params.fecServicioFin ??
      this.params.fecContableFin ??
      this.params.fecEntregaFin ??
      this.params.fecCertificadoFin;

    const tipoFechaSelected = this.getTipoFechaSelected();

    switch (tipoFechaSelected) {
      case "CONTABLE":
        this.params.fecContableInicio = fechaInicio;
        this.params.fecContableFin = fechaFin;
        this.params.fecEntregaInicio = undefined;
        this.params.fecEntregaFin = undefined;
        this.params.fecMovimientoInicio = undefined;
        this.params.fecMovimientoFin = undefined;
        break;
      case "ENTREGA":
        this.params.fecEntregaInicio = fechaInicio;
        this.params.fecEntregaFin = fechaFin;
        this.params.fecContableInicio = undefined;
        this.params.fecContableFin = undefined;
        this.params.fecMovimientoInicio = undefined;
        this.params.fecMovimientoFin = undefined;
        break;
      case "MOVIMIENTO":
        this.params.fecMovimientoInicio = fechaInicio;
        this.params.fecMovimientoFin = fechaFin;
        this.params.fecContableInicio = undefined;
        this.params.fecContableFin = undefined;
        this.params.fecEntregaInicio = undefined;
        this.params.fecEntregaFin = undefined;
        break;
    }
    this.params.fecCertificadoInicio = undefined;
    this.params.fecCertificadoFin = undefined;
    this.params.fecServicioInicio = undefined;
    this.params.fecServicioFin = undefined;

    this.filtersSaldos = {
      tipoFechaSelected: this.getTipoFechaSelected(),
      fechaInicio: fechaInicio ? new Date(fechaInicio) : undefined,
      fechaFin: fechaFin ? new Date(fechaFin) : undefined,
      delegationsSelected: this.params.pkDelegacion ?? [],
      puntosSelected: this.params.fkPuntoServ ?? [],
    };
  }

  async mounted(): Promise<void> {
    this.$router
      .replace({ path: this.$route.path, query: { ...this.params } as Dictionary<any> })
      .catch(() => {
        // Do nothing
      });

    this.flowReportTypes = (await this.$services.reporte.getFlowsReportTypesOfDelivery()) ?? [];
  }

  get quickFilter(): ISaldosQuickFilter {
    return this.getSaldosPageQuickFilter;
  }

  get hasDivisasFilter(): boolean {
    return this.quickFilter.divisa.length > 0;
  }

  get hasClientFilterButNoDivisasFilter(): boolean {
    if (this.params?.pkCliente) {
      return true;
    }

    return this.hasDivisasFilter;
  }

  getTipoFechaSelected(): string {
    if (this.params.fecMovimientoInicio || this.params.fecMovimientoFin) {
      return "MOVIMIENTO";
    }
    if (this.params.fecEntregaInicio || this.params.fecEntregaFin) {
      return "ENTREGA";
    }
    return "CONTABLE";
  }

  get dameTextoBotonFiltros(): string {
    let numFiltros = 0;
    Object.keys(this.filtersSaldos).forEach((key: string) => {
      if (
        key !== "tipoFechaSelected" &&
        this.filtersSaldos[key] != "" &&
        this.filtersSaldos[key] != null
      ) {
        numFiltros = numFiltros + 1;
      }
    });

    return numFiltros
      ? `(${numFiltros}) ${this.$t("label.filtros")}`
      : this.$t("label.filtros").toString();
  }

  resetFilters(delegaciones: []): void {
    this.filtersSaldos.delegationsSelected = delegaciones;
  }

  action(action: { action: string; data?: any }): void {
    if (action.action === "filtrar" || action.action === "clean") {
      this.params.fecContableInicio = undefined;
      this.params.fecContableFin = undefined;
      this.params.fecEntregaInicio = undefined;
      this.params.fecEntregaFin = undefined;
      this.params.fecMovimientoInicio = undefined;
      this.params.fecMovimientoFin = undefined;
      this.params.fecServicioInicio = undefined;
      this.params.fecServicioFin = undefined;
      this.params.fecCertificadoInicio = undefined;
      this.params.fecCertificadoFin = undefined;
      switch (this.filtersSaldos.tipoFechaSelected) {
        case "CONTABLE":
          this.params.fecContableInicio = this.getStartDate(this.filtersSaldos.fechaInicio);
          this.params.fecContableFin = this.getEndDate(this.filtersSaldos.fechaFin);
          break;
        case "ENTREGA":
          this.params.fecEntregaInicio = this.getStartDate(this.filtersSaldos.fechaInicio);
          this.params.fecEntregaFin = this.getEndDate(this.filtersSaldos.fechaFin);
          break;
        case "MOVIMIENTO":
          this.params.fecMovimientoInicio = this.getStartDate(this.filtersSaldos.fechaInicio);
          this.params.fecMovimientoFin = this.getEndDate(this.filtersSaldos.fechaFin);
          break;
      }
      this.params.pkDelegacion = this.filtersSaldos.delegationsSelected ?? undefined;
      this.params.fkPuntoServ = this.filtersSaldos.puntosSelected ?? undefined;
      this.$router
        .replace({
          path: this.$route.path,
          query: { ...this.params } as Dictionary<any>,
        })
        .catch(() => {
          // Do nothing
        });

      this.mostrarFiltros = false;
    }
  }

  async confirmDescarga(): Promise<SweetAlertResult> {
    return this.$swal({
      title: this.$t("label.confirmarDescarga.title").toString(),
      text: this.$t("label.confirmarDescarga.message").toString(),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: this.$t("label.yes").toString(),
      cancelButtonText: this.$t("label.no").toString(),
    });
  }

  async downloadSalidasPdfWithDenominations(): Promise<void> {
    const res = await this.confirmDescarga();
    if (res.isConfirmed) {
      if (this.isBCR) {
        await this.$services.movimientos._downloadSalidasPDFBCR(this.params);
      } else {
        await this.$services.movimientos._downloadSalidasPDF(this.params);
      }
      this.makeToast("success", this.$t("label.confirmarDescarga.success").toString());
    }
  }
  async downloadSalidasExcelWithDenominations(): Promise<void> {
    const res = await this.confirmDescarga();
    if (res.isConfirmed) {
      if (this.isBCR) {
        await this.$services.movimientos._downloadSalidasBCR(this.params);
      } else {
        await this.$services.movimientos._downloadSalidas(this.params);
      }
      this.makeToast("success", this.$t("label.confirmarDescarga.success").toString());
    }
  }

  async downloadSalidasExcelWithoutDenominations(): Promise<void> {
    const res = await this.confirmDescarga();
    if (res.isConfirmed) {
      if (this.isBCR) {
        await this.$services.movimientos._downloadSalidasWithoutDenominationsBCR(this.params);
      } else {
        await this.$services.movimientos._downloadSalidasWithoutDenominations(this.params);
      }
      this.makeToast("success", this.$t("label.confirmarDescarga.success").toString());
    }
  }
  async onGenerateDeliveriesReport(type: string): Promise<void> {
    if (type === "EXCEL") {
      this.downloadSalidasExcelWithDenominations();
    } else if (type === "EXCEL_WITHOUT_DENOMINATIONS") {
      this.downloadSalidasExcelWithoutDenominations();
    } else if (type === "PDF") {
      this.downloadSalidasPdfWithDenominations();
    }
  }
}
export default SaldosSalidas;
