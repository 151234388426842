
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component({
  name: "callback_page",
  components: {},
})
export default class CallbackPage extends Vue {
  @Getter("getCurrentLocation") currentLocation!: string;
  @Action("logout") logout!: () => Promise<void>;
  hasError = false;
  accessDenied = false;

  get currentPath(): string {
    if (!this.currentLocation) {
      return "/";
    }
    const url = new URL(this.currentLocation);
    return `${url.pathname}${url.search}`;
  }

  mounted(): void {
    Vue.$auth
      .signinRedirectCallback()
      .then(() => {
        this.goToCurrentPath();
      })
      .catch(() => {
        if (this.$route.query.error === "access_denied") {
          this.accessDenied = true;
        } else {
          this.hasError = true;
        }
      });

    history.pushState({}, "", this.currentPath);
  }

  goToCurrentPath(): void {
    this.$router.push({
      path: this.currentPath,
    });
  }
}
