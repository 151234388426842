
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  ICliente,
  IDelegacion,
  IDetalleDemanda,
  IDetallesSaldo,
  IDivisa,
  IOfertaDemanda,
  ISaldo,
} from "@/entities";
import { Action, Getter } from "vuex-class";
import { IDetalleOfertaParams, IOfertaParams, ISaldoActualParams } from "@/services";
import { EventBus } from "@/event-bus";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import VueFilters from "@/vue-filters";
import DetalleDemanda from "@/pages/transferencias/common/OfertaDemandaDetalle.vue";
import { SweetAlertResult } from "sweetalert2";
import VueMethods from "@/vue-methods";

@Component({
  name: "transferir-modal",
  mixins: [VueFilters, VueMethods],
  components: {
    DetalleDemanda,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class OfertarSobreDemanda extends Vue {
  @Prop({ required: true }) demanda!: IOfertaDemanda;
  @Prop({ required: true }) divisa!: IDivisa;
  @Getter("getClienteSelected") getClienteSelected!: ICliente;
  @Action("fetchSaldoActual") fetchSaldoActual!: (
    params?: ISaldoActualParams
  ) => Promise<ISaldo | undefined>;
  @Action("fetchClientesXDelegacion") fetchClientesXDelegacion!: (
    idDelegacion?: number
  ) => Promise<ICliente[] | undefined>;
  ofertaParams: IOfertaParams = {
    numCantidad: 0,
    coste: 0,
    pkDivisa: 0,
    pkCliente: 0,
    pkDelegacion: 0,
    detallesOferta: [],
    pkDelegaciones: [],
  };

  $refs!: {
    formTransferir: InstanceType<typeof ValidationObserver>;
  };

  public clientes: ICliente[] = [];
  public saldoActual: ISaldo | null = null;
  public detallesSaldo: IDetallesSaldo[] = [];
  public observacionesOferta: string | undefined = "";
  private isAnonimo = false;

  async mounted(): Promise<void> {
    await this.searchClientes(
      Number(this.demanda.clientexdel?.delegacion?.id),
      Number(this.divisa?.id)
    );
    this.demanda.detallesDemanda?.forEach((value) =>
      this.$set(
        value.soCalidadDenominacion,
        "importe",
        this.demanda.checkParcial ? 0 : value.cantidadDisponible
      )
    );

    this.ofertaParams.pkDelegacion = this.demanda.clientexdel?.delegacion?.id;
    this.ofertaParams.pkCliente =
      this.clientes.find((c) => c.id === this.getClienteSelected?.id)?.id ?? this.clientes[0].id!;

    this.onSearchSaldoActuales();
  }

  get clienteSelected(): ICliente | null {
    return this.clientes.find((cliente) => cliente.id === this.ofertaParams.pkCliente) || null;
  }

  get delegaciones(): IDelegacion[] {
    return (
      this.demanda.ubicaciones
        ?.map((ubicacion) => ubicacion.delegacion)
        .filter((delegacion) => delegacion.active)
        .sort((a, b) => this.sortAscending(a, b, "desDelegacion")) || []
    );
  }

  get fields_PTableSimple(): { key: string; label: string }[] {
    if (this.clienteSelected?.fakeCerts) {
      return [
        { key: "denominacion", label: this.$t("label.denominacion") as string },
        { key: "calidadDenominacion", label: this.$t("label.ofertado") as string },
      ];
    } else {
      return [
        { key: "denominacion", label: this.$t("label.denominacion") as string },
        { key: "saldoActual", label: this.$t("label.disponible") as string },
        { key: "calidadDenominacion", label: this.$t("label.ofertado") as string },
      ];
    }
  }

  parseNumberToFormat(param?: number): string {
    return Number(param)
      .toFixed(2)
      .replace(".", ",")
      .replace(/\d(?=(\d{3})+,)/g, "$&.")
      .toLocaleString();
  }

  async searchClientes(idDelegacion: number, idDivisa: number): Promise<void> {
    const res =
      (await this.$services.clientes._fetchAllClienteXDelegacionAndDivisa(
        idDelegacion,
        idDivisa
      )) || [];
    if (res) {
      this.clientes = res.filter((cliente) => cliente.id != this.demanda.clientexdel?.cliente?.id);
    } else {
      EventBus.$emit("p-toast", { variant: "danger", message: res });
    }
  }

  pushDetalleSaldo(
    detalleSaldo: IDetallesSaldo | undefined,
    detalleDemanda: IDetalleDemanda
  ): void {
    if (detalleSaldo) {
      if (this.demanda?.checkParcial) {
        this.$set(detalleSaldo.calidadDenominacion!, "importe", 0);
        detalleSaldo.saldoMaximoOfertar =
          detalleDemanda.cantidadDisponible > detalleSaldo.saldoActual!
            ? detalleSaldo.saldoActual
            : detalleDemanda.cantidadDisponible;
      } else {
        this.$set(detalleSaldo.calidadDenominacion!, "importe", detalleDemanda.cantidadDisponible);
      }
      this.detallesSaldo.push(detalleSaldo);
    }
  }

  cancelar(): void {
    this.$emit("cancel");
    this.ofertaParams.pkDemanda = undefined;
  }

  get saldoOfertar(): number {
    let sumaImportes = 0;
    this.detallesSaldo?.forEach((detalleSaldo) => {
      if (detalleSaldo.calidadDenominacion?.importe) {
        sumaImportes = Number(sumaImportes) + Number(detalleSaldo.calidadDenominacion?.importe);
      }
    });

    return sumaImportes;
  }

  onSearchSaldoActuales(): void {
    this.saldoActual = null;
    this.detallesSaldo = [];

    if (this.clienteSelected && this.ofertaParams.pkDelegacion && this.divisa) {
      this.searchSaldosActuales(this.divisa);
    }
  }

  async searchSaldosActuales(divisa: IDivisa): Promise<void> {
    if (!this.clienteSelected?.fakeCerts) {
      const res = await this.fetchSaldoActual({
        pkCliente: this.ofertaParams.pkCliente,
        pkDelegacion: this.ofertaParams.pkDelegacion,
        pkDivisa: divisa.id,
      });
      if (res) {
        this.saldoActual = res;
        this.demanda?.detallesDemanda?.forEach((detalleDemanda) => {
          const detalleSaldo = this.saldoActual?.detallesSaldo?.find(
            (detalle) =>
              detalle.calidadDenominacion?.id === detalleDemanda.soCalidadDenominacion?.id &&
              (detalle.saldoActual! >= detalleDemanda.cantidadDisponible ||
                this.demanda.checkParcial)
          );
          this.pushDetalleSaldo(detalleSaldo, detalleDemanda);
        });
      } else {
        EventBus.$emit("p-toast", { variant: "danger", message: res });
      }
    } else {
      this.demanda?.detallesDemanda?.forEach((detalleDemanda) => {
        const detalleSaldo = {
          calidadDenominacion: {
            denominacion: detalleDemanda.soCalidadDenominacion.denominacion,
            codCalidad: detalleDemanda.soCalidadDenominacion.codCalidad,
            desCalidad: detalleDemanda.soCalidadDenominacion.desCalidad,
            importe: 0,
            id: detalleDemanda.soCalidadDenominacion.id,
            cantidad: 0,
            importeSelected: false,
            cantidadSelected: false,
          },
          saldoActual: detalleDemanda.cantidadDisponible,
          saldoMaximoOfertar: detalleDemanda.cantidadDisponible,
        };
        this.pushDetalleSaldo(detalleSaldo, detalleDemanda);
      });
    }
  }

  async confirmOferta(): Promise<void> {
    const res = await this.confirmTransferencia();
    if (res.isConfirmed) {
      await this.ofertar();
    }
  }

  async confirmTransferencia(): Promise<SweetAlertResult> {
    return this.$swal({
      title: this.$t("label.confirmar").toString(),
      text: this.$t("label.transferencias.disclaimer").toString(),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: this.$t("label.yes").toString(),
      cancelButtonText: this.$t("label.no").toString(),
    });
  }

  async ofertar(): Promise<void> {
    const detallesOferta: IDetalleOfertaParams[] = [];

    this.detallesSaldo?.forEach((detalle) => {
      if (detalle.calidadDenominacion && detalle.calidadDenominacion.importe > 0) {
        detallesOferta.push({
          numCantidad: detalle.calidadDenominacion?.importe,
          calidadDenominacion: detalle.calidadDenominacion,
        });
      }
    });

    if (this.clienteSelected) {
      this.ofertaParams.numCantidad = this.saldoOfertar;
      this.ofertaParams.coste = this.demanda.coste.id;
      this.ofertaParams.pkDivisa = this.divisa.id;
      this.ofertaParams.detallesOferta = detallesOferta;
      this.ofertaParams.numTasa = this.clienteSelected.pais.hasTasa
        ? Number(this.demanda?.numTasa)
        : undefined;
      this.ofertaParams.pkDemanda = this.demanda?.id;
      this.ofertaParams.observacionesOferta = this.observacionesOferta;
      this.ofertaParams.checkAnonimo = this.isAnonimo;
      this.$emit("action", { action: "ofertarSobreDemandaConfirmed", data: this.ofertaParams });
    } else {
      EventBus.$emit("p-toast", { variant: "danger", message: "No hay cliente selecionado" });
    }
  }
}
