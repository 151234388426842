
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "error-page",
  components: {},
})
export default class ErrorPage extends Vue {
  public type = this.$route.query.type || "";

  constructor() {
    super();
  }

  get error(): { number: string; title: string; desc: string } {
    switch (this.type.toString()) {
      case "401":
      case "403":
        return {
          number: "error403.number",
          title: "error403.title",
          desc: "error403.description",
        };
      case "500":
        return {
          number: "error500.number",
          title: "error500.title",
          desc: "error500.description",
        };
      default:
        return {
          number: "unexpectedError.number",
          title: "unexpectedError.title",
          desc: "unexpectedError.description",
        };
    }
  }
}
