import {
  IClientexdel,
  IDivisa,
  iniPais,
  IPais,
  IRangoip,
  IUsuarioCrear,
  IUsuarioModif,
} from "@/entities";

export interface ICliente {
  active: boolean;
  clientexdels: IClientexdel[];
  codCliente: string;
  desCliente: string;
  codDesCliente: string;
  divisas: IDivisa[];
  fecBaja: string;
  fecCrear: string;
  fecModif: string;
  id?: number | null;
  pais: IPais;
  usuarioCrear?: IUsuarioCrear;
  usuarioModificar?: IUsuarioModif;
  rangoips?: IRangoip[];
  fakeCerts: boolean;
}
export interface IClienteAll {
  active: boolean;
  codCliente: string;
  desCliente: string;
  codDesCliente: string;
  id?: number | null;
  fakeCerts: boolean;
  pais?: IPais;
}
export const iniCliente: ICliente = {
  active: false,
  clientexdels: [],
  codCliente: "",
  desCliente: "",
  codDesCliente: "",
  divisas: [],
  fecBaja: "",
  fecCrear: "",
  fecModif: "",
  id: null,
  pais: iniPais,
  fakeCerts: false,
};
