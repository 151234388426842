
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PFormCheckBox from "../PFormCheckBox/PFormCheckBox.vue";
import PDragAndDrop from "../PDragAndDrop/PDragAndDrop.vue";
import PButton from "../PButton/PButton.vue";
import { FieldPTableType } from "./pTable.type";

@Component({
  components: {
    PFormCheckBox,
    PDragAndDrop,
    PButton,
  },
})
export class PPersonalizeTable extends Vue {
  @Prop({ required: true }) value!: FieldPTableType[];
  @Watch("value", { deep: true, immediate: true })
  onChangeValue(): void {
    this.value.forEach((fi: FieldPTableType) => {
      this.valueLocalOrder.push({ ...fi });
    });
  }
  @Prop({ required: true }) initFiels!: FieldPTableType[];
  @Watch("initFiels", { deep: true, immediate: true })
  onChangeInitFiels(): void {
    this.groupFields = [];
    this.initFiels.forEach((fi: FieldPTableType) => {
      this.valueLocal.push({ ...fi });

      this.groupFields.push(fi.groupField); //esto esta hecho porque hay un error de infinito no solucionable
    });
  }
  @Prop({ required: false, default: "Resetear" }) bRestoreText!: string;
  @Prop({ required: false, default: "Aplicar" }) bApplyText!: string;
  @Prop({ required: false, default: "" }) fieldsTitle!: string;
  @Prop({ required: false, default: "Orden de columnas:" }) orderTitle!: string;
  valueLocal: FieldPTableType[] = [];
  valueLocalOrder: FieldPTableType[] = []; //ojo este cambia el ordern
  rellenarValueLocalOrder = new Date().getTime();
  groupFields: string[] = [];

  get valueLocalComp(): FieldPTableType[] {
    //comprobamos los que estan visibles y los ponemos
    this.valueLocalOrder.forEach((fi) => {
      const fiTemp = this.valueLocal.find((vl) => vl.key === fi.key);
      if (fiTemp) {
        fiTemp.show = fi.show;
      }
    });
    return this.valueLocal.filter((vl) => vl.type != "action");
  }
  get valueLocalOrderComp(): FieldPTableType[] {
    let returnTemp: FieldPTableType[] = [];
    if (this.rellenarValueLocalOrder > 0) {
      //esto es solo un truco para que rellene el cambio
      returnTemp = this.valueLocalOrder.filter((fi: FieldPTableType) => fi.show === true);
    }
    this.rellenarValueLocalOrder = new Date().getTime();
    //TODO hacerlo en funcion del tipo de campo
    return returnTemp.slice(1);
  }
  set valueLocalOrderComp(value: FieldPTableType[]) {
    const accione = this.valueLocalOrder[0];
    this.valueLocalOrder = value;
    this.valueLocalOrder.unshift(accione);
  }

  mostrarTitulo(index: number): boolean {
    if (index == 0) {
      return true;
    } else return this.groupFields[index] != this.groupFields[index + 1];
  }
  restore(): void {
    this.valueLocal.forEach((fi) => {
      fi.show = true;
    });
    this.valueLocalOrder.forEach((fi) => {
      fi.show = true;
    });
  }
  aplicar(): void {
    const valueTemp: any[] = [];
    //relleno en primer lugar los primeros
    this.valueLocalOrder.forEach((fi) => {
      valueTemp.push({ ...fi });
    });
    //rellenamos los que no esten
    this.valueLocal.forEach((fi) => {
      if (valueTemp.findIndex((field) => field.key) == -1) {
        valueTemp.push({ ...fi });
      }
    });
    this.$emit("input", valueTemp);
    this.$emit("change", valueTemp);
  }
  changeCheck(field: FieldPTableType): void {
    const fieldOrder = this.valueLocalOrder.find((fi) => fi.key == field.key);
    if (fieldOrder) {
      fieldOrder.show = field.show;
      this.rellenarValueLocalOrder = new Date().getTime(); //hacemos saltar el relleno automatico
    } else {
      this.valueLocalOrder.push({ ...field });
    }
  }
}
export default PPersonalizeTable;
