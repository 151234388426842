import DefaultLayout from "@/layout/default/DefaultLayout.vue";
import AdministracionPage from "@/pages/AdministracionPage.vue";
import ATMsPage from "@/pages/ATMsPage.vue";
import DashboardPage from "@/pages/DashboardPage.vue";
import DocumentacionPage from "@/pages/DocumentacionPage.vue";
import ErrorPage from "@/pages/ErrorPage.vue";
import NotFoundErrorPage from "@/pages/NotFoundErrorPage.vue";
import NotificacionesPage from "@/pages/notificaciones/NotificacionesPage.vue";
import VideosPage from "@/pages/videos/VideosPage.vue";
import SaldosPage from "@/pages/saldos/SaldosPage.vue";
import TransferenciasPage from "@/pages/transferencias/TransferenciasPage.vue";
import { IStore } from "@/store";
import Vue from "vue";
import Router, { NavigationGuardNext, Route } from "vue-router";
import CallbackPage from "@/pages/CallbackPage.vue";
import { AppAbilityType, buildAbilityFor, defineRulesFor } from "@/services";
import SaldosBCRPage from "@/pages/saldos/SaldosBCRPage.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: DefaultLayout,
      redirect: "/saldos",
      children: [
        {
          path: "dashboard",
          name: "dashboard",
          component: DashboardPage,
        },
        {
          path: "documentacion",
          name: "documentacion",
          component: DocumentacionPage,
          meta: {
            resource: "DocumentacionPage",
          },
          redirect: "documentacion/documentos",
          children: [
            {
              path: "historico",
              name: "historico",
              component: DocumentacionPage,
              meta: {
                resource: "ByDocuments",
                alternativeRedirect: "/documentacion/documentos",
              },
            },
            {
              path: "delegacion",
              name: "delegacion",
              component: DocumentacionPage,
              meta: {
                resource: "ByDelegation",
                alternativeRedirect: "/documentacion/delegacion",
              },
            },
            {
              path: "importacion",
              name: "importacion",
              component: DocumentacionPage,
              meta: {
                resource: "ByImportacion",
                alternativeRedirect: "/documentacion/importacion",
              },
            },
            {
              path: "documentos",
              name: "documentos",
              component: DocumentacionPage,
              meta: {
                resource: "ByDocumentsNonHistorical",
                alternativeRedirect: "/documentacion/documentos",
              },
            },
          ],
        },
        {
          path: "atm",
          name: "atm",
          component: ATMsPage,
        },
        {
          path: "saldos",
          name: "saldos",
          component: SaldosPage,
          meta: {
            resource: "SaldosPage",
          },
          redirect: "saldos/todos",
          children: [
            {
              path: "todos",
              name: "todos",
              component: SaldosPage,
              meta: {
                resource: "SaldosTodos",
                alternativeRedirect: "/saldos/entradas",
              },
            },
            {
              path: "entradas",
              name: "entradas",
              component: SaldosPage,
              meta: {
                resource: "SaldosEntradas",
                alternativeRedirect: "/saldos/salidas",
              },
            },
            {
              path: "salidas",
              name: "salidas",
              component: SaldosPage,
              meta: {
                resource: "SaldosSalidas",
              },
            },
          ],
        },
        {
          path: "saldosBCR",
          name: "saldosBCR",
          component: SaldosBCRPage,
          meta: {
            resource: "SaldosBCRPage",
          },
          redirect: "saldosBCR/todos",
          children: [
            {
              path: "todos",
              name: "todosBCR",
              component: SaldosBCRPage,
              meta: {
                resource: "SaldosTodos",
                alternativeRedirect: "/saldosBCR/entradas",
              },
            },
            {
              path: "entradas",
              name: "entradasBCR",
              component: SaldosBCRPage,
              meta: {
                resource: "SaldosEntradas",
                alternativeRedirect: "/saldosBCR/salidas",
              },
            },
            {
              path: "salidas",
              name: "salidasBCR",
              component: SaldosBCRPage,
              meta: {
                resource: "SaldosSalidas",
              },
            },
          ],
        },
        {
          path: "administracion",
          name: "administracion",
          component: AdministracionPage,
          meta: {
            resource: "AdministracionPage",
          },
          redirect: "administracion/usuarios",
          children: [
            {
              path: "clientes",
              name: "clientes",
              component: AdministracionPage,
              meta: {
                resource: "AdminClientes",
                alternativeRedirect: "/administracion/usuarios",
              },
            },
            {
              path: "usuarios",
              name: "usuarios",
              component: AdministracionPage,
              meta: {
                resource: "AdminUsuarios",
              },
            },
            {
              path: "columnas",
              name: "columnas",
              component: AdministracionPage,
              meta: {
                resource: "AdminColumnas",
                alternativeRedirect: "/administracion/usuarios",
              },
            },
            {
              path: "cargas",
              name: "cargas",
              component: AdministracionPage,
              meta: {
                resource: "AdminCargas",
                alternativeRedirect: "/administracion/usuarios",
              },
            },
          ],
        },
        {
          path: "transferencias/:option",
          name: "transferencias",
          component: TransferenciasPage,
          meta: {
            resource: "TransferenciasPage",
          },
        },
        {
          path: "videos",
          name: "videos",
          component: VideosPage,
          meta: {
            resource: "VideosPage",
          },
        },
        {
          path: "notificaciones/:option",
          name: "notificaciones",
          component: NotificacionesPage,
        },
        {
          path: "callback",
          name: "callback",
          component: CallbackPage,
          meta: {
            anonymous: true,
          },
        },
        {
          path: "404",
          name: "404",
          component: NotFoundErrorPage,
        },
        {
          path: "error",
          name: "error",
          component: ErrorPage,
        },
      ],
    },
    { path: "*", redirect: "/404" },
  ],
});

export const useRouter = (store: IStore): Router => {
  const abilities = buildAbilityFor(store.getters.getLoggedUser);

  router.beforeEach(async (to, _from, next) => {
    const canNavigate = () => {
      return (
        to.meta?.anonymous ||
        abilities.can(to.meta?.action || "read", to.meta?.resource) ||
        !to.meta?.resource
      );
    };

    if (to.matched.some((record) => record.meta.anonymous)) {
      next();
    } else {
      await store.dispatch("setCurrentLocation", `${window.origin}${to.fullPath}`);

      if (!store.getters.getSignedIn) {
        await store.dispatch("initialize");
        abilities.update(defineRulesFor(store.getters.getLoggedUser));
      }

      if (store.getters.getSignedIn) {
        if (!canNavigate()) {
          redirect(to, next, abilities);
        }

        next();
      }
    }
  });

  return router;
};

function redirect(to: Route, next: NavigationGuardNext, abilities: AppAbilityType) {
  if (
    (to.redirectedFrom === "/" || window.location.pathname === "/callback") &&
    abilities.rules?.filter((rule) => rule.subject.toString().includes("Page")).length
  ) {
    const resource = abilities.rules
      ?.filter((rule) => rule.subject.toString().includes("Page"))[0]
      .subject.toString();
    return next(router.getRoutes().find((route) => route.meta.resource === resource));
  } else if (to.meta?.alternativeRedirect) {
    return next(to.meta.alternativeRedirect);
  } else {
    return next({
      name: "error",
      query: {
        type: "403",
      },
    });
  }
}
