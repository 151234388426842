import { IStore } from "@/store";
import Vue from "vue";
import { IProvider } from "./provider.types";
import {
  ClientesService,
  DivisasService,
  MovimientosService,
  OfertasService,
  SaldosService,
} from "./saldosonline";
import { UsuarioService } from "@/services/saldosonline/usuario";
import { PaisService } from "@/services/saldosonline/pais";
import { AuthService } from "@/services/auth";
import { VideosService } from "@/services";
import { ColumnaService } from "@/services/common";
import { I18nService } from "@/services/saldosonline/i18n";
import { DocumentacionService } from "@/services/saldosonline/documentacion/documentacion";
import { TransferenciasService } from "@/services/saldosonline/transferencias";
import { DemandasService } from "@/services/saldosonline/demandas";
import { CargaService } from "@/services/saldosonline/carga";
import { AxiosRequestConfig } from "axios";
import { APPLICATION_JSON } from "@/constans";
import { DisclaimerService } from "@/services/saldosonline/disclaimer";
import { ReportesService } from "@/services/saldosonline/reportes";
import { User } from "oidc-client-ts";

export const provider = (): IProvider => ({
  saldos: new SaldosService(),
  clientes: new ClientesService(),
  divisas: new DivisasService(),
  auth: new AuthService(),
  movimientos: new MovimientosService(),
  columna: new ColumnaService(),
  usuarios: new UsuarioService(),
  pais: new PaisService(),
  i18n: new I18nService(),
  documentacion: new DocumentacionService(),
  transferencias: new TransferenciasService(),
  demandas: new DemandasService(),
  ofertas: new OfertasService(),
  videos: new VideosService(),
  carga: new CargaService(),
  disclaimer: new DisclaimerService(),
  reporte: new ReportesService(),
});

export const prepareServices = (store: IStore): void => {
  store.$services = provider();

  Vue.mixin({
    beforeCreate() {
      this.$services = store.$services;
    },
  });

  const addAuthorizationBearer = async function (config: AxiosRequestConfig) {
    const user = (await provider().auth.getUser()) as User | null;
    const token = user?.id_token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.Accept = APPLICATION_JSON;

    return config;
  };

  Vue.$axios.interceptors.request.use(addAuthorizationBearer);

  Vue.$axiosSilent.interceptors.request.use(addAuthorizationBearer);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const serialize = (p: any): string => {
  const searchParams = new URLSearchParams(p);
  const keysForDel: string[] = [];
  searchParams.forEach((value, key) => {
    if (value === "undefined") {
      keysForDel.push(key);
    }
  });

  keysForDel.forEach((key) => {
    searchParams.delete(key);
  });

  return searchParams.toString();
};
