
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class PFormInputText extends Vue {
  @Prop({ required: true })
  id!: string;
  @Prop({ required: true })
  value!: string;
  @Prop({ required: false, default: "p_form_input_text" })
  variant!: string;
  @Prop({ required: false })
  label!: string;
  @Prop({ required: false, default: "p_form_input_text_label" })
  lclass!: string;
  @Prop({ required: false, default: "text" })
  type!: string;
  @Prop({ required: false, default: undefined })
  state!: boolean;
  @Prop({ required: false, default: undefined })
  invalid_feedback!: string;
  @Prop({ required: false })
  icon!: string;
  @Prop({ required: false, default: "p_icon_defecto" })
  iclass!: string;
  @Prop({ required: false, default: false })
  readonly!: boolean;
  @Prop({ required: false, default: "text" })
  itype!: string;
  @Watch("state")
  onStateChanged(): void {
    this.stateChangedAction();
  }

  stateChangedAction(): void {
    if (this.icon != undefined) {
      this.mostrarIcono = true;
    } else if (this.state === undefined) {
      this.mostrarIcono = false;
    } else if (this.state) {
      this.varIclass = "p_icon_resuelto";
      this.varIcon = "p_icon_check";
      this.mostrarIcono = true;
    } else if (!this.state) {
      this.varIclass = "p_icon_error";
      this.varIcon = "p_icon_info";
      this.mostrarIcono = true;
    }
  }

  varIclass = "p_icon_defecto";
  varIcon = "done";
  mostrarIcono = false;

  get valueLocalComputed(): string {
    return this.value;
  }
  set valueLocalComputed(valor: string) {
    this.$emit("input", valor);
  }

  mostrarLabel = false;

  get inputEstaVacio(): boolean {
    if (this.value === "") {
      return false;
    } else {
      return true;
    }
  }

  mounted(): void {
    if (this.label === undefined) {
      this.mostrarLabel = false;
    } else {
      this.mostrarLabel = true;
    }

    this.varIcon = this.icon;
    this.varIclass = this.iclass;
    this.stateChangedAction();
  }
}
