
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "layout-content",
})
export default class LayoutContent extends Vue {
  constructor() {
    super();
  }
}
