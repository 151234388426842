export interface INivel {
  descNivel?: string;
  codNivel: string;
  id: number;
}
export const iniNivelesPAIS: INivel[] = [
  {
    id: 1,
    codNivel: "ROLE_PAIS",
  },
  {
    id: 2,
    codNivel: "ROLE_CLIENTE",
  },
  {
    id: 3,
    codNivel: "ROLE_DELEGACION",
  },
  {
    id: 4,
    codNivel: "ROLE_PUNTOSERVICIO",
  },
];
export const iniNivelesCLIENTE: INivel[] = [
  {
    id: 2,
    codNivel: "ROLE_CLIENTE",
  },
  {
    id: 3,
    codNivel: "ROLE_DELEGACION",
  },
  {
    id: 4,
    codNivel: "ROLE_PUNTOSERVICIO",
  },
];
export const iniNivelesDELEGACION: INivel[] = [
  {
    id: 3,
    codNivel: "ROLE_DELEGACION",
  },
  {
    id: 4,
    codNivel: "ROLE_PUNTOSERVICIO",
  },
];
