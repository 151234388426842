<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12">
    <g fill="gray">
      <path
        d="M3.025 11.226h12.978c.796 0 1.433-.637 1.433-1.433V3.025c0-.756-.597-1.393-1.354-1.433v-.12C16.082.638 15.406 0 14.61 0H1.473C.637 0 0 .677 0 1.473V8.36c0 .836.677 1.473 1.473 1.473h.12c.04.756.676 1.393 1.432 1.393zM.597 8.36V1.513c0-.478.398-.876.876-.876H14.57c.477 0 .875.398.875.876v6.886c0 .478-.398.876-.875.876H1.473c-.478-.04-.876-.438-.876-.915zM14.57 9.833c.836 0 1.473-.677 1.473-1.473V2.19c.437.04.756.397.756.835v6.728c0 .478-.398.836-.836.836H3.025c-.438 0-.796-.358-.836-.757h12.38z"
      />
      <path
        d="M13.057 2.15V1.83H3.025v.279c0 .478-.398.876-.875.876H1.87v3.821h.279c.477 0 .875.398.875.876v.279h10.032v-.28c0-.477.398-.875.876-.875h.278V3.025h-.278c-.478 0-.876-.398-.876-.875zm.597 4.14c-.597.119-1.035.557-1.154 1.154H3.583c-.12-.597-.558-1.035-1.155-1.154V3.583c.597-.12 1.035-.558 1.155-1.155H12.5c.119.597.557 1.035 1.154 1.155V6.29z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconBilletes",
};
</script>

<style scoped></style>
