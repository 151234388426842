
import Component from "vue-class-component";
import { Prop, Vue } from "vue-property-decorator";

@Component
export default class POptionBar extends Vue {
  @Prop({
    required: true,
    default: [],
  })
  options!: { text: string; pressed: boolean; data: any }[];

  @Prop({
    required: false,
    default: true,
  })
  onlyOneActive!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  navigation!: boolean;

  mounted(): void {
    if (this.navigation) {
      this.$router.afterEach((to) => {
        const option = this.options.find((o) => to.path.toString().includes(o.data));

        if (option) {
          this.pressOption(option);
        }
      });
    }
  }

  toggleOnOff(option: { text: string; pressed: boolean; data: any }): void {
    this.pressOption(option);
    this.$emit("onPressed", option.data);
  }

  pressOption(option: { text: string; pressed: boolean; data: any }): void {
    if (this.onlyOneActive) {
      this.options.forEach((o) => (o.pressed = false));
    }
    option.pressed = !option.pressed;
  }
}
