export interface IPais {
  codPais: string;
  codPaisCorto: string;
  codTimezone: string;
  descripcion: string;
  id: number;
  hasTasa: boolean;
  admiteAnonimo: boolean;
  soConfig: ICountryConfig;
  dateFormat: string;
  intercambioDelegaciones: boolean;
  importCheck: boolean;
  bcr: boolean;
  hasInfoBancaria: boolean;
  diasVisualizarVideos: number;
  descargarVideos: boolean;
}
export const initBilletesFalsosConfigData: IBilletesFalsosConfigData = {
  Enabled: true,
};
export const initICountryConfigData: ICountryConfigData = {
  BilletesFalsos: initBilletesFalsosConfigData,
  Transferencias: {
    Oferta: {
      ShowInfoBancaria: false,
    },
    Demanda: {
      ShowInfoBancaria: false,
    },
  },
};
export const iniPais: IPais = {
  codPais: "",
  codPaisCorto: "",
  codTimezone: "",
  descripcion: "",
  id: 0,
  hasTasa: false,
  admiteAnonimo: true,
  soConfig: initICountryConfigData,
  dateFormat: "",
  intercambioDelegaciones: false,
  importCheck: false,
  bcr: false,
  hasInfoBancaria: false,
  diasVisualizarVideos: 7,
  descargarVideos: false,
};
export interface IDisplayColumna {
  codColumna: string;
  codColumnaDetalle: string;
  codTabla: string;
  id: number;
}
export interface IBilletesFalsosConfigData {
  readonly Enabled?: boolean;
}
export interface ITransferenciasConfigData {
  readonly Oferta?: IOfertaConfigData;
  readonly Demanda?: IDemandaConfigData;
}
export interface IOfertaConfigData {
  readonly ShowInfoBancaria?: boolean;
}
export interface IDemandaConfigData {
  readonly ShowInfoBancaria?: boolean;
}
export interface ICountryConfigData {
  readonly BilletesFalsos: IBilletesFalsosConfigData;
  readonly Transferencias: ITransferenciasConfigData;
}
export interface IBilletesFalsosConfig extends IBilletesFalsosConfigData {}
export interface IOfertaConfig extends IOfertaConfigData {}
export interface IDemandaConfig extends IDemandaConfigData {}
export interface ITransferenciasConfig extends ITransferenciasConfigData {
  readonly Oferta?: IOfertaConfig;
  readonly Demanda?: IDemandaConfig;
}
export interface ICountryConfig extends ICountryConfigData {
  readonly BilletesFalsos: IBilletesFalsosConfig;
  readonly Transferencias: ITransferenciasConfig;
}
