
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export class PFormRadioButton extends Vue {
  @Prop({ required: true }) value!: any;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.valueLocal = this.value;
  }
  @Prop({ required: false, default: "" }) label!: string;
  @Prop({ required: false, default: "Si" }) textTrue!: string;
  @Prop({ required: false, default: "No" }) textFalse!: string;
  @Prop({ required: false, default: true }) valueTrue!: any;
  @Prop({ required: false, default: false }) valueFalse!: any;
  @Prop({ required: false, default: false }) readonly!: any;
  valueLocal = "";
  get classTrue(): string {
    if (this.valueLocal === this.valueTrue) {
      return "trueSelected_PFormRadioButton";
    } else {
      return "true_PFormRadioButton";
    }
  }
  get classFalse(): string {
    if (this.valueLocal === this.valueFalse) {
      return "falseSelected_PFormRadioButton";
    } else {
      return "false_PFormRadioButton";
    }
  }
  ClickTrue(): void {
    this.valueLocal = this.valueTrue;
    this.$emit("input", this.valueLocal);
  }
  ClickFalse(): void {
    this.valueLocal = this.valueFalse;
    this.$emit("input", this.valueLocal);
  }
}
export default PFormRadioButton;
