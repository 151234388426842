import { ICliente, IClienteAll, iniPais } from "@/entities";

export const CONTENT_TYPE = "Content-Type";
export const ACCEPT = "Accept";
export const APPLICATION_JSON = "application/json";
export const APPLICATION_X_WWW_FORM_URLENCODED = "application/x-www-form-urlencoded";

export const ROLE_PAIS = 1;
export const ROLE_CLIENTE = 2;
export const ROLE_DELEGACION = 3;
export const ROLE_PS = 4;

export const MILLISECONDS_24_HORAS = 86400000;
export const ESTADO_VIDEO_COMPLETADO = "COMPLETADO";
export const ESTADO_VIDEO_VISUALIZADO = "VISUALIZADO";

export const MAX_RANGE_MONTH_DATE = 3;

export const todosClienteAll: IClienteAll = {
  id: -1,
  codCliente: "Todos",
  codDesCliente: "Todos",
  desCliente: "Todos",
  fakeCerts: false,
  active: true,
};

export const todosCliente: ICliente = {
  id: -1,
  codCliente: "Todos",
  codDesCliente: "Todos",
  desCliente: "Todos",
  fakeCerts: false,
  active: true,
  clientexdels: [],
  divisas: [],
  fecBaja: "",
  fecCrear: "",
  fecModif: "",
  pais: iniPais,
};
