
import { Component, Vue } from "vue-property-decorator";
import { IOptionBarItem } from "@/components/look";
import { PPage } from "@/components/look/PPage/PPage.vue";
import POptionBarNew from "@/components/look/POptionBarNew/POptionBarNew.vue";
import ByDocumentsComponent from "@/components/documentacion/documents/ByDocumentsComponent.vue";
import ByDocumentsNonHistoricalComponent from "@/components/documentacion/documents/ByDocumentsNonHistoricalComponent.vue";
import ByDelegationComponent from "@/components/documentacion/delegation/ByDelegationComponent.vue";
import ByImportacionComponent from "@/components/documentacion/importacion/ByImportacionComponent.vue";
import { Action } from "vuex-class";
import { EDirection, IColumnaResponse, IDocumentacionParams } from "@/services";
import VueMethods from "@/vue-methods";
import format from "date-fns/format";

@Component({
  name: "documentacion-page",
  components: {
    PPage,
    POptionBarNew,
    ByDocumentsComponent,
    ByDocumentsNonHistoricalComponent,
    ByDelegationComponent,
    ByImportacionComponent,
  },
  mixins: [VueMethods],
})
export default class DocumentacionPage extends Vue {
  @Action("fetchColumna") fetchColumna!: (
    codTabla: string
  ) => Promise<IColumnaResponse | undefined>;

  private queryParams = this.$route.query;

  public params: IDocumentacionParams = {
    codCliente: this.queryParams.codCliente?.toString(),
    delegaciones: this.$route.query.delegaciones
      ? this.$route.query.delegaciones.toString().split(",")
      : [],
    tipoDocumento: this.queryParams.tipoDocumento?.toString(),
    fechaInicio: this.queryParams.fechaInicio?.toString() || format(new Date(), "yyyy-MM-dd"),
    fechaFin: this.queryParams.fechaFin?.toString() || format(new Date(), "yyyy-MM-dd"),
    direction: EDirection.DESC,
    orderBy: "id",
    page: 0,
    size: 10,
  };
  public documentosConfig: IColumnaResponse | null = null;
  public delegacionConfig: IColumnaResponse | null = null;
  public importacionConfig: IColumnaResponse | null = null;
  public documentosNonHistoricalConfig: IColumnaResponse | null = null;

  async created(): Promise<void> {
    const responses = await Promise.all([
      this.fetchColumna("documentos"),
      this.fetchColumna("documentosxdelegacion"),
      this.fetchColumna("documentosImportados"),
      this.fetchColumna("documentosNonHistorical"),
    ]);

    this.documentosConfig = responses[0] ?? {
      codTabla: "documentos",
      codColumna: "",
    };
    this.delegacionConfig = responses[1] ?? {
      codTabla: "documentosxdelegacion",
      codColumna: "",
    };
    this.importacionConfig = responses[2] ?? {
      codTabla: "documentosImportados",
      codColumna: "",
    };
    this.documentosNonHistoricalConfig = responses[3] ?? {
      codTabla: "documentosNonHistorical",
      codColumna: "",
    };
  }

  get options(): IOptionBarItem[] {
    return [
      {
        text: this.$t("label.historical").toString(),
        value: "historico",
        hidden: !this.$ability.can("read", "ByDocuments"),
      },
      {
        text: this.$t("label.delegation").toString(),
        value: "delegacion",
        hidden: !this.$ability.can("read", "ByDelegation"),
      },
      {
        text: this.$t("label.importacion").toString(),
        value: "importacion",
        hidden: !this.$ability.can("read", "ByImportacion"),
      },
      {
        text: this.$t("label.documents").toString(),
        value: "documentos",
        hidden: !this.$ability.can("read", "ByDocumentsNonHistorical"),
      },
    ];
  }

  get optionSelected(): string {
    return this.$route.name || "documentos";
  }

  set optionSelected(option: string) {
    this.$router.push({ path: `/documentacion/${option}` });
  }
}
