
import { Component, Prop, Vue } from "vue-property-decorator";
import TransferenciasPublicasFiltersForm from "./TransferenciasPublicasFiltersForm.vue";
import TransferenciasPublicasTable from "./TransferenciasPublicasTable.vue";
import DemandarSobreOferta from "./DemandarSobreOferta.vue";
import OfertarSobreDemanda from "./OfertarSobreDemanda.vue";
import { Action, Getter } from "vuex-class";
import {
  IColumnaResponse,
  IDemandaParams,
  iniPaged,
  IOfertaParams,
  IPaged,
  ITransferenciaFilter,
  ITransferenciasData,
} from "@/services";
import { ICliente, IDemanda, IDivisa, iniDivisa, IOferta } from "@/entities";
import QuickFilter from "@/pages/transferencias/common/QuickFilter.vue";
import { iniQuickFilter, IQuickFilter } from "@/pages/transferencias/types/quickFilter.types";
import { EventBus } from "@/event-bus";
import { ITransferenciasAll } from "@/pages/transferencias/types";
import { IOfertaDemanda } from "@/entities/centros-efectivo-backend/ofertaDemanda.types";

@Component({
  components: {
    TransferenciasPublicasFiltersForm,
    TransferenciasPublicasTable,
    DemandarSobreOferta,
    OfertarSobreDemanda,
    QuickFilter,
  },
})
export class TransferenciasPublicas extends Vue {
  @Prop({ required: true }) publicasOfertasConfig!: IColumnaResponse;
  @Prop({ required: true }) publicasDemandasConfig!: IColumnaResponse;
  @Getter("getClientesAll") getClientes!: ICliente[];
  @Getter("getDivisas") getDivisas!: IDivisa[];
  @Getter("getClienteXDivisa") getClienteXDivisa!: IDivisa[];
  @Action("fetchPublicas") fetchPublicas!: (
    params: ITransferenciaFilter
  ) => Promise<ITransferenciasData | undefined>;
  @Action("saveDemanda") saveDemanda!: (params?: IDemandaParams) => Promise<IDemanda | undefined>;
  @Action("saveOferta") saveOferta!: (params?: IOfertaParams) => Promise<IOferta | undefined>;
  @Action("setClienteSelected") setClienteSelected!: (cliente?: ICliente) => void;

  datosDemandarSobreOferta: { oferta: Partial<IOfertaDemanda>; divisa: IDivisa } = {
    oferta: {},
    divisa: iniDivisa,
  };
  datosOfertarSobreDemanda: { demanda: Partial<IOfertaDemanda>; divisa: IDivisa } = {
    demanda: {},
    divisa: iniDivisa,
  };
  fecExpOk = false;
  verDemandarSobreOferta = true;
  verOfertarSobreDemanda = false;
  verModal = false;
  titleModal = "";
  mostrarFiltros = false;
  filters: any = {
    delegationsSelected: [],
    pkCoste: [],
    denominacionesSelected: [],
  };
  quickFilter: IQuickFilter = iniQuickFilter;
  get dameTextoBotonFiltros(): string {
    let numFiltros = 0;
    Object.keys(this.filters).forEach((key: string) => {
      if (this.filters[key] != "" && this.filters[key] != null) {
        numFiltros = numFiltros + 1;
      }
    });

    return numFiltros
      ? `(${numFiltros}) ${this.$t("label.filtros")}`
      : this.$t("label.filtros").toString();
  }

  queryParams = this.$route.query;
  params: ITransferenciaFilter = {
    pkCliente: this.queryParams.pkCliente ? Number(this.queryParams.pkCliente) : undefined,
    tipo: this.queryParams.tipo?.toString(),
    delegaciones: this.queryParams.delegaciones
      ?.toString()
      .split(",")
      .map((pk) => Number(pk)),
    estado: this.queryParams.estado?.toString().split(","),
  };

  transferenciasAll: ITransferenciasAll[] = [];
  transferencias(tipo: "OFERTA" | "DEMANDA"): ITransferenciasAll[] {
    let result: ITransferenciasAll[] = [];
    const filtradoPorTipo = this.transferenciasAll.filter((data) => data.tipo === tipo);
    //filtramos por divisas seleccionadas
    this.quickFilter.divisas.forEach((divisa) => {
      const filtradoPorDivisas = filtradoPorTipo.filter((data) => data.divisa.codDivisa === divisa);
      result = result.concat(filtradoPorDivisas);
    });
    result.forEach((r) => {
      r.transferencias.forEach((transferencia) => {
        if (transferencia.checkAnonimo) transferencia.clientexdel.cliente.desCliente = "-";
      });
    });
    return result;
  }
  cerrarOfertarSobreDemanda(): void {
    this.verModal = false;
    this.verOfertarSobreDemanda = false;
  }
  cerrarDemandarSobreOferta(): void {
    this.verModal = false;
    this.verDemandarSobreOferta = false;
  }
  checkClient(): void {
    if (!this.getClientes.find((c) => c.id === Number(this.params.pkCliente))) {
      this.params.pkCliente = undefined;
    }
  }
  async getPublicas(
    tipo: "OFERTA" | "DEMANDA",
    divisa: IDivisa,
    pagination: IPaged
  ): Promise<ITransferenciasData | undefined> {
    this.checkClient();
    const params: ITransferenciaFilter = Object.assign({}, this.params);
    params.pkDivisa = divisa.id;
    params.tipo = tipo;
    params.page = pagination.page! - 1;
    params.size = pagination.size;
    params.orderBy = pagination.orderBy;
    params.direction = pagination.direction;
    const response: ITransferenciasData | undefined = await this.fetchPublicas(params);
    return response;
  }
  cargarTodosDatos(): void {
    this.transferenciasAll = [];
    this.getClienteXDivisa.forEach(async (divisa) => {
      this.cargarDatos("OFERTA", divisa);
      this.cargarDatos("DEMANDA", divisa);
    });
  }
  async cargarDatos(tipo: "OFERTA" | "DEMANDA", divisa: IDivisa): Promise<void> {
    const transferecias: ITransferenciasAll | undefined = this.transferenciasAll.find(
      (trans) => trans.tipo === tipo && trans.divisa.codDivisa === divisa.codDivisa
    );
    if (transferecias) {
      //Como ya existe cargamos los datos nuevos
      const response: ITransferenciasData | undefined = await this.getPublicas(
        tipo,
        divisa,
        transferecias.pagination
      );
      if (response) {
        transferecias.transferencias = response.transferencias.content;
        transferecias.pagination.totalElements = response.transferencias.totalElements;
        transferecias.cantidadDisponible = response.cantidadDisponible;
      }
    } else {
      const pagination: IPaged = iniPaged;

      //Aqui se inicializa si no existe lo datos de tipo y divisa
      const response: ITransferenciasData | undefined = await this.getPublicas(
        tipo,
        divisa,
        pagination
      );

      if (response) {
        pagination.totalElements = response.transferencias.totalElements;
        this.transferenciasAll.push({
          tipo: tipo,
          divisa: divisa,
          transferencias: response.transferencias.content,
          cantidadDisponible: response.cantidadDisponible,
          pagination: pagination,
          totalElements: response.transferencias.totalElements,
        });
      }
    }
  }
  async actionDemandarSobreOferta(action: { action: string; data?: any }): Promise<void> {
    if (action.action === "demandarSobreOferta") {
      this.fecExpOk = false;
      this.datosDemandarSobreOferta.oferta = action.data.oferta;
      this.datosDemandarSobreOferta.divisa = action.data.divisa;
      this.verModal = true;
      this.titleModal = this.$t("title.modal.transfer.public.demand").toString();
      this.verDemandarSobreOferta = true;
      this.verOfertarSobreDemanda = false;
      if (
        this.datosDemandarSobreOferta.oferta.fecExpirar !== undefined &&
        new Date(this.datosDemandarSobreOferta.oferta.fecExpirar) >= new Date()
      ) {
        this.fecExpOk = true;
      }
    } else if (action.action === "demandarSobreOfertaConfirmed") {
      if (
        this.fecExpOk &&
        action.data.fecExpirar !== undefined &&
        action.data.fecExpirar < new Date()
      ) {
        this.$swal({
          icon: "error",
          title: this.$i18n.t("error") as string,
          html: this.$i18n.t("label.oferta.expirada") as string,
        });
      } else {
        const res = await this.saveDemanda(action.data);

        if (res) {
          this.cargarTodosDatos();
          this.verModal = false;
          this.titleModal = "";
          EventBus.$emit("p-toast", {
            variant: "success",
            message: this.$t("label.demanda.guardada").toString(),
          });
        } else {
          EventBus.$emit("p-toast", { variant: "danger", message: res });
        }
      }
    }
  }
  async actionOfertarSobreDemanda(action: { action: string; data?: any }): Promise<void> {
    this.fecExpOk = false;
    if (action.action === "ofertarSobreDemanda") {
      this.datosOfertarSobreDemanda.demanda = action.data.demanda;
      this.datosOfertarSobreDemanda.divisa = action.data.divisa;

      this.verModal = true;
      this.titleModal = this.$t("label.transferir").toString();
      this.verDemandarSobreOferta = false;
      this.verOfertarSobreDemanda = true;
      if (
        this.datosOfertarSobreDemanda.demanda.fecExpirar !== undefined &&
        new Date(this.datosOfertarSobreDemanda.demanda.fecExpirar) >= new Date()
      ) {
        this.fecExpOk = true;
      }
    } else if (action.action === "ofertarSobreDemandaConfirmed") {
      if (
        this.fecExpOk &&
        action.data.fecExpirar.fecExpirar !== undefined &&
        action.data.fecExpirar < new Date()
      ) {
        this.$swal({
          icon: "error",
          title: this.$i18n.t("error") as string,
          html: this.$i18n.t("label.demanda.expirada") as string,
        });
      } else {
        const res = await this.saveOferta(action.data);

        if (res) {
          this.cargarTodosDatos();
          this.verModal = false;
          this.titleModal = "";
          EventBus.$emit("p-toast", {
            variant: "success",
            message: this.$t("label.oferta.guardada").toString(),
          });
        } else {
          EventBus.$emit("p-toast", { variant: "danger", message: res });
        }
      }
    }
  }

  actionQuickFilter(action: { action: string; data?: any }): void {
    if (action.action === "quickFilter_cliente") {
      //llamaremos con el filtro del cliente

      if (action.data.cliente == -1) {
        this.params.pkCliente = undefined;
      } else {
        this.params.pkCliente = action.data.cliente;
      }
      this.cargarTodosDatos();
    } else if (action.action === "quickFilter_divisa") {
      //lo dejo por si hay que hacer alguna accion a futuro
    } else if (action.action === "quickFilter_tipo") {
      //lo dejo por si hay que hacer alguna accion a futuro
    }
    this.params.page = 1;
  }

  async action(action: { action: string; data?: any }): Promise<void> {
    if (action.action === "print") {
      alert("print");
    } else if (action.action === "download") {
      alert("download");
    } else if (action.action === "filtrar" || action.action === "clean") {
      this.params.page = 1;
      this.params.delegaciones = this.filters.delegationsSelected ?? undefined;
      this.params.pkCoste = this.filters.pkCoste ?? undefined;
      this.params.calidadesDenominacion = this.filters.denominacionesSelected ?? undefined;
      this.cargarTodosDatos();
      this.mostrarFiltros = false;
    } else if (action.action === "changeTable") {
      await this.cargarDatos(action.data.tipo, action.data.divisa);
    }
    this.actionQuickFilter(action);
    await this.actionDemandarSobreOferta(action);
    await this.actionOfertarSobreDemanda(action);
  }
  mounted(): void {
    this.getClienteXDivisa.forEach((divisa) => {
      if (this.quickFilter.divisas.findIndex((div) => div === divisa.codDivisa) < 0)
        this.quickFilter.divisas.push(divisa.codDivisa);
    });
  }
}
export default TransferenciasPublicas;
