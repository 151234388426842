
import { Component, Vue } from "vue-property-decorator";
import ResumenOferta from "./ResumenOferta.vue";
import {
  ICalidadDenominacion,
  ICliente,
  IDelegacion,
  IDenominacion,
  IDetallesSaldo,
  IDivisa,
  IParametro,
  ISaldo,
} from "@/entities";
import Multiselect from "vue-multiselect";
import { Action, Getter } from "vuex-class";
import {
  IDetalleOfertaParams,
  IOfertaParams,
  IOfertaPaso4Params,
  ISaldoActualParams,
} from "@/services";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import AsyncComputed from "vue-async-computed-decorator";
import VueMethods from "@/vue-methods";
import { SweetAlertResult } from "sweetalert2";
import VueFilters from "@/vue-filters";

@Component({
  name: "ofertar-form",
  mixins: [VueMethods, VueFilters],
  components: {
    ResumenOferta,
    Multiselect,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class OfertarForm extends Vue {
  @Action("fetchSaldoActual") fetchSaldoActual!: (
    params?: ISaldoActualParams
  ) => Promise<ISaldo | undefined>;
  @Getter("getClienteSelected") getClienteSelected!: ICliente;
  @Getter("getClientesAll") getClientesAll!: ICliente[];
  @Getter("getCostes") getCostes!: IParametro[];

  $refs!: {
    formOfertar: InstanceType<typeof ValidationObserver>;
  };

  phase1 = true;
  phase2 = false;
  phase3 = false;
  phase4 = false;
  phase5 = false;

  clienteSelected: ICliente | null = null;
  delegationSelected: IDelegacion | null = null;
  divisaSelected: IDivisa | null = null;

  saldoActual: ISaldo | null = null;
  detallesSaldo: IDetallesSaldo[] = [];
  saldoDisponible = 0;

  costeSelected: IParametro | null = null;
  fechaExpiracion: string | null = null;
  horaExpiracion: string | null = null;
  anonimo = false;
  tasa = 0;

  delegationsOptional: number[] = [];
  isShowBankField = false;

  ofertaPaso4Params: IOfertaPaso4Params = {
    relleno: false,
    cuentaCorriente: "",
    agNumeroSucursal: "",
    ispbBanco: "",
    cnpjPersona: "",
    observacionesOferta: "",
  };

  get delegationsOptionalFiltered(): IDelegacion[] {
    return this.delegations.filter((del) => del.id != this.delegationSelected?.id);
  }

  get delegationsOptionalSelected(): number[] {
    return this.delegationsOptional.filter((id) => id != this.delegationSelected?.id);
  }

  get divisas(): IDivisa[] {
    return this.clienteSelected?.divisas ?? [];
  }

  get calidadesDenominacion(): ICalidadDenominacion[] {
    const calidades: ICalidadDenominacion[] = [];
    if (this.clienteSelected?.fakeCerts) {
      const denominaciones = Object.assign([], this.divisaSelected?.denominaciones);
      denominaciones?.sort((a, b) => this.sortDescending(a, b, "numValue"));
      denominaciones?.forEach((denominacion: IDenominacion) => {
        denominacion.calidades?.forEach((calidad) => {
          if (calidad) {
            this.$set(calidad, "importe", 0);
            this.$set(calidad, "importeSelected", false);
            this.$set(calidad, "cantidad", 0);
            this.$set(calidad, "cantidadSelected", false);
            calidades.push(calidad);
          }
        });
      });
    }
    return calidades;
  }

  async mounted(): Promise<void> {
    extend("date_future_oferta", {
      validate: async () => {
        if (this.fechaExpiracion && this.horaExpiracion) {
          return (
            new Date(
              Number(this.fechaExpiracion?.split("-")[0]),
              Number(this.fechaExpiracion?.split("-")[1]) - 1,
              Number(this.fechaExpiracion?.split("-")[2]),
              Number(this.horaExpiracion?.split(":")[0]),
              Number(this.horaExpiracion?.split(":")[1])
            ) > new Date()
          );
        }
        return false;
      },
      message: () => this.$t("validation.messages.date_must_future") as string,
    });

    this.clienteSelected = this.getClienteSelected;
  }

  get delegations(): IDelegacion[] {
    const d: IDelegacion[] = [];

    if (this.clienteSelected?.clientexdels) {
      this.clienteSelected?.clientexdels.forEach((clientexdel) => {
        if (clientexdel?.delegacion && clientexdel?.active) {
          d.push(clientexdel.delegacion);
        }
      });
      return d.sort((a, b) => this.sortAscending(a, b, "desDelegacion"));
    }
    return [];
  }

  async clienteSelectedInput(): Promise<void> {
    let aux = await this.$services.clientes._fetchCliente(Number(this.clienteSelected?.id));
    if (aux) {
      this.clienteSelected = aux;
      this.isShowBankField = aux.pais.hasInfoBancaria;
    }
    this.delegationsOptional = [];
    this.delegationSelected = null;
    this.divisaSelected = null;
    this.limpiarOfertaPaso4();
  }

  async searchSaldosActuales(): Promise<void> {
    if (this.clienteSelected && this.delegationSelected && this.divisaSelected) {
      const res = await this.fetchSaldoActual({
        pkCliente: this.clienteSelected.id!,
        pkDelegacion: this.delegationSelected.id,
        pkDivisa: this.divisaSelected.id,
      });
      if (res?.saldoActual) {
        this.saldoActual = res;
        if (this.saldoActual.saldoActual > 0) {
          this.saldoDisponible = this.saldoActual?.saldoActual;
          this.detallesSaldo = this.saldoActual?.detallesSaldo;
          this.detallesSaldo.forEach((detalleSaldo) => {
            this.$set(detalleSaldo.calidadDenominacion!, "importe", 0);
            this.$set(detalleSaldo.calidadDenominacion!, "importeSelected", false);
            this.$set(detalleSaldo.calidadDenominacion!, "cantidad", 0);
            this.$set(detalleSaldo.calidadDenominacion!, "cantidadSelected", false);
          });
        } else {
          await this.modalAlert();
        }
      } else {
        await this.modalAlert();
      }
    }
  }

  async modalAlert(): Promise<SweetAlertResult> {
    this.saldoDisponible = 0;
    return this.$swal({
      title: this.$t("label.advertencia").toString(),
      text: this.$t("label.saldo.no.disponible").toString(),
      icon: "warning",
    });
  }

  fechaDefecto(): void {
    let currentDate = new Date();
    const currentDateIn17Hours = new Date();
    currentDateIn17Hours.setHours(17);
    currentDateIn17Hours.setMinutes(0);

    if (currentDate.getTime() > currentDateIn17Hours.getTime()) {
      currentDate.setDate(currentDate.getDate() + 1);
    }

    this.fechaExpiracion = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}`;

    this.horaExpiracion = "17:00:00";
  }

  get saldoRestante(): number {
    return Number(this.saldoDisponible) - Number(this.saldoOfertar);
  }

  get saldoOfertar(): number {
    let sumaImportes = 0;
    if (this.clienteSelected?.fakeCerts) {
      this.calidadesDenominacion?.forEach((calidadDenominacion) => {
        if (calidadDenominacion.importe) {
          sumaImportes = Number(sumaImportes) + Number(calidadDenominacion.importe);
        }
      });
    } else {
      this.detallesSaldo?.forEach((detalleSaldo) => {
        if (detalleSaldo.calidadDenominacion?.importe) {
          sumaImportes = Number(sumaImportes) + Number(detalleSaldo.calidadDenominacion?.importe);
        }
      });
    }

    return sumaImportes;
  }

  @AsyncComputed()
  async continuarCondition(): Promise<boolean> {
    if (this.phase1) {
      return false;
    } else if (this.phase2) {
      if (this.clienteSelected?.fakeCerts) {
        if (this.saldoOfertar > 0 && (await this.$refs.formOfertar.validate())) {
          return false;
        }
      } else if (
        this.saldoOfertar > 0 &&
        this.saldoRestante > 0 &&
        (await this.$refs.formOfertar.validate())
      ) {
        return false;
      }
    } else if (this.phase3) {
      if (
        this.fechaExpiracion &&
        this.horaExpiracion &&
        this.costeSelected &&
        (await this.$refs.formOfertar.validate({ silent: true }))
      ) {
        return false;
      }
    } else if (this.phase4) {
      if (await this.$refs.formOfertar.validate({ silent: true })) {
        return false;
      }
    }

    return true;
  }

  atras(): void {
    if (this.phase2) {
      this.phase1 = true;
      this.phase2 = false;
      this.detallesSaldo = [];
    } else if (this.phase3) {
      this.phase2 = true;
      this.phase3 = false;
      this.fechaExpiracion = null;
      this.horaExpiracion = null;
      this.costeSelected = null;
      this.anonimo = false;
    } else if (this.phase4) {
      this.phase3 = true;
      this.phase4 = false;
      this.limpiarOfertaPaso4();
    } else if (this.phase5) {
      this.phase4 = true;
      this.phase5 = false;
    } else if (!this.phase1 && !this.phase2 && !this.phase3 && !this.phase4 && !this.phase5) {
      this.phase5 = true;
    }
  }

  continuar(): void {
    if (this.phase1) {
      if (this.clienteSelected && this.delegationSelected && this.divisaSelected) {
        this.phase1 = false;
        this.phase2 = true;
        if (!this.clienteSelected?.fakeCerts) {
          this.searchSaldosActuales();
        }
      }
    } else if (this.phase2) {
      this.phase2 = false;
      this.phase3 = true;
      this.fechaDefecto();
    } else if (this.phase3) {
      this.phase3 = false;
      this.phase4 = true;
      this.ofertaPaso4Params.relleno = true;
    } else if (this.phase4) {
      this.phase4 = false;
      this.phase5 = true;
    }
  }

  cancelar(): void {
    this.phase5 = false;
    this.phase4 = true;
  }

  infoOfertar(): void {
    this.phase1 = false;
    this.phase2 = false;
    this.phase3 = false;
    this.phase4 = false;
    this.phase5 = false;
  }

  limpiarOfertaPaso4(): void {
    this.ofertaPaso4Params.cuentaCorriente = null;
    this.ofertaPaso4Params.agNumeroSucursal = null;
    this.ofertaPaso4Params.ispbBanco = null;
    this.ofertaPaso4Params.cnpjPersona = null;
    this.ofertaPaso4Params.observacionesOferta = null;
    this.ofertaPaso4Params.relleno = false;
  }

  async ofertar(): Promise<void> {
    const detallesOferta: IDetalleOfertaParams[] = [];
    if (!this.clienteSelected?.fakeCerts) {
      this.detallesSaldo.forEach((detalle) => {
        if (detalle.calidadDenominacion && detalle.calidadDenominacion.importe > 0) {
          detallesOferta.push({
            numCantidad: detalle.calidadDenominacion.importe,
            calidadDenominacion: detalle.calidadDenominacion,
          });
        }
      });
    } else {
      this.calidadesDenominacion.forEach((calidadDenominacion) => {
        if (calidadDenominacion && calidadDenominacion.importe > 0) {
          detallesOferta.push({
            numCantidad: calidadDenominacion.importe,
            calidadDenominacion: calidadDenominacion,
          });
        }
      });
    }
    if (
      this.costeSelected &&
      this.divisaSelected &&
      this.delegationSelected &&
      this.clienteSelected
    ) {
      const ofertaParams: IOfertaParams = {
        numCantidad: this.saldoOfertar,
        fecExpirar: new Date(this.fechaExpiracion + " " + this.horaExpiracion),
        checkAnonimo: this.anonimo,
        coste: this.costeSelected.id,
        pkDivisa: this.divisaSelected.id,
        pkCliente: this.clienteSelected.id!,
        pkDelegacion: this.delegationSelected.id,
        detallesOferta: detallesOferta,
        numTasa: this.clienteSelected.pais.hasTasa ? Number(this.tasa) : undefined,
        pkDelegaciones: this.delegationsOptionalSelected,
        observacionesOferta: this.ofertaPaso4Params.observacionesOferta ?? undefined,
        cuentaCorriente: this.ofertaPaso4Params.cuentaCorriente ?? undefined,
        agNumeroSucursal: this.ofertaPaso4Params.agNumeroSucursal ?? undefined,
        ispbBanco: this.ofertaPaso4Params.ispbBanco ?? undefined,
        cnpjPersona: this.ofertaPaso4Params.cnpjPersona ?? undefined,
      };

      this.$emit("action", { action: "ofertarConfirm", data: ofertaParams });
    }
  }

  verifyImporteToZeroInDetalleSaldoById(id: number): void {
    this.detallesSaldo
      .filter((detalleSaldo) => detalleSaldo.id === id)
      .forEach((detalleSaldo) => {
        const importe = detalleSaldo.calidadDenominacion!.importe;
        detalleSaldo.calidadDenominacion!.importe = !importe ? 0 : Number(importe);
      });
  }

  get titleLeftToDelegacionesOpcionales(): string {
    const textLeft = this.delegationSelected?.desDelegacion ?? "";
    const title = this.$t("label.delegaciones.opcionales.form").toString().replace("X", textLeft);
    return this.$t(title).toString();
  }
}
