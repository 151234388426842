
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { IConfColumnaPais, IField, ISoConfColumnasUpdateDTO } from "@/entities";
import { Action } from "vuex-class";
import Pagination from "@/components/common/Pagination.vue";
import VueFilters from "@/vue-filters";

import SelectColumns from "@/components/common/SelectColumns/SelectColumns.vue";
import { EDirection, IColumnaResponse, IPaged, IUsuarioTableContent } from "@/services";
import IconAdministracion from "@/components/common/icon/IconAdministracion.vue";

@Component({
  components: {
    IconAdministracion,
    SelectColumns,
    Pagination,
  },
  mixins: [VueFilters],
})
export default class UsuariosTable extends Vue {
  @Prop({ required: true }) columnsConfig!: IColumnaResponse;
  @Prop({ required: true }) usuarios!: IUsuarioTableContent[];
  @Action("saveColumna") saveColumna!: (
    params: ISoConfColumnasUpdateDTO
  ) => Promise<IColumnaResponse | undefined>;

  codTable = "usuarios";
  sortDesc = this.columnsConfig?.codTipoOrden !== "ASC";
  totalElements = 0;

  pagination: IPaged = {
    direction: this.sortDesc ? EDirection.DESC : EDirection.ASC,
    orderBy: this.columnsConfig?.codOrden ?? "id",
    page: 1,
    size: 10,
  };

  get fields(): IField[] {
    const visibleKeys = this.columnsConfig?.codColumna?.split(",").filter((s) => s !== "") || [];
    let columnas: IField[] = [
      {
        key: "actions-left",
        label: this.$t("label.acciones") as string,
        sortable: false,
        thClass: "table-header table-header-active pl-4",
        tdClass: "table-cell-first actions",
        visible: true,
        class: "table-cell",
        aux: true,
        code: "",
      },
    ];
    this.columnsConfig.columnas?.forEach((column: IConfColumnaPais) => {
      columnas.push({
        key: column.keyFront,
        label: this.$t("label." + column.codColumna) as string,
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        thStyle: column.widthFront ? { width: column.widthFront } : {},
        visible: this.isVisible(visibleKeys, column.codColumna),
        class: "table-cell",
        aux: false,
        code: column.codColumna,
        formatter: (value: any): string => {
          if (column.formatType == "Date") {
            return this.filterDate(value);
          } else if (column.formatType == "Number") {
            return this.filterNumber(value, 2);
          } else if (column.formatType == "Boolean") {
            return value ? (this.$t("label.si") as string) : (this.$t("label.no") as string);
          }
          return value;
        },
      });
    });
    columnas.push({
      key: "actions-right",
      label: "",
      sortable: false,
      thClass: "table-header table-header-add-columnas pb-0",
      tdClass: "table-cell-middle actions",
      visible: true,
      class: "table-cell",
      aux: true,
      code: "",
    });
    return columnas;
  }

  set fields(fields: IField[]) {
    this.columnsConfig.codColumna =
      fields
        .filter((item) => item.visible && !item.aux)
        .map((item) => item.code)
        .join(",") || "";
  }

  get visibleFields(): IField[] {
    return this.fields.filter((item) => item.visible);
  }

  clickAcciones(accion: string, row: unknown): void {
    if (accion === "modificar") {
      this.$emit("edit", row);
    }
  }

  onSortingChange(
    obj: { sortBy: string; sortDesc: boolean },
    usuariosGroup: IUsuarioTableContent
  ): void {
    this.$nextTick(async () => {
      const sortDesc = usuariosGroup ? usuariosGroup.sortDesc : true;
      const orderBy = usuariosGroup ? usuariosGroup.orderBy : "id";
      const pagination: IPaged = {
        direction: sortDesc ? EDirection.DESC : EDirection.ASC,
        orderBy,
        page: usuariosGroup.pagination.page,
        size: usuariosGroup.pagination.size,
      };
      await this.saveColumnsConfig();
      this.$emit("filter", {
        action: "filterPagination",
        dataFilters: {
          pagination,
          configurado: usuariosGroup.configurado,
        },
      });
    });
  }

  onChangeCurrentPage(currentPage: number, usuariosGroup: IUsuarioTableContent): void {
    const sortDesc = usuariosGroup ? usuariosGroup.sortDesc : true;
    const orderBy = usuariosGroup ? usuariosGroup.orderBy : "id";
    const pagination: IPaged = {
      direction: sortDesc ? EDirection.DESC : EDirection.ASC,
      orderBy,
      page: usuariosGroup.pagination.page,
      size: usuariosGroup.pagination.size,
    };

    this.$emit("change", {
      action: "changePagination",
      dataFilters: {
        currentPage: currentPage,
        configurado: usuariosGroup.configurado,
        pagination,
      },
    });
  }

  async saveColumnsConfig(): Promise<void> {
    const params: ISoConfColumnasUpdateDTO = {
      codColumna: this.columnsConfig.codColumna || "",
      codTabla: this.columnsConfig.codTabla,
    };
    if (this.pagination) {
      params.codTipoOrden = this.pagination.direction;
      params.numPagElements = this.pagination.size;
      params.codOrden = this.pagination.orderBy;
    }
    await this.saveColumna(params);
  }

  isVisible(visibleKeys: string[], column: string): boolean {
    return !visibleKeys.length || !!visibleKeys.find((key) => key === column);
  }
}
