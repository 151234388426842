import { ITransferenciasQuickFilter } from "@/pages/transferencias/types";
import { ISaldosQuickFilter } from "@/pages/saldos/types";
import { IGetters, IState, Locale } from "@/store";
import {
  ICliente,
  IClienteAll,
  ICountryConfig,
  IDelegacion,
  IDivisa,
  IPais,
  IParametro,
  IUsuario,
} from "@/entities";
import { Store } from "vuex";
import { CountryConfig } from "@/entities/centros-efectivo-backend/pais";

export const getters: IGetters = {
  getLocale(state: IState): Locale {
    return state.locale;
  },
  getCurrentLocation(state: IState): string {
    return state.currentLocation;
  },
  getSignedIn(state: IState): boolean {
    return state.signedIn;
  },
  getLoggedUser(state: IState): IUsuario | undefined {
    return state.loggedUser;
  },
  getColumnas(state: IState): { codTabla: string; columnas: string[] }[] {
    return state.columnas;
  },
  getSaldosPageQuickFilter(state: IState): ISaldosQuickFilter {
    return state.saldosPageQuickFilter;
  },
  getTransferenciasPageQuickFilter(state: IState): ITransferenciasQuickFilter {
    return state.transferenciasPageQuickFilter;
  },
  getCostes(state: IState): IParametro[] {
    return state.costes;
  },
  getClientes(state: IState): ICliente[] {
    return state.clientes;
  },
  getClientesAll(state: IState): IClienteAll[] {
    return state.clientesAll;
  },
  getClienteSelected(state: IState): ICliente | undefined {
    return state.clienteSelected;
  },
  getClienteXDivisa(state: IState): IDivisa[] {
    return state.clienteXDivisa;
  },
  getCountryConfig(this: Store<IState>, state: IState): ICountryConfig | undefined {
    if (!state.countryConfig) {
      return;
    }
    return new CountryConfig(state.countryConfig);
  },
  getDelegacionesCliente(state: IState): IDelegacion[] {
    return state.delegacionesCliente;
  },
  getDivisas(state: IState): IDivisa[] {
    return state.divisas;
  },
  getPaises(state: IState): IPais[] {
    return state.paises;
  },
  getDisclaimer(state: IState): string | undefined {
    return state.disclaimer;
  },
};
