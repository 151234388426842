
import { ICliente, IDelegacion } from "@/entities";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Locale } from "@/store";
import VueMethods from "@/vue-methods";

@Component({
  mixins: [VueMethods],
})
export class TransferenciasPropiasFiltersForm extends Vue {
  @Prop({ required: true }) value!: any;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }
  valueLocal = {
    fecCertificadoInicio: null,
    fecCertificadoFin: null,
    delegationsSelected: [],
    allEstados: [],
  };
  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;
  @Getter("getLocale") locale!: Locale;
  @Getter("getClienteSelected") clienteSelected!: ICliente;
  validado = false;
  optionEstados = [
    { label: this.$t("label.estado.ACTIVA"), value: "ACTIVA" },
    { label: this.$t("label.estado.COMPLETADA"), value: "COMPLETADA" },
    { label: this.$t("label.estado.CANCELADA"), value: "CANCELADA" },
    { label: this.$t("label.estado.EXPIRADA"), value: "EXPIRADA" },
  ];
  initialValueLocal = {
    fecCertificadoInicio: null,
    fecCertificadoFin: null,
    delegationsSelected: [],
    allEstados: [],
  };

  get delegations(): IDelegacion[] {
    let delegationsList: IDelegacion[] = [];
    this.clienteSelected?.clientexdels.forEach((clientexdel) => {
      if (clientexdel.active) delegationsList.push(clientexdel.delegacion);
    });
    return delegationsList.sort((a, b) => this.sortAscending(a, b, "desDelegacion"));
  }

  limpiar(): void {
    this.valueLocal.fecCertificadoInicio = null;
    this.valueLocal.fecCertificadoFin = null;
    this.valueLocal.delegationsSelected = [];
    this.valueLocal.allEstados = [];
    if (!this.isInitialObject(this.value, this.initialValueLocal)) {
      this.$emit("input", this.valueLocal);
      this.$emit("cancel", { action: "clean" });
    }
  }
  action(action: string): void {
    this.$emit("input", this.valueLocal);
    this.$emit("action", { data: this.valueLocal, action: action });
  }
}
export default TransferenciasPropiasFiltersForm;
