
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { IField, IFields, ISoConfColumnasUpdateDTO } from "@/entities";
import { Action } from "vuex-class";
import { IColumnaResponse, IPaged } from "@/services";

@Component
export default class SelectColumns extends Vue {
  @Prop({ required: true, default: [] }) fields!: IFields;
  @Prop({ required: true, default: null }) pagination: IPaged | undefined;
  @Prop({ required: true, default: "" }) codTable!: string;
  @Action("saveColumna")
  saveColumna!: (params: ISoConfColumnasUpdateDTO) => Promise<IColumnaResponse | undefined>;

  get fieldsLocal(): IFields {
    return this.fields;
  }

  get allVisible(): boolean {
    return this.fields.filter((f) => !(f as IField).visible).length === 0;
  }

  get visibleColumnsKey(): string {
    if ((this.fieldsLocal[1] as IField).code) {
      return this.fieldsLocal
        .filter((f) => (f as IField).visible && !(f as IField).aux)
        .map((f) => (f as IField).code)
        .toString();
    } else {
      return this.fieldsLocal
        .filter((f) => (f as IField).visible && !(f as IField).aux)
        .map((f) => (f as IField).key)
        .toString();
    }
  }

  toggle(field: string | IField): void {
    if (typeof field !== "string") {
      if ((this.fieldsLocal[1] as IField).code) {
        this.$emit(
          "update:fields",
          this.fieldsLocal.map((f) => {
            if ((f as IField).code === field.code) {
              (f as IField).visible = !(f as IField).visible;
            }
            return f;
          })
        );
      } else {
        this.$emit(
          "update:fields",
          this.fieldsLocal.map((f) => {
            if ((f as IField).key === field.key) {
              (f as IField).visible = !(f as IField).visible;
            }
            return f;
          })
        );
      }

      this.save();
    }
  }

  setAllVisible(): void {
    this.$emit(
      "update:fields",
      this.fieldsLocal.map((f) => {
        (f as IField).visible = true;
        return f;
      })
    );
    this.save();
  }

  async save(): Promise<void> {
    const params: ISoConfColumnasUpdateDTO = {
      codColumna: this.visibleColumnsKey,
      codTabla: this.codTable,
    };
    if (this.pagination) {
      params.codTipoOrden = this.pagination.direction;
      params.numPagElements = this.pagination.size;
      params.codOrden = this.pagination.orderBy;
    }
    await this.saveColumna(params);
  }
}
