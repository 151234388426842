
import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Multiselect from "vue-multiselect";
import { Action, Getter } from "vuex-class";
import { IPaisParams, IPaisResponse } from "@/services";
import { IConfColumnaPais, IConfColumnaParams, IPais } from "@/entities";
import ColumnasTable from "@/components/administracion/columnas/ColumnasTable.vue";

@Component({
  components: { ColumnasTable, ValidationObserver, Multiselect },
})
export default class AdminColumnasComponent extends Vue {
  @Action("fetchPais")
  fetchPais!: (params?: IPaisParams) => Promise<IPaisResponse | undefined>;

  @Action("fetchTablasPais")
  fetchTablasPais!: () => Promise<string[] | undefined>;

  @Action("fetchConfColumnasPais")
  fecthConfColumnasPais!: (params: IConfColumnaParams) => Promise<IConfColumnaPais[] | undefined>;

  @Getter("getPaises") optionsCountry!: IPais[];

  public countrySelected: IPais | null = null;
  public countrySelectedId: number | null = null;
  public tableSelected: string | null = null;

  public optionsTable: { label: string; key: string }[] | undefined = [];

  public config: IConfColumnaPais[] | null = null;

  constructor() {
    super();
  }

  async mounted(): Promise<void> {
    await this.searchCountryTables();
  }

  async searchCountryTables(): Promise<void> {
    this.optionsTable = [
      { label: "Saldos", key: "saldos" },
      { label: "Entradas", key: "entradas" },
      { label: "Salidas", key: "salidas" },
      { label: "Documentacion", key: "documentosNonHistorical" },
    ];
  }

  resetTable(): void {
    this.countrySelected =
      this.optionsCountry.find((pais) => pais.id === this.countrySelectedId) || null;
    this.tableSelected = null;
  }

  async search(): Promise<void> {
    if (this.countrySelected) {
      const rest = await this.fecthConfColumnasPais({
        codPais: this.countrySelected.codPaisCorto,
        codTabla: this.tableSelected,
      });
      if (rest) {
        this.config = rest;
      } else {
        this.config = null;
      }
    }
  }

  async update(): Promise<void> {
    await this.search();
  }
}
