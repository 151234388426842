import { IDemandaParams, IDemandasService } from "@/services/saldosonline/demandas/demandas.types";
import { IDemanda } from "@/entities/centros-efectivo-backend/demanda.types";
import { APPLICATION_JSON } from "@/constans";
import { BACKEND_BASE_URL } from "@/services";
import { handleResponse } from "@/services/handlers";
import { IOfertaDemanda, IResponseError } from "@/entities";
import Vue from "vue";
import { i18n } from "@/i18n";

export class DemandasService implements IDemandasService {
  async saveUpdateDemanda(params: IDemandaParams): Promise<IDemanda | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IDemanda, IResponseError>(
      Vue.$axios.post(`${BACKEND_BASE_URL}/api/demanda`, params, { headers })
    );
    if (error) {
      Vue.swal({
        title: i18n.t("error").toString(),
        icon: "error",
        html: `<span>` + error + `</span>`,
      });
      return undefined;
    } else {
      return response!.data;
    }
  }
  async saveUpdateDemandaPrivada(params: IDemandaParams): Promise<IDemanda | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IDemanda, IResponseError>(
      Vue.$axios.post(`${BACKEND_BASE_URL}/api/demandaPrivada`, params, { headers })
    );
    if (error) {
      Vue.swal({
        title: i18n.t("error").toString(),
        icon: "error",
        html: `<span>` + error + `</span>`,
      });
      return undefined;
    } else {
      return response!.data;
    }
  }

  async cancelDemanda(idDemanda: number): Promise<IDemanda | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IDemanda, IResponseError>(
      Vue.$axios.post(`${BACKEND_BASE_URL}/api/demanda/cancelar?id=` + idDemanda, { headers })
    );
    if (error) {
      Vue.swal({
        title: i18n.t("error").toString(),
        icon: "error",
        html: `<span>` + error + `</span>`,
      });
      return undefined;
    } else {
      return response!.data;
    }
  }

  async demandasCompatibles(idOferta: number): Promise<IOfertaDemanda[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IOfertaDemanda[], IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/demanda/compatibles/` + idOferta, { headers })
    );
    if (error) {
      Vue.swal({
        title: i18n.t("error").toString(),
        icon: "error",
        html: `<span>` + error + `</span>`,
      });
      return undefined;
    } else {
      return response!.data;
    }
  }
}
