
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { ICliente, IDelegacion, IField, IParametro, ISoConfColumnasUpdateDTO } from "@/entities";
import { Action, Getter } from "vuex-class";
import Pagination from "@/components/common/Pagination.vue";
import VueFilters from "@/vue-filters";

import SelectColumns from "@/components/common/SelectColumns/SelectColumns.vue";
import QuickFilter from "@/components/documentacion/common/QuickFilter.vue";
import { EDirection, IColumnaResponse } from "@/services";
import {
  IDocumentacionParams,
  IDocumentacionResponse,
} from "@/services/saldosonline/documentacion/documentacion.types";
import { IDocumento } from "@/entities/centros-efectivo-backend/documento.types";
import DocumentacionTableFilters from "@/components/documentacion/DocumentacionTableFilters.vue";
import format from "date-fns/format";
import VueMethods from "@/vue-methods";
import { Dictionary } from "vue-router/types/router";
import { IconDownload, IconTrash } from "@/components/look";
import { MAX_RANGE_MONTH_DATE } from "@/constans";
import { SweetAlertResult } from "sweetalert2";

@Component({
  components: {
    DocumentacionTableFilters,
    IconDownload,
    IconTrash,
    SelectColumns,
    QuickFilter,
    Pagination,
    MAX_RANGE_MONTH_DATE,
  },
  mixins: [VueFilters, VueMethods],
})
export default class DocumentosTable extends Vue {
  @Getter("getClienteSelected") getClienteSelected!: ICliente;
  @Action("fetchDocumentacion")
  fetchDocumentos!: (params?: IDocumentacionParams) => Promise<IDocumentacionResponse | undefined>;
  @Action("saveColumna") saveColumna!: (
    params: ISoConfColumnasUpdateDTO
  ) => Promise<IColumnaResponse | undefined>;

  @Getter("getDelegacionesCliente") delegations!: IDelegacion[];

  @Prop({ required: false, default: {} })
  fieldsI!: IField[];

  @Prop({ required: true, default: {} })
  params!: IDocumentacionParams;

  public maxRangeMonthDate = MAX_RANGE_MONTH_DATE;

  public filtersDocumentos: any = {
    fechaInicio: this.params.fechaInicio
      ? this.getStartDate(this.params.fechaInicio)
      : this.getStartDate(new Date().toISOString()),
    fechaFin: this.params.fechaFin
      ? this.getEndDate(this.params.fechaFin)
      : this.getEndDate(new Date().toISOString()),
    delegationsSelected: [],
    tipoDocumentoSelected: this.params.tipoDocumento ?? null,
  };

  public mostrarFiltros = false;
  public documentacionResponse?: IDocumentacionResponse | null = null;
  public codTable = "documentos";
  public sortDesc = this.params.direction === EDirection.DESC;

  async loadInfo(): Promise<void> {
    const delegacionsSelected = this.delegations?.filter((d) =>
      this.params.delegaciones?.find((code) => code === d.codDelegacionSol)
    );

    this.params.delegaciones = delegacionsSelected.map((d) => d.codDelegacionSol);

    this.filtersDocumentos.delegationsSelected = delegacionsSelected.map((d) => d.id);

    await this.updateTiposDocumentos();
    await this.getDocumentacion();
  }

  async onChangeQuickFilter(): Promise<void> {
    await this.loadInfo();
  }

  get items(): IDocumento[] | undefined {
    return this.documentacionResponse?.listado;
  }

  public get visibleFields(): IField[] {
    return this.fieldsI.filter((item) => item.visible);
  }

  get fields(): IField[] {
    return JSON.parse(JSON.stringify(this.fieldsI));
  }

  set fields(fields: IField[]) {
    this.$emit("update:fieldsI", fields);
  }

  get dameTextoBotonFiltros(): string {
    let numFiltros = 0;
    Object.keys(this.filtersDocumentos).forEach((key: string) => {
      if (this.filtersDocumentos[key] != "" && this.filtersDocumentos[key] != null) {
        numFiltros = numFiltros + 1;
      }
    });

    return numFiltros
      ? `(${numFiltros}) ${this.$t("label.filtros")}`
      : this.$t("label.filtros").toString();
  }

  tiposDocumentos: IParametro[] | undefined = [];

  async updateTiposDocumentos(): Promise<void> {
    this.tiposDocumentos = await this.$services.documentacion.fetchTiposDocumentosByPais(
      this.getClienteSelected?.pais?.id
    );
    this.params.tipoDocumento = this.tiposDocumentos?.find(
      (tipoDoc) => tipoDoc.codParametro == this.params.tipoDocumento
    )?.codParametro;

    this.filtersDocumentos.tipoDocumentoSelected = this.params.tipoDocumento ?? null;
  }

  onChangeCurrentPage(currentPage: number): void {
    this.params.page = currentPage;
    this.getDocumentacion();
  }

  onSortingChange(sort: { sortBy: string; sortDesc: boolean }): void {
    this.$nextTick(async () => {
      this.params.direction = sort.sortDesc ? EDirection.DESC : EDirection.ASC;
      await this.getDocumentacion();
      await this.saveColumnsConfig();
    });
  }

  async saveColumnsConfig(): Promise<void> {
    const params: ISoConfColumnasUpdateDTO = {
      codColumna: this.visibleFields
        .filter((f) => !f.aux)
        .map((f) => f.code)
        .toString(),
      codTabla: this.codTable,
      codTipoOrden: this.params.direction,
      numPagElements: this.params.size,
      codOrden: this.params.orderBy,
    };

    await this.saveColumna(params);
  }

  async getDocumentacion(): Promise<void> {
    this.$nextTick(() => {
      this.$router
        .replace({ path: this.$route.path, query: { ...this.params } as Dictionary<any> })
        .catch(() => {
          // Do nothing
        });
    });

    this.params.codCliente = this.getClienteSelected?.codCliente;

    this.documentacionResponse = await this.fetchDocumentos(this.params);
  }

  async download(doc: IDocumento): Promise<void> {
    const response = await this.$services.documentacion.downloadFile(doc);

    if (response) {
      this.downloadFile(response);
    } else {
      this.makeToast("danger", this.$t("label.documentation.download.error").toString());
    }
  }

  async downloadDocumentosTodos(): Promise<void> {
    if (this.items != undefined && this.items.length != 0) {
      const response = await this.$services.documentacion.downloadAllFiles(this.params);
      if (response) {
        this.downloadFile(response);
      } else {
        this.makeToast("danger", this.$t("label.documentation.download.error").toString());
      }
    }
  }

  action(action: { action: string; data?: any }): void {
    if (action.action === "download") {
      alert("download");
    } else if (action.action === "filtrar" || action.action === "clean") {
      this.params.page = 1;
      this.params.fechaInicio = format(new Date(this.filtersDocumentos.fechaInicio), "yyyy-MM-dd");
      this.params.fechaFin = format(new Date(this.filtersDocumentos.fechaFin), "yyyy-MM-dd");
      this.params.delegaciones =
        this.filtersDocumentos.delegationsSelected.map(
          (pk: number) => this.delegations.find((d) => d.id === pk)?.codDelegacionSol
        ) ?? undefined;
      this.params.tipoDocumento = this.filtersDocumentos.tipoDocumentoSelected ?? undefined;

      this.mostrarFiltros = false;

      this.getDocumentacion();
    }
  }
}
