import Vue from "vue";
import { abilitiesPlugin, Can } from "@casl/vue";
import { buildAbilityFor } from "@/services";
import { IUsuario } from "@/entities";

const ability = buildAbilityFor({} as IUsuario);

Vue.use(abilitiesPlugin, ability);

Vue.component("Can", Can);
