
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "icon-add",
  components: {},
})
export class IconGreaterThan extends Vue {
  @Prop({ default: 26 }) size!: number;
  @Prop({ default: "#ffffff" }) color!: string;

  constructor() {
    super();
  }
}
export default IconGreaterThan;
