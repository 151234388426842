
import { Component, Vue } from "vue-property-decorator";
import TransferenciasPropias from "./propias/TransferenciasPropias.vue";
import TransferenciasPublicas from "./publicas/TransferenciasPublicas.vue";
import { Action, Getter } from "vuex-class";
import { IColumnaResponse, IDemandaParams, IOfertaParams } from "@/services";
import OfertarForm from "./common/OfertarForm.vue";
import DemandarForm from "./common/DemandarForm.vue";
import { EventBus } from "@/event-bus";
import { IDemanda, IOferta, IPais } from "@/entities";
import ImportarForm from "@/pages/transferencias/common/ImportarForm.vue";
import VueMethods from "@/vue-methods";

@Component({
  mixins: [VueMethods],
  components: {
    ImportarForm,
    TransferenciasPropias,
    TransferenciasPublicas,
    OfertarForm,
    DemandarForm,
  },
})
export class TransferenciasPage extends Vue {
  @Action("saveDemanda") saveDemanda!: (params?: IDemandaParams) => Promise<IDemanda | undefined>;

  @Action("fetchColumna") fetchColumna!: (
    codTabla: string
  ) => Promise<IColumnaResponse | undefined>;
  @Action("fetchTiposCoste") fetchTiposCoste!: () => Promise<void>;
  @Action("saveOferta") saveOferta!: (params?: IOfertaParams) => Promise<IOferta | undefined>;
  @Getter("getPaises") getPaises!: IPais[];

  verModal = false;
  titleModal = "";
  verOfertarForm = false;
  verDemandarForm = false;
  verImportarForm = false;
  optionsPaises: IPais[] = [];
  textActionName = this.$t("label.importar");
  actions = [
    { action: "ofertar", label: this.$t("label.ofertar") as string },
    { action: "demandar", label: this.$t("label.demandar") as string },
  ];

  public propiasOfertasConfig: IColumnaResponse | null = null;
  public propiasDemandasConfig: IColumnaResponse | null = null;
  public publicasOfertasConfig: IColumnaResponse | null = null;
  public publicasDemandasConfig: IColumnaResponse | null = null;

  async created(): Promise<void> {
    const responses = await Promise.all([
      this.fetchColumna("propiasOfertas"),
      this.fetchColumna("propiasDemandas"),
      this.fetchColumna("publicasOfertas"),
      this.fetchColumna("publicasDemandas"),
      this.fetchTiposCoste(),
    ]);

    this.propiasOfertasConfig = responses[0] || {
      codTabla: "propiasOfertas",
      codColumna: "",
    };
    this.propiasDemandasConfig = responses[1] || {
      codTabla: "propiasDemandas",
      codColumna: "",
    };
    this.publicasOfertasConfig = responses[2] || {
      codTabla: "publicasOfertas",
      codColumna: "",
    };
    this.publicasDemandasConfig = responses[3] || {
      codTabla: "publicasDemandas",
      codColumna: "",
    };
  }
  optionSelected = "propias";
  constructor() {
    super();
  }
  mounted(): void {
    this.optionSelected = this.$route.params.option;
    this.optionsPaises = this.getPaises.filter((pais) => pais.importCheck);
    if (this.optionsPaises.length) {
      this.actions.push({ action: "importar", label: this.$t("label.importar") as string });
    }
  }

  get options(): any {
    return [
      {
        text: this.$t("label.propias") + "",
        value: "propias",
      },
      {
        text: this.$t("label.publicas"),
        value: "publicas",
      },
    ];
  }

  changeOptionBarNew(option: string): void {
    this.$router.replace({ path: `/transferencias/${option}` });
  }
  actionModal(action: string): void {
    if (action == "ofertar") {
      this.verModal = true;
      this.titleModal = this.$t("label.ofertar").toString();
      this.verImportarForm = false;
      this.verDemandarForm = false;
      this.verOfertarForm = true;
    } else if (action == "demandar") {
      this.verModal = true;
      this.titleModal = this.$t("label.demandar").toString();
      this.verImportarForm = false;
      this.verOfertarForm = false;
      this.verDemandarForm = true;
    } else if (action == "importar") {
      this.verModal = true;
      this.titleModal = this.$t("label.importar").toString();
      this.verOfertarForm = false;
      this.verDemandarForm = false;
      this.verImportarForm = true;
    }
  }
  reseteador = true; //Es para recargar datos despues de crear ofertas o demandas siempre tiene que terminar a true
  async action(action: { action: string; data?: any }): Promise<void> {
    if (action.action == "ofertarConfirm") {
      this.reseteador = false;
      const res = await this.saveOferta(action.data);

      if (res) {
        this.verModal = false;
        EventBus.$emit("p-toast", {
          variant: "success",
          message: this.$t("label.oferta.guardada").toString(),
        });
      } else {
        EventBus.$emit("p-toast", { variant: "danger", message: res });
      }
      this.reseteador = true;
    } else if (action.action == "demandarConfirm") {
      this.reseteador = false;
      const res = await this.saveDemanda(action.data);

      if (res) {
        this.verModal = false;
        EventBus.$emit("p-toast", {
          variant: "success",
          message: this.$t("label.demanda.guardada").toString(),
        });
      } else {
        EventBus.$emit("p-toast", { variant: "danger", message: res });
      }
      this.reseteador = true;
    } else if (action.action == "importConfirm") {
      this.reseteador = false;
      const res = await this.$services.transferencias.importTransferencias(action.data);
      if (res) {
        this.downloadFile(res);
        this.verModal = false;
      }
      this.reseteador = true;
    }
  }
}
export default TransferenciasPage;
