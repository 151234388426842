
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "icon-coins",
  components: {},
})
export class IconCoins extends Vue {
  @Prop({ default: 26 }) public size!: number;
  @Prop({ default: "#ffffff" }) public color!: string;

  constructor() {
    super();
  }
}
export default IconCoins;
