
import { Component, Vue } from "vue-property-decorator";
import Conteos from "@/components/common/widgets/Conteos.vue";
import SaldosPorDivisa from "@/components/common/widgets/SaldosPorDivisa.vue";
import OfertasDemandas from "@/components/common/widgets/OfertasDemandas.vue";
import DenominacionesConExcedentes from "@/components/common/widgets/DenominacionesConExcedentes.vue";
import SaldosActualesPorDelegacion from "@/components/common/widgets/saldosActualesPorDelegacion.vue";

@Component({
  name: "panel-widgets",
  components: {
    SaldosActualesPorDelegacion,
    DenominacionesConExcedentes,
    OfertasDemandas,
    SaldosPorDivisa,
    Conteos,
  },
})
export default class PanelWidgets extends Vue {
  public visible = false;

  constructor() {
    super();
  }
}
