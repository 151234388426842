import { IDocumentacionServiceMock } from "@/services/saldosonline/documentacion/documentacion.types";

export const mockDocumentacionService = (): IDocumentacionServiceMock => ({
  _fetch: jest.fn(),
  _fetchImportacion: jest.fn(),
  fetchDocFajos: jest.fn(),
  fetchTiposDocumentos: jest.fn(),
  fetchTiposDocumentosByPais: jest.fn(),
  downloadFile: jest.fn(),
  downloadAllFiles: jest.fn(),
  downloadFileNonHistorical: jest.fn(),
  downloadFileOfImportation: jest.fn(),
  downloadByDelegation: jest.fn(),
  remove: jest.fn(),
  removeNonHistorical: jest.fn(),
  removeImported: jest.fn(),
  approveImported: jest.fn(),
  saveDocumentacionImportacion: jest.fn(),
  fetchDocumentos: jest.fn(),
});
