
import { Component, Prop, Vue } from "vue-property-decorator";
import { IPais } from "@/entities";
import Multiselect from "vue-multiselect";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import VueMethods from "@/vue-methods";
import { ITansferenciasParamsImport } from "@/services";
import { Getter } from "vuex-class";
import { Locale } from "@/store";

@Component({
  name: "importar-form",
  mixins: [VueMethods],
  components: {
    Multiselect,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class ImportarForm extends Vue {
  @Prop({ required: true }) optionsPaises!: IPais[];
  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;
  @Getter("getLocale") locale!: Locale;

  validado = false;
  params: ITansferenciasParamsImport = {
    id: 0,
    fichero: "",
  };

  $refs!: {
    formImportar: InstanceType<typeof ValidationObserver>;
  };

  async mounted(): Promise<void> {
    this.params.id = this.optionsPaises[0].id;
    this.params.idioma = this.locale;
  }

  async action(action: string): Promise<void> {
    if (action == "download") {
      this.download();
    } else if (action == "importar") {
      this.importFile();
    }
  }

  async download(): Promise<void> {
    this.downloadFile(await this.$services.transferencias._downloadPlantilla(this.params.id));
  }

  async importFile(): Promise<void> {
    this.$emit("action", { action: "importConfirm", data: this.params });
  }

  getBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  async fileToString(): Promise<void> {
    await this.getBase64(this.params.file!).then((videoString) => {
      this.params.fichero = videoString;
    });
  }

  cancel(): void {
    this.$emit("cancel");
  }
}
