import { render, staticRenderFns } from "./ATMsPage.vue?vue&type=template&id=3b41d750&scoped=true&"
import script from "./ATMsPage.vue?vue&type=script&lang=ts&"
export * from "./ATMsPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b41d750",
  null
  
)

export default component.exports