
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Conteos extends Vue {
  @Prop({ required: false, default: "CONTEOS CON DIFERENCIAS" })
  title!: string;

  @Prop({
    required: false,
    default: () => [
      {
        label: "Delegación: Bahía Blanca",
        date: "01/06/20",
        hour: "08:35:00",
        rows: ["Declarado: € 100.000,00", "Diferencia: € 470,00"],
      },
      {
        label: "Delegación: Bahía Blanca",
        date: "01/06/20",
        hour: "08:35:00",
        rows: ["Declarado: € 100.000,00", "Diferencia: € 470,00"],
      },
      {
        label: "Delegación: Bahía Blanca",
        date: "01/06/20",
        hour: "08:35:00",
        rows: ["Declarado: € 100.000,00", "Diferencia: € 470,00"],
      },
      {
        label: "Delegación: Bahía Blanca",
        date: "01/06/20",
        hour: "08:35:00",
        rows: ["Declarado: € 100.000,00", "Diferencia: € 470,00"],
      },
      {
        label: "Delegación: Bahía Blanca",
        date: "01/06/20",
        hour: "08:35:00",
        rows: ["Declarado: € 100.000,00", "Diferencia: € 470,00"],
      },
      {
        label: "Delegación: Bahía Blanca",
        date: "01/06/20",
        hour: "08:35:00",
        rows: ["Declarado: € 100.000,00", "Diferencia: € 470,00"],
      },
      {
        label: "Delegación: Bahía Blanca",
        date: "01/06/20",
        hour: "08:35:00",
        rows: ["Declarado: € 100.000,00", "Diferencia: € 470,00"],
      },
      {
        label: "Delegación: Bahía Blanca",
        date: "01/06/20",
        hour: "08:35:00",
        rows: ["Declarado: € 100.000,00", "Diferencia: € 470,00"],
      },
    ],
  })
  data:
    | [
        {
          label: string;
          date: string;
          hour: string;
          rows: string[];
        }
      ]
    | undefined;
}
