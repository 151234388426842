
import { Component, Vue } from "vue-property-decorator";
import NotificacionesRecientes from "./components/NotificacionesRecientes.vue";
import NotificacionesHistoricas from "./components/NotificacionesHistoricas.vue";

@Component({
  components: {
    NotificacionesRecientes,
    NotificacionesHistoricas,
  },
})
export class NotificacionesPage extends Vue {
  optionSelected = "recientes";
  mounted(): void {
    this.optionSelected = this.$route.params.option;
  }
  get options(): any {
    return [
      {
        text: this.$t("label.reciente") + "",
        value: "recientes",
      },
      {
        text: this.$t("label.historicos"),
        value: "historicos",
      },
    ];
  }
}
export default NotificacionesPage;
