
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { IConfColumnaPais, IPais, ISoConfConlumnasPaisUpdate } from "@/entities";
import { Action } from "vuex-class";
import Pagination from "@/components/common/Pagination.vue";
import VueFilters from "@/vue-filters";

import SelectColumns from "@/components/common/SelectColumns/SelectColumns.vue";
import { IColumnaResponse } from "@/services";
import PFormSwitch from "@/components/look/PFormCheckBox/PFormSwitch.vue";

@Component({
  components: { PFormSwitch, SelectColumns, Pagination },
  mixins: [VueFilters],
})
export default class ColumnasTable extends Vue {
  @Prop({ required: true, default: null }) config!: IConfColumnaPais[];
  @Watch("config", { deep: true })
  onChangeParams(): void {
    this.columnasVisibles = [];

    const columnas: IConfColumnaPais[] = JSON.parse(JSON.stringify(this.config));
    if (columnas.length) {
      columnas.forEach((col: IConfColumnaPais) => {
        if (col.visible) {
          this.columnasVisibles.push(col.codColumna);
        }
      });
    }
  }
  @Prop({ required: false, default: null }) country!: IPais;
  @Prop({ required: false, default: null }) table!: string;

  @Action("saveColumnaConfPais") saveColumnaConfPais!: (
    params: ISoConfConlumnasPaisUpdate
  ) => Promise<IColumnaResponse | undefined>;

  private columnasVisibles: string[] = [];

  private items(): { key: string; label?: string; active: boolean }[] {
    const items: { key: string; label?: string; active: boolean }[] = [];

    if (this.config) {
      this.config.forEach((columna: IConfColumnaPais) => {
        items.push({
          key: columna.codColumna,
          label: this.$t("label." + columna.codColumna).toString(),
          active: columna.visible,
        });
      });
    }
    return items;
  }

  async updateColumn(item: { key: string; label: string; active: string }): Promise<void> {
    let index = this.columnasVisibles.indexOf(item.key);
    if (index >= 0) {
      this.columnasVisibles.splice(index, 1);
    } else {
      this.columnasVisibles.push(item.key);
    }
    const rest = await this.saveColumnaConfPais({
      codPaisCorto: this.country?.codPaisCorto,
      codTabla: this.table,
      codColumna: this.columnasVisibles.join(","),
    });
    if (rest) {
      this.$emit("update");
    }
  }
}
