
import { Component, Prop, Vue } from "vue-property-decorator";
import { ICalidadDenominacion, ICliente, IDelegacion, IDetallesSaldo, IDivisa } from "@/entities";
import { IParametro } from "@/entities/centros-efectivo-backend/parametro.types";
import { IOfertaPaso4Params } from "@/services";
import VueFilters from "@/vue-filters";

@Component({
  name: "resumen-oferta",
  mixins: [VueFilters],
})
export default class ResumenOferta extends Vue {
  @Prop({ required: false, default: null }) cliente!: ICliente;
  @Prop({ required: false, default: null }) delegacion!: IDelegacion;
  @Prop({ required: false, default: null }) divisa!: IDivisa;
  @Prop({ required: false, default: null }) detalleSaldo!: IDetallesSaldo[];
  @Prop({ required: false, default: null }) calidadesDenominacion!: ICalidadDenominacion[];
  @Prop({ required: false, default: null }) saldoRestante!: number;
  @Prop({ required: false, default: null }) saldoOfertar!: number;
  @Prop({ required: false, default: null }) fechaExpiracion!: string;
  @Prop({ required: false, default: null }) horaExpiracion!: string;
  @Prop({ required: false, default: null }) coste!: IParametro;
  @Prop({ required: false, default: null }) anonimo!: boolean;
  @Prop({ required: false, default: null }) tasa!: number;
  @Prop({ required: false, default: null }) ofertaPaso4Params!: IOfertaPaso4Params;
  @Prop({ required: false, default: false }) isShowBankField!: boolean;
  @Prop({ required: false, default: null }) final!: boolean;

  constructor() {
    super();
  }
}
