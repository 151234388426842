
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "icon-dashboard",
  components: {},
})
export default class IconDashboard extends Vue {
  @Prop() public width!: number;
  @Prop() public height!: number;
  @Prop() public color!: string;

  constructor() {
    super();
  }
}
