
import { Component, Prop, Vue } from "vue-property-decorator";

import VueApexCharts from "vue-apexcharts";
import RadialBar from "@/components/common/widgets/RadialBar.vue";
import Pie from "@/components/common/widgets/Pie.vue";
import D3pie from "@/components/common/widgets/PieD3.vue";
import { EventBus } from "@/event-bus";

Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
@Component({
  components: { D3pie, Pie, RadialBar },
})
export default class DenominacionesConExcedentes extends Vue {
  @Prop({ required: false, default: "denominaciones con excedentes" })
  title!: string;
  optionA: { selected: string; options: string[] } = {
    selected: "BILLETES",
    options: ["BILLETES", "MONEDAS"],
  };

  data = [
    { name: "P100", value: 8123674.87 },
    { name: "P500", value: 10536298.39 },
    { name: "P200", value: 4630000.24 },
    { name: "P20", value: 5839665.03 },
    { name: "P50", value: 6746453.84 },
  ];

  setOptionA(item: string): void {
    this.optionA.selected = item;
    if (item == "MONEDAS") {
      this.data = [
        { name: "M0.01", value: 8123674.87 },
        { name: "M0.02", value: 10536298.39 },
        { name: "M0.05", value: 4630000.24 },
        { name: "M0.1", value: 5839665.03 },
        { name: "M0.2", value: 6746453.84 },
        { name: "M0.3", value: 6746453.84 },
        { name: "M0.5", value: 6746453.84 },
        { name: "M1", value: 6746453.84 },
        { name: "M2", value: 6746453.84 },
      ];
    } else {
      this.data = [
        { name: "P100", value: 8123674.87 },
        { name: "P500", value: 10536298.39 },
        { name: "P200", value: 4630000.24 },
        { name: "P20", value: 5839665.03 },
        { name: "P50", value: 6746453.84 },
      ];
    }
    EventBus.$emit("update-chart", this.data);
  }
}
