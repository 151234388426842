
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "top-navbar",
})
export default class TopNavbar extends Vue {
  constructor() {
    super();
  }
}
