import format from "date-fns/format";
import { enUS, es } from "date-fns/locale";
import { Component, Vue } from "vue-property-decorator";
import { i18n } from "./i18n";
import { IPais, IUsuario } from "@/entities";
import { Getter } from "vuex-class";

declare module "vue/types/vue" {
  interface Vue {
    filterDate(dateStr: string): string;
    filterTime(dateStr: string): string;
    filterDateTime(dateStr: string): string;
    filterNumber(amount: number, decimals: number): string;
  }
}

@Component({
  name: "vue-filters",
})
export default class VueFilters extends Vue {
  @Getter("getLoggedUser") getLoggedUser!: IUsuario;
  public filterDate(dateStr: string): string {
    let locale = enUS;

    if (i18n.locale === "es") {
      locale = es;
    }
    const dateformat = this.getDateFormat(this.getLoggedUser.pais, false);

    const date = new Date(dateStr);

    if (
      !date.getUTCHours() &&
      !date.getUTCMinutes() &&
      !date.getUTCSeconds() &&
      !date.getUTCMilliseconds()
    ) {
      //Ajustamos para que en todos los paises salga la fecha correcta sin la hora
      date.setTime(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
    }

    return dateStr ? format(date, dateformat, { locale }) : "";
  }

  private getDateFormat(pais: IPais | undefined, enableHour: boolean): string {
    const dateByDefault = "dd/MM/yyyy";
    if (!pais) {
      return dateByDefault;
    }
    if (!pais.dateFormat) {
      return dateByDefault;
    }
    return !enableHour ? pais.dateFormat : pais.dateFormat.concat(" ").concat("HH:mm");
  }

  public filterTime(dateStr: string): string {
    let locale = enUS;

    if (i18n.locale === "es") {
      locale = es;
    }

    return dateStr ? format(new Date(dateStr), "HH:mm", { locale }) : "";
  }

  public filterDateTime(dateStr: string): string {
    let locale = enUS;

    if (i18n.locale === "es") {
      locale = es;
    }
    const dateformat = this.getDateFormat(this.getLoggedUser.pais, true);

    return dateStr ? format(new Date(dateStr), dateformat, { locale }) : "";
  }

  public filterNumber(amount: number, decimals: number): string {
    return Number(amount)
      .toFixed(decimals)
      .replace(".", ",")
      .replace(/\d(?=(\d{3})+,)/g, "$&.")
      .toLocaleString();
  }
}
