import { IPaisParams, IPaisResponse, IPaisServiceMock } from "@/services";

export const mockPaisService = (): IPaisServiceMock => ({
  _fetch: jest.fn(),
  _fetchDelegaciones: jest.fn(),
  getConfig: jest.fn(),
});

export const mockPaisParams = (): IPaisParams => <IPaisParams>{};
export const mockPaisResponse = (): IPaisResponse =>
  <IPaisResponse>{
    content: [
      {
        codPais: "ARG",
        codPaisCorto: "AR",
        codTimezone: "America/Argentina/Buenos_Aires",
        descripcion: "Argentina",
        id: 1,
        hasTasa: false,
        soConfig: {},
        dateFormat: "",
      },
      {
        codPais: "ESP",
        codPaisCorto: "ES",
        codTimezone: "Europa/Madrid",
        descripcion: "España",
        id: 2,
        hasTasa: false,
        soConfig: {},
        dateFormat: "",
      },
    ],
  };
