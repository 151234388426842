import { II18n, II18nServiceMock } from "./i18n.types";

export const mockI18nService = (): II18nServiceMock => ({
  getI18n: jest.fn(),
});

export const mockII18nData = (): II18n => ({
  en: {
    "column.code": "Code",
    "column.description": "Description",
  },
  es: {
    "label.code": "Código",
    "label.description": "Descripción",
  },
});
