
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { ICliente, IClienteAll, IDivisa } from "@/entities";
import { IVideoFilter } from "@/services";
import { todosCliente } from "@/constans";

@Component({
  name: "quick-filter",
})
export default class QuickFilter extends Vue {
  @Prop() public params!: IVideoFilter;
  @Action("setClienteSelected") setClienteSelected!: (cliente?: ICliente) => Promise<void>;

  @Getter("getClientesAll") getClientesAll!: IClienteAll[];
  @Getter("getClienteSelected") getClienteSelected!: ICliente;
  @Getter("getClienteXDivisa") getClienteXDivisa!: IDivisa[];

  private admin = false;
  private optionTodos = todosCliente;

  get clienteSelected(): number {
    let cliente = this.getClienteSelected;
    const clienteId = cliente?.id ?? this.params.pkCliente ?? this.optionTodos.id!;
    return this.getCliente(clienteId)?.id ?? this.optionTodos.id!;
  }

  set clienteSelected(clienteId: number) {
    if (clienteId && clienteId !== this.optionTodos.id) {
      this.setClienteSelected(this.getCliente(clienteId)).then(() => {
        return this.onChangeCliente(this.clienteSelected);
      });
    } else {
      this.setClienteSelected().then(() => {
        this.onChangeCliente();
      });
    }
  }

  getCliente(clienteId: number): ICliente {
    return this.clientes.find((cliente) => cliente.id === clienteId) as ICliente;
  }

  get clientes(): ICliente[] {
    const c = Object.assign([], this.getClientesAll);
    if (this.admin) {
      c.unshift(this.optionTodos);
    }
    return c;
  }

  public async mounted(): Promise<void> {
    this.admin = this.getClientesAll.length > 1;

    if (!this.getClienteSelected) {
      const idCliente = this.params.pkCliente ?? -1;
      const cliente = this.clientes.find((cliente) => cliente.id === idCliente);
      if (cliente && cliente.id !== -1) {
        await this.setClienteSelected(cliente);
      } else {
        await this.setClienteSelected();
      }
    }
  }

  async onChangeCliente(clienteId?: number): Promise<void> {
    if (clienteId && clienteId !== -1) {
      this.params.pkCliente = clienteId;
    } else {
      this.$emit("resetClient", true);
      delete this.params["pkCliente"];
    }

    this.$emit("action", { action: "quickFilter_client", data: null });
  }
}
