import { APPLICATION_JSON } from "@/constans";
import { BACKEND_BASE_URL, IColumnaResponse, IColumnaService } from "@/services";
import { handleResponse } from "@/services/handlers";
import Vue from "vue";
import { EventBus } from "@/event-bus";
import { IResponseError } from "@/entities/common/common.types";
import { IConfColumnaPais, ISoConfColumnasUpdateDTO, ISoConfConlumnasPaisUpdate } from "@/entities";

export class ColumnaService implements IColumnaService {
  async _fetch(codTabla: string): Promise<IColumnaResponse | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IColumnaResponse, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/columna/usuario?codTabla=` + codTabla, { headers })
    );

    if (error) {
      EventBus.$emit("on-service-columna-configuration-clientes-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _save(
    soConfColumnasUpdateDTO: ISoConfColumnasUpdateDTO
  ): Promise<IColumnaResponse | undefined> {
    const config = {
      headers: {
        Accept: APPLICATION_JSON,
      },
    };
    const { response, error } = await handleResponse<IColumnaResponse, IResponseError>(
      Vue.$axiosSilent.post(
        `${BACKEND_BASE_URL}/api/columna/usuario`,
        soConfColumnasUpdateDTO,
        config
      )
    );
    if (error) {
      EventBus.$emit("on-service-columna-configuration-clientes-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _fetchConfColumnasPais(
    codPaisCorto: string,
    codTabla: string | null
  ): Promise<IConfColumnaPais[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IConfColumnaPais[], IResponseError>(
      Vue.$axios.get(
        `${BACKEND_BASE_URL}/api/columna/pais?codPais=` + codPaisCorto + `&codTabla=` + codTabla,
        { headers }
      )
    );
    if (error) {
      EventBus.$emit("on-service-saldosonline-columnas-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _saveConfColumnasPais(
    soConfColumnasUpdateDTO: ISoConfConlumnasPaisUpdate
  ): Promise<IColumnaResponse | undefined> {
    const config = {
      headers: {
        Accept: APPLICATION_JSON,
      },
    };
    const { response, error } = await handleResponse<IColumnaResponse, IResponseError>(
      Vue.$axios.post(`${BACKEND_BASE_URL}/api/columna/pais`, soConfColumnasUpdateDTO, config)
    );
    if (error) {
      EventBus.$emit("on-service-columna-configuration-pais-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }
}
