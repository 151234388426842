
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { ICliente, IDelegacion, IDivisa, IParametro, KeyValuePair } from "@/entities";
import { ITransferenciasQuickFilter } from "@/pages/transferencias/types";
import { Locale } from "@/store";
import VueMethods from "@/vue-methods";

@Component({
  mixins: [VueMethods],
})
export class TransferenciasPublicasFiltersForm extends Vue {
  @Prop({ required: true }) value!: any;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }
  valueLocal = {
    delegationsSelected: [],
    pkCoste: [],
    denominacionesSelected: [],
  };
  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;
  @Getter("getLocale") locale!: Locale;
  @Getter("getClienteSelected") clienteSelected!: ICliente;
  @Getter("getCostes") getCostes!: IParametro[];
  @Getter("getClienteXDivisa") getClienteXDivisa!: IDivisa[];
  @Getter("getTransferenciasPageQuickFilter")
  getTransferenciasPageQuickFilter!: ITransferenciasQuickFilter;

  validado = false;

  get getDenominaciones(): KeyValuePair[] {
    const divisas = this.getTransferenciasPageQuickFilter.divisa.length
      ? this.getTransferenciasPageQuickFilter.divisa
      : this.getClienteXDivisa;

    const calidades: KeyValuePair[] = [];
    divisas.forEach((divisa) => {
      divisa.denominaciones?.sort((a, b) => this.sortDescending(a, b, "numValue"));
      divisa.denominaciones?.forEach((denominacion) =>
        denominacion.calidades?.forEach((calidad) =>
          calidades.push({
            key: calidad.id.toString(),
            value: `${calidad.denominacion?.codDenominacion} ${calidad.desCalidad}`,
          })
        )
      );
    });
    return calidades;
  }
  initialValueLocal = {
    delegationsSelected: [],
    pkCoste: [],
    denominacionesSelected: [],
  };

  get delegations(): IDelegacion[] {
    let delegationsList: IDelegacion[] = [];
    this.clienteSelected?.clientexdels.forEach((clientexdel) => {
      if (clientexdel.active) delegationsList.push(clientexdel.delegacion);
    });
    return delegationsList.sort((a, b) => this.sortAscending(a, b, "desDelegacion"));
  }

  limpiar(): void {
    this.valueLocal.delegationsSelected = [];
    this.valueLocal.pkCoste = [];
    this.valueLocal.denominacionesSelected = [];
    if (!this.isInitialObject(this.value, this.initialValueLocal)) {
      this.$emit("input", this.valueLocal);
      this.$emit("cancel", { action: "clean" });
    }
  }
  action(action: string): void {
    this.$emit("input", this.valueLocal);
    this.$emit("action", { data: this.valueLocal, action: action });
  }
}
export default TransferenciasPublicasFiltersForm;
