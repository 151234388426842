
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PIcon from "../PIcon/PIcon.vue";
import PButton from "../PButton/PButton.vue";

@Component({
  name: "pagination",
  components: {
    PIcon,
    PButton,
  },
})
export class PPagination extends Vue {
  @Prop({ required: true }) value!: number;
  @Watch("value", { immediate: true })
  onChangeValue(): void {
    this.currentPage = this.value;
  }
  @Prop({ required: true }) pageSize!: number;
  @Prop({ required: true }) totalElements!: number;

  currentPage = 1;
  constructor() {
    super();
  }

  get lastPageNumber(): number {
    return Math.ceil(this.totalElements / this.pageSize);
  }

  get isFirstPageNumber(): boolean {
    return this.currentPage === 1;
  }

  get isLastPageNumber(): boolean {
    return this.currentPage === this.lastPageNumber;
  }

  onClickDirection(factor: number): void {
    if (factor === 0) {
      this.currentPage = 1;
    } else if (factor === this.lastPageNumber) {
      this.currentPage = this.lastPageNumber;
    } else if (factor > 0) {
      this.currentPage++;
    } else if (factor < 0) {
      this.currentPage--;
    }
    this.$emit("input", this.currentPage);
    this.$emit("change", this.currentPage);
  }
}
export default PPagination;
