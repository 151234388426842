
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as d3 from "d3";
import { LineChartData } from "@/entities";
import VueFilters from "@/vue-filters";

@Component({
  name: "line-chart",
  components: { VueFilters },
})
export default class LineChart extends Vue {
  @Prop({ required: true, default: [] })
  public data!: LineChartData[];

  public xy: [number, number][] = [];

  private parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%S.%f%Z");

  @Watch("data")
  onChangeData(): void {
    d3.select("#line-chart").html("");

    if (this.data.length) {
      this.xy = [];

      this.data.forEach((d) => {
        if (d.dateString && this.parseTime(d.dateString)) {
          d.date = this.parseTime(d.dateString)!;
          d.value = +d.value;
          this.xy.push([d.date.getTime(), d.value]);
        }
      });

      if (this.xy.length) {
        if (this.xy.length === 1) {
          this.xy.unshift([this.parseTime("1970-01-01T00:00:00.000+00:00")!.getTime(), 0]);
        }

        this.generateGraph();
      }
    }
  }

  generateGraph(): void {
    const max = d3.max(this.xy, (d) => d[1])! * 2;

    // set the dimensions and margins of the graph. right: 80
    const margin = { top: 0, right: 0, bottom: 0, left: 0 },
      width = 400 - margin.left - margin.right,
      height = 360 - margin.top - margin.bottom;

    // set the ranges
    const x = d3.scaleTime().range([0, width]);
    const y = d3.scaleLinear().range([height, 0]);

    // define the line
    const valueLine = d3
      .line()
      .curve(d3.curveBasis)
      .x((d) => x(d[0]))
      .y((d) => y(d[1]));

    // append the svg object to the body of the page
    // appends a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    const svg = d3
      .select("#line-chart")
      .append("svg")
      .style("overflow", "visible")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    ////////////////////////////////////////////////////////////////////////////////// gradient //////////////
    svg
      .append("linearGradient")
      .attr("id", "fill-gradient")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", 0)
      .attr("y1", height - margin.bottom)
      .attr("x2", 0)
      .attr("y2", margin.top);

    svg
      .append("linearGradient")
      .attr("id", "line-gradient")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", 0)
      .attr("x2", width);

    const fillGradient = d3.select("#fill-gradient");
    fillGradient
      .append("stop")
      .attr("class", "start")
      .attr("offset", "0%")
      .attr("stop-color", "#4c4c4c")
      .attr("stop-opacity", 0.2);
    fillGradient
      .append("stop")
      .attr("class", "end")
      .attr("offset", "50%")
      .attr("stop-color", "#ffd102")
      .attr("stop-opacity", 0.7);

    const lineGradient = d3.select("#line-gradient");
    lineGradient
      .append("stop")
      .attr("class", "start")
      .attr("offset", "0%")
      .attr("stop-color", "#4c4c4c")
      .attr("stop-opacity", 1);
    lineGradient
      .append("stop")
      .attr("class", "end")
      .attr("offset", "100%")
      .attr("stop-color", "#eec106")
      .attr("stop-opacity", 1);

    ////////////////////////////////////////////////////////////////////////////////// gradient //////////////
    // Get the data

    // Scale the range of the data
    x.domain(d3.extent(this.xy, (d) => d[0]) as [number, number]);

    y.domain([0, max]);

    // add the value line path.
    svg
      .append("path")
      .data([this.xy])
      .attr("class", "area")
      .attr(
        "d",
        d3
          .area()
          .x((d) => x(d[0]))
          .curve(d3.curveBasis)
          .y0(y(0))
          .y1((d) => y(d[1]))
      );

    // Create the circle that travels along the curve of chart
    const focus = svg
      .append("g")
      .append("rect")
      .attr("rx", 6)
      .attr("ry", 6)
      .attr("width", 122)
      .attr("height", 170)
      .attr("fill", "#f2f2f2");

    svg
      .attr("class", "rect2")
      .append("g")
      .append("rect")
      .attr("rx", 6)
      .attr("ry", 6)
      .attr("width", 65)
      .attr("height", 170)
      .attr("fill", "#f2f2f2")
      .style("opacity", 0);

    ////////////////////////////////////
    // This allows to find the closest X index of the mouse:
    // let bisect = d3.bisector((d) => d.date).left;

    svg.append("path").data([this.xy]).attr("class", "line").attr("d", valueLine);

    const circle = svg
      .append("circle")
      .attr("class", "circle")
      .attr("r", 20)
      .attr("stroke", "white")
      .attr("stroke-width", 4)
      .attr("fill", "#ffd102");

    const arrows = svg
      .append("text")
      .attr("class", "arrows")
      .attr("font-size", 14)
      .attr("font-weight", "bold")
      .attr("font-family", "roboto")
      .attr("fill", "#ffffff")
      .style("text-anchor", "middle")
      .attr("dy", ".35em")
      .text("< >");

    const saldoActual = svg
      .append("text")
      .attr("class", "saldoActual")
      .attr("font-size", 14)
      .attr("font-weight", "bold")
      .attr("font-family", "roboto")
      .style("text-anchor", "middle")
      .attr("dy", ".35em");
    const total = svg
      .append("text")
      .attr("class", "total")
      .attr("font-size", 12)
      .style("fill", "#4c4c4c")
      .style("text-anchor", "middle")
      .attr("font-family", "roboto")
      .attr("dy", ".35em")
      .text("TOTAL");

    const fecha = svg
      .append("text")
      .attr("class", "fecha")
      .attr("font-size", 13)
      .attr("font-family", "roboto")
      .attr("dy", ".35em")
      .style("fill", "#4c4c4c")
      .style("text-anchor", "middle");

    const circle2 = svg
      .append("circle")
      .attr("class", "circle2")
      .attr("r", 15)
      .attr("stroke", "#ffd102")
      .attr("stroke-width", 4)
      .attr("fill", "#f2f2f2");

    focus.style("display", "none");
    circle.style("display", "none");
    arrows.style("display", "none");
    circle2.style("display", "none");
    saldoActual.style("display", "none");
    total.style("display", "none");
    fecha.style("display", "none");

    svg
      .append("rect")
      .style("fill", "none")
      .style("pointer-events", "all")
      .attr("width", width)
      .attr("height", height)
      .on("mouseover", () => {
        focus.style("display", "block");
        circle.style("display", "block");
        arrows.style("display", "block");
        circle2.style("display", "block");
        saldoActual.style("display", "block");
        total.style("display", "block");
        fecha.style("display", "block");
      })
      .on("mouseout", () => {
        focus.style("display", "none");
        circle.style("display", "none");
        arrows.style("display", "none");
        circle2.style("display", "none");
        saldoActual.style("display", "none");
        total.style("display", "none");
        fecha.style("display", "none");
      })
      .on("mousemove", (event) => {
        // recover coordinate we need
        const xDate = x.invert(d3.pointer(event)[0]);
        const bisect = d3.bisector((d: [number, number]) => d[0]).center;
        const i = bisect(this.xy, xDate, 0);
        const selectedData = this.xy[i];

        focus.attr("x", x(selectedData[0]) - 61).attr("y", y(selectedData[1]) - 85);
        circle.attr("cx", x(selectedData[0])).attr("cy", y(selectedData[1]) - 85);
        arrows.attr("x", x(selectedData[0])).attr("y", y(selectedData[1]) - 85);
        circle2.attr("cx", x(selectedData[0])).attr("cy", y(selectedData[1]));
        saldoActual.text(selectedData[1].toLocaleString("es-ES"));
        saldoActual.attr("x", x(selectedData[0])).attr("y", y(selectedData[1]) + 50);
        total.attr("x", x(selectedData[0])).attr("y", y(selectedData[1]) + 30);
        fecha
          .attr("x", x(selectedData[0]))
          .attr("y", y(selectedData[1]) - 40)
          .text(new Date(selectedData[0]).toLocaleString("es-ES") || "");
      });
    /////////////////////////////////////
  }
}
