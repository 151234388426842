var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('b-row',{staticClass:"mb-3 ml-2"},[_c('span',{staticClass:"title_detalle"},[_vm._v(_vm._s(_vm.title))])]),_c('b-row',[_c('b-col',[(_vm.detalles)?_c('b-table',{ref:"tableDetalleTransferencia",attrs:{"fields":_vm.fields,"items":_vm.detalles,"empty-text":_vm.$t('label.noData'),"hover":"","show-empty":"","small":"","borderless":""},scopedSlots:_vm._u([{key:"cell(actions-left)",fn:function(row){return [_c('div',{staticClass:"table-button-container d-flex"},[_c('b-icon',{staticClass:"icon-details ml-3 my-auto cursor-pointer",attrs:{"id":`info-detalle-${_vm.tipo}-${row.item.id}`,"icon":"exclamation-circle","alt":_vm.$t('label.informacion')}}),_c('b-tooltip',{attrs:{"target":`info-detalle-${_vm.tipo}-${row.item.id}`,"custom-class":"table-cell-tooltip","placement":"bottom"}},[_vm._l((_vm.tipo === 'OFERTA'
                  ? row.item.detallesDemanda
                  : row.item.detallesOferta),function(detalleDemanda,index){return [_c('div',{key:`detalle-${detalleDemanda.id}`},[(index)?_c('hr',{staticClass:"m-0"}):_vm._e(),_c('div',{staticClass:"tooltip-inner-content-diff"},[_c('div',{staticClass:"title-info"},[_vm._v(" "+_vm._s(_vm.$t("label.denominacion"))+": "+_vm._s(detalleDemanda.soCalidadDenominacion.denominacion.codDenominacion)+" "+_vm._s(detalleDemanda.soCalidadDenominacion.desCalidad)+" ")]),(
                        (_vm.tipo === 'OFERTA' && row.item.detallesDemanda.length === 1) ||
                        (_vm.tipo !== 'OFERTA' && row.item.detallesOferta.length === 1)
                      )?_c('hr',{staticClass:"m-0 pt-2"}):_vm._e(),(_vm.tipo == 'OFERTA')?_c('div',[_vm._v(" "+_vm._s(_vm.$t("label.demandado"))+": "+_vm._s(_vm.filterNumber(detalleDemanda.cantidadDisponible, 2))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("label.ofertado"))+": "+_vm._s(_vm.filterNumber(detalleDemanda.cantidadDisponible, 2))+" ")])])])]})],2),_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.d300",value:(
                _vm.tipo === 'OFERTA' ? _vm.$t('label.transferir') : _vm.$t('label.demandar')
              ),expression:"\n                tipo === 'OFERTA' ? $t('label.transferir') : $t('label.demandar')\n              ",modifiers:{"hover":true,"d300":true}}],staticClass:"icon-details ml-3 my-auto cursor-pointer",attrs:{"id":`transferir-${_vm.tipo}-${row.index}`,"icon":"arrow-left-right","alt":_vm.tipo === 'OFERTA' ? _vm.$t('label.transferir') : _vm.$t('label.demandar')},on:{"click":function($event){_vm.tipo === 'OFERTA' ? _vm.relacionarDemanda(row.item) : _vm.relacionarOferta(row.item)}}})],1)]}},{key:"cell(coste)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.$t(`label.coste.${row.value.codParametro}`))+" ")]}},{key:"cell(checkParcial)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.$t(`label.${row.value ? "yes" : "no"}`))+" ")]}}],null,false,3383114339)}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }