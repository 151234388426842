
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { ICliente, IDelegacion } from "@/entities";
import { Locale } from "@/store";
import VueMethods from "@/vue-methods";

@Component({
  mixins: [VueMethods],
})
export default class DocumentacionImportacionTableFilters extends Vue {
  @Prop({ required: false })
  textActionName!: string;
  @Prop({ required: false })
  actionName!: string;
  @Prop({ required: false })
  maxRangeMonth?: number;

  @Getter("getLocale") locale!: Locale;
  @Getter("getClienteSelected") clienteSelected!: ICliente;

  @Prop({ required: true }) value!: any;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.valueLocal = JSON.parse(JSON.stringify(this.value));
    this.$set(
      this.valueLocal,
      "fechaArchivoInicio",
      this.valueLocal.fechaArchivoInicio ? new Date(this.valueLocal.fechaArchivoInicio!) : null
    );
    this.$set(
      this.valueLocal,
      "fechaArchivoFin",
      this.valueLocal.fechaArchivoFin ? new Date(this.valueLocal.fechaArchivoFin!) : null
    );
    this.$set(
      this.valueLocal,
      "fechaSubidaInicio",
      this.valueLocal.fechaSubidaInicio ? new Date(this.valueLocal.fechaSubidaInicio!) : null
    );
    this.$set(
      this.valueLocal,
      "fechaSubidaFin",
      this.valueLocal.fechaSubidaFin ? new Date(this.valueLocal.fechaSubidaFin!) : null
    );
  }
  validado = false;
  valueLocal = {
    pais: null,
    documentTitle: null,
    documentType: null,
    delegationsSelected: [],
    fechaArchivoInicio: null,
    fechaArchivoFin: null,
    fechaSubidaInicio: null,
    fechaSubidaFin: null,
    usuario: null,
    estado: null,
    aprobadoPor: null,
  };

  initialValueLocal = {
    pais: null,
    documentTitle: null,
    documentType: null,
    delegationsSelected: [],
    fechaArchivoInicio: null,
    fechaArchivoFin: null,
    fechaSubidaInicio: null,
    fechaSubidaFin: null,
    usuario: null,
    estado: null,
    aprobadoPor: null,
  };

  get delegations(): IDelegacion[] {
    let delegationsList: IDelegacion[] = [];
    this.clienteSelected?.clientexdels.forEach((clientexdel) => {
      if (clientexdel.active) delegationsList.push(clientexdel.delegacion);
    });
    return delegationsList.sort((a, b) => this.sortAscending(a, b, "desDelegacion"));
  }

  limpiar(): void {
    this.valueLocal.fechaArchivoInicio = null;
    this.valueLocal.fechaArchivoFin = null;
    this.valueLocal.fechaSubidaInicio = null;
    this.valueLocal.fechaSubidaFin = null;
    this.valueLocal.delegationsSelected = [];
    this.valueLocal.documentType = null;
    this.valueLocal.pais = null;
    this.valueLocal.usuario = null;
    this.valueLocal.documentTitle = null;
    this.valueLocal.estado = null;
    this.valueLocal.aprobadoPor = null;
    if (!this.isInitialObject(this.value, this.initialValueLocal)) {
      this.$emit("input", this.valueLocal);
      this.$emit("cancel", { action: "clean" });
    }
  }
  action(action: string): void {
    this.$emit("input", this.valueLocal);
    this.$emit("action", { action: action });
  }
  get estados(): any[] {
    return [
      { codEstado: "PENDIENTE", descriptionEstado: this.$t("label.estado.documento.pendiente") },
      { codEstado: "PROCESADO", descriptionEstado: this.$t("label.estado.documento.procesado") },
      { codEstado: "ERROR", descriptionEstado: this.$t("error") },
      {
        codEstado: "PENDIENTE_APROBACION",
        descriptionEstado: this.$t("label.estado.documento.pendiente-aprobacion"),
      },
    ];
  }
}
