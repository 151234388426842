export interface IParametro {
  id: number;
  codParametro: string;
  codTipo?: string;
  desParametro?: string;
  active: boolean;
  selected: boolean;
}
export const iniParametro: IParametro = {
  id: 0,
  codParametro: "",
  active: false,
  selected: false,
};
