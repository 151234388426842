var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PForm',{attrs:{"action":_vm.actionName,"textAction":_vm.textActionName,"readonly":!_vm.valueLocal.desCliente},on:{"cancel":_vm.cancel,"accept":_vm.action},scopedSlots:_vm._u([{key:"default",fn:function({ validar }){return [_c('div',[(!_vm.valueLocal.desCliente)?_c('PRow',[_c('PCol',{attrs:{"col":"6"}},[_c('PFormSelect',{attrs:{"options":_vm.optionsPaises,"optionValue":"id","optionText":"descripcion","label":_vm.$t('label.pais'),"placeholder":_vm.$t('option.select'),"rules":"required","invalid_feedback":"Obligatorio"},on:{"input":validar,"change":_vm.fetchDelegaciones},model:{value:(_vm.valueLocal.pais),callback:function ($$v) {_vm.$set(_vm.valueLocal, "pais", $$v)},expression:"valueLocal.pais"}})],1),_c('PCol',{attrs:{"col":"6"}},[_c('PFormInputText',{attrs:{"label":_vm.$t('label.codCliente'),"rules":"required|max:10"},model:{value:(_vm.valueLocal.codCliente),callback:function ($$v) {_vm.$set(_vm.valueLocal, "codCliente", $$v)},expression:"valueLocal.codCliente"}})],1)],1):_vm._e(),(!_vm.valueLocal.desCliente)?_c('PRow',[(_vm.valueLocal.codCliente != '' && _vm.valueLocal.pais != '' && _vm.actionName === 'crear')?_c('PCol',{staticClass:"divBotonValidar_ClienteForm",attrs:{"md":"12"}},[_c('PButton',{on:{"click":_vm.validarCliente}},[_vm._v(_vm._s(_vm.$t("label.validar")))])],1):_vm._e()],1):_vm._e(),(_vm.valueLocal.desCliente)?_c('PRow',[_c('PCol',{attrs:{"md":"12"}},[_c('PDataLine',{attrs:{"value":{
              codCliente: _vm.valueLocal.codCliente,
              desCliente: _vm.valueLocal.desCliente,
            },"fields":{
              codCliente: { title: _vm.$t('label.codCliente'), width: '50%' },
              desCliente: { title: _vm.$t('label.cliente'), width: '50%' },
            },"showBorder":true}})],1)],1):_vm._e(),_c('PRow',[(_vm.valueLocal.desCliente)?_c('PCol',{attrs:{"md":"12"}},[_c('PFormSelectTranslateItems',{attrs:{"options":_vm.optionsDelegaciones,"optionValue":"id","optionText":"desDelegacion","rules":"required","titleLeft":_vm.$t('label.delegacion'),"titleRight":_vm.$t('label.seleccionadas')},on:{"change":validar},model:{value:(_vm.valueLocal.delegaciones),callback:function ($$v) {_vm.$set(_vm.valueLocal, "delegaciones", $$v)},expression:"valueLocal.delegaciones"}})],1):_vm._e()],1),_c('PRow',[(_vm.valueLocal.desCliente)?_c('PCol',{attrs:{"col":"6"}},[_c('PFormSwitch',{attrs:{"label":_vm.$t('label.fakeCerts')},model:{value:(_vm.valueLocal.fakeCerts),callback:function ($$v) {_vm.$set(_vm.valueLocal, "fakeCerts", $$v)},expression:"valueLocal.fakeCerts"}})],1):_vm._e(),(_vm.valueLocal.desCliente)?_c('PCol',{attrs:{"col":"6"}},[_c('PFormSwitch',{attrs:{"label":_vm.$t('label.activo')},model:{value:(_vm.valueLocal.active),callback:function ($$v) {_vm.$set(_vm.valueLocal, "active", $$v)},expression:"valueLocal.active"}})],1):_vm._e()],1)],1)]}}]),model:{value:(_vm.validado),callback:function ($$v) {_vm.validado=$$v},expression:"validado"}})
}
var staticRenderFns = []

export { render, staticRenderFns }