
import { Component, Prop, Vue } from "vue-property-decorator";
import EntradasTable from "./EntradasTable.vue";
import VueFilters from "@/vue-filters";
import { IColumnaResponse, IMovimientoParams } from "@/services";
import { IDivisa } from "@/entities";

@Component({
  components: { EntradasTable },
  mixins: [VueFilters],
})
export default class ColapsableEntradasTable extends Vue {
  @Prop({ required: true }) columnsConfig!: IColumnaResponse;
  @Prop({ required: true, default: null }) divisa!: IDivisa;
  @Prop({ required: false, default: null }) params!: IMovimientoParams;
  @Prop({ required: false, default: false }) isBCR!: boolean;

  collapsed = true;
  total = 0;
  totalElements = 0;
}
