
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { iniVideoParams } from "@/services";
import { ICliente } from "@/entities";
import { Locale } from "@/store";

@Component
export class VideosUploadForm extends Vue {
  @Prop({ required: true }) value!: any;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }

  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;
  @Getter("getLocale") locale!: Locale;
  @Getter("getClientesAll") getClientesAll!: ICliente[];
  validado = false;
  valueLocal = iniVideoParams;
  optionsClientes: ICliente[] = [];
  file: File | null = null;
  inicioSeleccion = new Date(1900, 0, 1);

  mounted(): void {
    this.optionsClientes = this.getClientesAll;
  }

  limpiar(): void {
    this.$emit("cancel");
  }
  accept(action: string): void {
    this.$emit("input", this.valueLocal);
    this.$emit("action", { data: this.valueLocal, action: action, file: this.file });
  }
  cancel(): void {
    this.$emit("cancel", { data: this.valueLocal, action: "cancel" });
  }
}

export default VideosUploadForm;
