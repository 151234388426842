
import { Component, Prop, Vue } from "vue-property-decorator";
import IconBilletes from "@/components/common/icon/IconBilletes.vue";
import IconMonedas from "@/components/common/icon/IconMonedas.vue";

@Component({
  components: { IconMonedas, IconBilletes },
})
export default class SaldosPorDivisa extends Vue {
  @Prop({ required: false, default: "SALDOS ACTUALES POR DIVISA" })
  title!: string;

  @Prop({
    required: false,
    default: () => [
      {
        currency: "ARS",
        coin: "1.834.397,38",
        banknote: "1.834.397,38",
        total: "2.348.598,45",
      },
      {
        currency: "USD",
        coin: "1.834.397,38",
        banknote: "1.834.397,38",
        total: "2.348.598,45",
      },
      {
        currency: "EUR",
        coin: "1.834.397,38",
        banknote: "1.834.397,38",
        total: "2.348.598,45",
      },
    ],
  })
  data:
    | [
        {
          currency: string;
          coin: string;
          banknote: string;
          total: string;
        }
      ]
    | undefined;
}
