import { ISort, mockSort } from "@/entities";

export interface IPageable {
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  sort: ISort;
  unpaged: boolean;
}

export const mockPageable = (): IPageable => ({
  offset: 1,
  pageNumber: 1,
  pageSize: 1,
  paged: true,
  sort: mockSort(),
  unpaged: false,
});
