
import { Component, Prop, Vue } from "vue-property-decorator";
import PInfoBar from "@/components/look/PInfoBar.vue";
import { BvTableFieldArray } from "bootstrap-vue";
import VueFilters from "@/vue-filters";
import POptionBar from "@/components/look/POptionBar.vue";
import {
  ICliente,
  IDetallesEntrada,
  IDetallesFalso,
  IDetallesSalida,
  IEntrada,
  IPais,
  ISalida,
} from "@/entities";
import { Getter } from "vuex-class";

@Component({
  components: { POptionBar, PInfoBar },
  mixins: [VueFilters],
})
export default class DetalleMovimiento extends Vue {
  @Getter("getPaises") getPaises!: IPais[];
  @Prop({ required: true, default: false })
  cliente!: ICliente;
  @Prop({ required: true, default: false })
  salidas!: boolean;
  @Prop({ required: true, default: null })
  movimiento!: IEntrada | ISalida;

  border = false;

  mounted(): void {
    const pais = this.getPaises.find((p) => p.codPais == this.cliente?.pais.codPais);
    if (pais?.soConfig.BilletesFalsos.Enabled) {
      this.options.push({ text: "billetes falsos", pressed: false, data: "F" });
    }
    this.fetch();
  }

  async fetch(): Promise<void> {
    if (this.salidas && !("detallesSalida" in this.movimiento)) {
      const response = await this.$services.movimientos._fetchDetallesSalidaByIdSalida(
        this.movimiento.id
      );
      this.$set(this.movimiento, "detallesSalida", response ?? []);
    } else if (!this.salidas && !("detallesEntrada" in this.movimiento)) {
      const response = await this.$services.movimientos._fetchDetallesEntradaByIdEntrada(
        this.movimiento.id
      );
      this.$set(this.movimiento, "detallesEntrada", response ?? []);
    }
  }

  get detallesMovimiento(): IDetallesEntrada[] | IDetallesSalida[] {
    if ("detallesEntrada" in this.movimiento) {
      return this.movimiento.detallesEntrada || [];
    } else if ("detallesSalida" in this.movimiento) {
      return this.movimiento.detallesSalida || [];
    }
    return [];
  }

  get detallesMovimientoExtra(): IDetallesFalso[] {
    if ("detallesFalso" in this.movimiento) {
      if (this.movimiento.detallesFalso) return this.movimiento.detallesFalso || [];
    }
    return [];
  }

  options = [{ text: "desglose", pressed: true, data: "V" }];
  falso = false;

  get infoMovimiento(): { label: string; text: string }[] {
    return [
      {
        label: "código centro",
        text: this.movimiento.puntoservicio?.clientexdel?.delegacion?.codDelegacionSol || "",
      },
      {
        label: "nombre centro",
        text: this.movimiento.puntoservicio?.clientexdel?.delegacion?.desDelegacion || "",
      },
    ];
  }

  get fieldsE(): BvTableFieldArray {
    return [
      {
        key: "numFalso",
        label: "CANTIDAD FALSO",
        sortable: true,
        thClass: "table-header-detail capitalize",
        tdClass: "table-cell-detail",
      },
      {
        key: "desBilletesFalsos",
        label: "BILLETE FALSO",
        sortable: true,
        thClass: "table-header-detail capitalize",
        tdClass: "table-cell-detail",
      },
      {
        key: "numRoto",
        label: "CANTIDAD ROTO",
        sortable: true,
        thClass: "table-header-detail capitalize",
        tdClass: "table-cell-detail",
      },
    ];
  }

  get fields(): BvTableFieldArray {
    return [
      {
        key: "calidadDenominacion.denominacion.mercancia.desParametro",
        label: "MERCANCÍA",
        sortable: true,
        thClass: "table-header-detail capitalize",
        tdClass: "table-cell-detail",
      },
      {
        key: "calidadDenominacion.denominacion.codDenominacion",
        label: "DENOMINACIÓN",
        sortable: true,
        thClass: "table-header-detail capitalize",
        tdClass: "table-cell-detail",
      },
      {
        key: "calidadDenominacion.denominacion.categoria.desParametro",
        label: "CATEGORIA",
        sortable: true,
        thClass: "table-header-detail capitalize",
        tdClass: "table-cell-detail",
      },
      {
        key: "calidadDenominacion.desCalidad",
        label: "CALIDAD",
        sortable: true,
        thClass: "table-header-detail capitalize",
        tdClass: "table-cell-detail",
      },
      {
        key: "numImporte",
        label: "IMPORTE REAL",
        sortable: true,
        thClass: "table-header-detail capitalize",
        tdClass: "table-cell-detail",
      },
    ];
  }

  selectTab(tab: string): void {
    this.falso = tab !== "V";
  }
}
