
import { Component, Prop, Vue } from "vue-property-decorator";
import SalidasTable from "./SalidasTable.vue";
import VueFilters from "@/vue-filters";
import { IDivisa } from "@/entities";
import { IColumnaResponse, IMovimientoParams } from "@/services";

@Component({
  components: { SalidasTable },
  mixins: [VueFilters],
})
export default class ColapsableSalidasTable extends Vue {
  @Prop({ required: true }) columnsConfig!: IColumnaResponse;
  @Prop({ required: true, default: null }) divisa!: IDivisa;
  @Prop({ required: false, default: null }) params!: IMovimientoParams;
  @Prop({ required: false, default: false }) isBCR!: boolean;

  collapsed = true;
  total = 0;
  totalElements = 0;
}
