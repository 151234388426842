
import { Component, Prop, Vue } from "vue-property-decorator";
import PIcon from "../PIcon/PIcon.vue";

@Component({
  components: {
    PIcon,
  },
})
export class PConfirm extends Vue {
  @Prop({ required: false, default: "div" }) type!: "modal" | "div";
  @Prop({ required: false, default: "IconAlerta" }) icon!: string;
  @Prop({ required: false, default: "Aceptar" }) textButtonAccept!: string;
  @Prop({ required: false, default: "Cancelar" }) textButtonCancel!: string;
  get classContainer(): string {
    if (this.type === "modal") {
      return "container_PConfirm";
    } else {
      return "wrapping_PConfirm";
    }
  }
  get classBackground(): string {
    if (this.type === "modal") {
      return "background_PConfirm";
    } else {
      return "";
    }
  }
  cancel(): void {
    this.$emit("cancel");
  }
  accept(): void {
    this.$emit("accept");
  }
}
export default PConfirm;
