export interface iTranslationsTable {
  [key: string]: string;
  orderAsc: string;
  orderDes: string;
  compressData: string;
  extendData: string;
  filter: string;
  hideColumn: string;
}
export interface iLanguageTable {
  language: string;
  translations: iTranslationsTable;
}
export const translations: iLanguageTable[] = [
  {
    language: "es",
    translations: {
      orderAsc: "Ordenar columna ascendente",
      orderDes: "Ordenar columna descendente",
      compressData: "Comprimir datos",
      extendData: "Expandir datos",
      filter: "Filtrar",
      hideColumn: "Ocultar Columna",
    },
  },
  {
    language: "en",
    translations: {
      orderAsc: "Sort ascending column",
      orderDes: "Sort descending column",
      compressData: "Compress data",
      extendData: "Expand data",
      filter: "Filter",
      hideColumn: "Hide column",
    },
  },
];
