
import { Component, Prop, Vue } from "vue-property-decorator";
import { ITransferencia } from "@/entities";
import VueFilters from "@/vue-filters";

@Component({
  name: "detalle-demanda",
  mixins: [VueFilters],
})
export default class TransferenciaDetalle extends Vue {
  @Prop({ required: true }) transferencia!: ITransferencia;
  isShowBankField = false;
  public fecha = "";

  mounted(): void {
    this.isShowBankField = this.transferencia.soVDemanda.clientexdel.cliente.pais.hasInfoBancaria;
    this.fecha = this.filterDate(this.transferencia.fecExpirar);
  }
}
