import { AxiosResponse } from "axios";

/**
 * Generic function to Avoid Unhandled promise rejection error and also handle error granularly.
 *
 * @template T
 * @template U
 * @param {Promise<AxiosResponse>} promise
 * @returns {Promise<{ response: AxiosResponse<T>; error: AxiosResponse<U> }>}
 * @memberof IFolderHttpSvc
 */
export const handleResponse = async <T, U>(
  promise: Promise<AxiosResponse>
): Promise<{ response?: AxiosResponse<T>; error?: AxiosResponse<U> }> => {
  try {
    const response: AxiosResponse<T> = await promise;

    return { response };
  } catch (error) {
    return Promise.resolve({ error: error as AxiosResponse<U> | undefined });
  }
};
