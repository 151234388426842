export interface IQuickFilter {
  cliente: number;
  divisas: string[];
  tipos: Array<"OFERTA" | "DEMANDA">;
}
export const iniQuickFilter: IQuickFilter = {
  cliente: -1,
  divisas: [],
  tipos: ["OFERTA", "DEMANDA"],
};
