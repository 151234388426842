
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PIcon from "@/components/look/PIcon/PIcon.vue";

@Component({
  components: {
    PIcon,
  },
})
export class PModal extends Vue {
  @Prop({ required: true }) show!: boolean;
  @Prop({ required: false, default: "" }) title!: string;
  @Prop({ required: false, default: 0 }) width!: number;
  @Prop({ required: false, default: 0 }) height!: number;
  @Prop({ required: false, default: true }) closeClickExit!: boolean;
  @Prop({ required: false, default: true }) showCloseIcon!: boolean;

  @Watch("show", { immediate: true })
  onChangeShow(): void {
    this.showVar = this.show;
  }
  public windowWidth: number;
  public windowHeight: number;
  public showVar: boolean;
  constructor() {
    super();
    this.windowWidth = 0;
    this.windowHeight = 0;
    this.showVar = false;
  }
  mounted(): void {
    this.$nextTick(function () {
      window.addEventListener("resize", this.getWindowWidth);
      window.addEventListener("resize", this.getWindowHeight);

      //Init
      this.getWindowWidth();
      this.getWindowHeight();
    });
  }
  close(quien: string): void {
    //div#myModal.p_modal_viewer

    if (quien === "modal" && this.closeClickExit) {
      this.$emit("close");
    }
    if (quien == "close") {
      this.$emit("close");
    }
  }
  get dameStyle(): string {
    if (this.height != 0 && this.width != 0) {
      return (
        "width:" +
        this.width * 0.9 +
        "px;min-height:" +
        this.height * 0.9 +
        "px;margin-top:" +
        (this.windowHeight - this.height) / 2 +
        "px;"
      );
    } else if (this.width != 0) {
      return (
        "width:" +
        this.width * 0.9 +
        "px;max-height:" +
        this.windowHeight * 0.9 +
        "px;margin-top:" +
        this.windowHeight * 0.05 +
        "px;"
      );
    } else if (this.height != 0) {
      return (
        "width:" +
        this.windowWidth * 0.9 +
        "px;min-height:" +
        this.height * 0.9 +
        "px;margin-top:" +
        this.height * 0.05 +
        "px;"
      );
    } else {
      return (
        "width:" +
        this.windowWidth * 0.9 +
        "px;max-height:" +
        this.windowHeight * 0.9 +
        "px;margin-top:" +
        this.windowHeight * 0.05 +
        "px;"
      );
    }
  }
  get dameStyleContent(): string {
    if (this.height != 0) {
      return "max-height:" + (this.height * 0.9 - 51) + "px";
    }

    return "";
  }

  getWindowWidth(): void {
    this.windowWidth = document.documentElement.clientWidth;
  }

  getWindowHeight(): void {
    this.windowHeight = document.documentElement.clientHeight;
  }
  beforeDestroy(): void {
    window.removeEventListener("resize", this.getWindowWidth);
    window.removeEventListener("resize", this.getWindowHeight);
  }
}
export default PModal;
