
import { Component, Prop, Vue } from "vue-property-decorator";
import { ILinksPActionsFloat } from "./pActionsFloat.types";

@Component
export class PActionsFloat extends Vue {
  @Prop({ required: true }) actions!: ILinksPActionsFloat[];
  @Prop({ required: false, default: false }) segundoPanel!: boolean;
  @Prop({ required: false, default: "" }) tooltip!: string;
  isOpen = true;

  click(accion: string): void {
    this.$emit("click", accion);
  }
}
export default PActionsFloat;
