import { ICliente, iniCliente, iniParametro, IParametro } from "@/entities";
import { IUsuarioxVideo } from "@/entities/centros-efectivo-backend/usuario-x-video.types";

export interface IVideo {
  id: number;
  cedulasCiudadania: string;
  ciudad: string;
  cliente: ICliente;
  fecha: string;
  fechaNovedad: string;
  fechaSubida: string;
  nombreRevisores: string;
  nombreVideo?: string;
  observaciones: string;
  puntoServicio: string;
  sucursal: string;
  urlVideo: string;
  listUsuarios?: IUsuarioxVideo[];
  estado: IParametro;
}
export const iniVideo: IVideo = {
  id: 0,
  cedulasCiudadania: "",
  ciudad: "",
  cliente: iniCliente,
  fecha: "",
  fechaNovedad: "",
  fechaSubida: "",
  nombreRevisores: "",
  observaciones: "",
  puntoServicio: "",
  sucursal: "",
  nombreVideo: "",
  urlVideo: "",
  estado: iniParametro,
};
