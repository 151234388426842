import {
  ITansferenciasParamsImport,
  ITransferenciaFilter,
  ITransferenciaParams,
  ITransferenciasData,
  ITransferenciasService,
} from "@/services/saldosonline/transferencias/transferencias.types";
import { APPLICATION_JSON } from "@/constans";
import { BACKEND_BASE_URL, serialize } from "@/services";
import { handleResponse } from "@/services/handlers";
import { IResponseError, ITransferencia, ITransferenciaRelacionar } from "@/entities";
import Vue from "vue";
import { EventBus } from "@/event-bus";
import { IParametro } from "@/entities/centros-efectivo-backend/parametro.types";
import { i18n } from "@/i18n";
import { AxiosRequestConfig, AxiosResponse } from "axios";

export class TransferenciasService implements ITransferenciasService {
  async fetchPropias(params: ITransferenciaFilter): Promise<ITransferenciasData | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<ITransferenciasData, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/transferencia/propias`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );
    if (error) {
      EventBus.$emit("on-service-propias-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async fetchPublicas(params: ITransferenciaFilter): Promise<ITransferenciasData | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<ITransferenciasData, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/transferencia/publicas`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );
    if (error) {
      EventBus.$emit("on-service-propias-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async fetchTiposCoste(): Promise<IParametro[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IParametro[], IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/transferencia/tipos-coste`, { headers })
    );
    if (error) {
      EventBus.$emit("on-service-tipos-coste-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async fetchByOferta(id: number): Promise<ITransferencia[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<ITransferencia[], IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/transferencia/oferta/` + id, { headers })
    );
    if (error) {
      Vue.swal({
        title: i18n.t("error").toString(),
        icon: "error",
        html: `<span>${error}</span>`,
      });
      return undefined;
    } else {
      return response!.data;
    }
  }

  async fetchByDemanda(id: number): Promise<ITransferencia[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<ITransferencia[], IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/transferencia/demanda/` + id, { headers })
    );
    if (error) {
      Vue.swal({
        title: i18n.t("error").toString(),
        icon: "error",
        html: `<span>${error}</span>`,
      });
      return undefined;
    } else {
      return response!.data;
    }
  }

  async rejectTransferencia(id: number): Promise<ITransferencia | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<ITransferencia, IResponseError>(
      Vue.$axios.post(`${BACKEND_BASE_URL}/api/transferencia/rechazarTransferencia?id=` + id, {
        headers,
      })
    );
    if (error) {
      Vue.swal({
        title: i18n.t("error").toString(),
        icon: "error",
        html: `<span>${error}</span>`,
      });
      return undefined;
    } else {
      return response!.data;
    }
  }

  async cancelDemandaPrivada(id: number): Promise<ITransferencia | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<ITransferencia, IResponseError>(
      Vue.$axios.post(`${BACKEND_BASE_URL}/api/transferencia/cancelarDemandaPrivada?id=` + id, {
        headers,
      })
    );
    if (error) {
      Vue.swal({
        title: i18n.t("error").toString(),
        icon: "error",
        html: `<span>${error}</span>`,
      });
      return undefined;
    } else {
      return response!.data;
    }
  }

  async aceptarDemandaPrivada(params: ITransferenciaParams): Promise<ITransferencia | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<ITransferencia, IResponseError>(
      Vue.$axios.post(`${BACKEND_BASE_URL}/api/transferencia/aceptarTransferencia`, params, {
        headers,
      })
    );
    if (error) {
      EventBus.$emit("on-service-propias-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async relacionarConDemanda(
    params: ITransferenciaRelacionar
  ): Promise<ITransferencia | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<ITransferencia, IResponseError>(
      Vue.$axios.post(`${BACKEND_BASE_URL}/api/transferencia/relacionar`, params, {
        headers,
      })
    );
    if (error) {
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _downloadPlantilla(id: number): Promise<AxiosResponse | undefined> {
    const postConfig = {
      params: { id: id },
      headers: {
        Accept: "/",
        "X-Requested-With": "XMLHttpRequest",
      },
      responseType: "blob",
    } as AxiosRequestConfig;
    const { response, error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/downloadPlantilla`, postConfig)
    );

    if (error) {
      EventBus.$emit("on-service-transferencias-download-plantilla-error", error.data);
      return undefined;
    } else {
      return response;
    }
  }

  async importTransferencias(
    params: ITansferenciasParamsImport
  ): Promise<AxiosResponse | undefined> {
    const postConfig = {
      headers: {
        Accept: "/",
        "X-Requested-With": "XMLHttpRequest",
      },
      responseType: "blob",
    } as AxiosRequestConfig;
    const { response, error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.post(`${BACKEND_BASE_URL}/api/transferencia/importar`, params, postConfig)
    );

    if (error) {
      EventBus.$emit("on-service-transferencias-importar-error", error.data);
      return undefined;
    } else {
      return response;
    }
  }
}
