import { IDenominacion } from "@/entities";

export interface IDivisa {
  codDivisa: string;
  simbolo: string;
  denominaciones: IDenominacion[];
  id: number;
  selected: boolean;
}
export const iniDivisa: IDivisa = {
  codDivisa: "",
  simbolo: "",
  denominaciones: [],
  id: 0,
  selected: false,
};
