
import { Component, Prop, Vue } from "vue-property-decorator";
import { IDetalleTransferencia, IOfertaDemanda, ITransferenciaRelacionar } from "@/entities";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import VueFilters from "@/vue-filters";
import TransferenciaDetalle from "@/pages/transferencias/common/TransferenciaDetalle.vue";
import OfertaDemandaDetalle from "@/pages/transferencias/common/OfertaDemandaDetalle.vue";
import { SweetAlertResult } from "sweetalert2";

@Component({
  name: "transferir-modal",
  mixins: [VueFilters],
  components: {
    TransferenciaDetalle,
    OfertaDemandaDetalle,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class RelacionarConOferta extends Vue {
  @Prop({ required: true }) oferta!: IOfertaDemanda;
  @Prop({ required: true }) demanda!: IOfertaDemanda;
  isShowBankField = false;

  $refs!: {
    formOfertaCompatible: InstanceType<typeof ValidationObserver>;
  };

  mounted(): void {
    this.isShowBankField = this.demanda.clientexdel.cliente.pais.hasInfoBancaria || false;

    if (this.demanda.checkParcial) {
      this.demanda.detallesDemanda!.forEach((value) => {
        this.$set(value.soCalidadDenominacion, "importe", 0);
      });
    }
  }

  get continuarCondition(): boolean {
    return !(!this.demanda.checkParcial || (this.saldoDemandar > 0 && this.demanda.checkParcial));
  }

  get fields_PTableSimple(): { key: string; label: string }[] {
    return [
      { key: "denominacion", label: this.$t("label.denominacion") as string },
      { key: "disponible", label: this.$t("label.disponible") as string },
      { key: "demandado", label: this.$t("label.demandado") as string },
    ];
  }

  get detallesRelacionar(): IDetalleTransferencia[] {
    let detallesRelacionar: IDetalleTransferencia[] = [];
    this.demanda.detallesDemanda!.forEach((detalleDem) => {
      let detalleOferta = this.oferta.detallesOferta!.find(
        (detalleOf) => detalleOf.soCalidadDenominacion.id === detalleDem.soCalidadDenominacion.id
      );
      if (detalleOferta) {
        let detalleRelacionar: IDetalleTransferencia = {
          id: 0,
          cantidadDemandada: 0,
          cantidadTransferida: 0,
          cantidadOrigen: detalleOferta.cantidadDisponible,
          cantidadSolicitud: this.demanda.checkParcial ? 0 : detalleDem.cantidadDisponible,
          soCalidadDenominacion: detalleDem.soCalidadDenominacion,
          soVDetalleOferta: detalleOferta,
          soVDetalleDemanda: detalleDem,
        };
        detallesRelacionar.push(detalleRelacionar);
      }
    });
    return detallesRelacionar;
  }

  get saldoRestante(): number {
    return this.demanda.cantidadDisponible - this.saldoDemandar;
  }

  get saldoDemandar(): number {
    let sumaImportes = 0;
    if (this.demanda.checkParcial) {
      this.demanda.detallesDemanda!.forEach((value) => {
        if (value.soCalidadDenominacion.importe) {
          sumaImportes = Number(sumaImportes) + Number(value.soCalidadDenominacion.importe);
        }
      });
      return sumaImportes;
    } else {
      return this.demanda.cantidadDisponible;
    }
  }

  cancelar(): void {
    this.$emit("cancel");
  }

  async confirmDemanda(): Promise<void> {
    const res = await this.confirmTransferencia();
    if (res.isConfirmed) {
      await this.demandar();
    }
  }

  async confirmTransferencia(): Promise<SweetAlertResult> {
    return this.$swal({
      title: this.$t("label.confirmar").toString(),
      text: this.$t("label.transferencias.disclaimer").toString(),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: this.$t("label.yes").toString(),
      cancelButtonText: this.$t("label.no").toString(),
    });
  }

  async demandar(): Promise<void> {
    let transferencia: ITransferenciaRelacionar = {
      idOferta: this.oferta.id,
      idDemanda: this.demanda.id,
      detalles: [],
    };
    if (this.demanda.checkParcial) {
      this.detallesRelacionar.forEach((value: IDetalleTransferencia) => {
        if (value.soCalidadDenominacion.importe > 0) {
          transferencia.detalles.push({
            idCalidadDenominacion: value.soCalidadDenominacion.id,
            cantidadDemandada: value.soCalidadDenominacion.importe,
          });
        }
      });
    } else {
      this.detallesRelacionar.forEach((value: IDetalleTransferencia) => {
        transferencia.detalles.push({
          idCalidadDenominacion: value.soCalidadDenominacion.id,
          cantidadDemandada: value.cantidadSolicitud,
        });
      });
    }
    this.$emit("action", {
      action: "relacionarConDemanda",
      data: transferencia,
    });
  }
}
