
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export class PTableSimple extends Vue {
  @Prop({ required: true }) items!: any[];
  @Prop({ required: true }) fields!: any[];
  showItems: number[] = [];
  showExtendItem(index: number): void {
    const numIndex = this.showItems.findIndex((showItem) => showItem === index);
    if (numIndex > -1) {
      this.showItems.splice(numIndex, 1);
    } else {
      this.showItems.push(index);
    }
  }
  isExtendShowItem(index: number): boolean {
    return this.showItems.findIndex((showItem) => showItem === index) > -1;
  }
}
export default PTableSimple;
