
import { Component, Prop, Vue } from "vue-property-decorator";
import * as icons from "./icon";

@Component({
  components: {
    ...icons,
  },
})
export class PIcon extends Vue {
  @Prop({ required: true }) icon!: string;
  @Prop({ required: false, default: "" }) type!: string;
  @Prop({ required: false, default: "#6f6f6f" }) color!: string;
  @Prop({ required: false, default: 16 }) size!: number;
  @Prop({ required: false }) help!: boolean;

  get helpComp(): boolean {
    if (this.help) {
      return true;
    } else {
      return false;
    }
  }
  get iconsApp(): string[] {
    return Object.keys(icons);
  }
  get iconComp(): string {
    return this.icon;
  }
  get style(): string {
    return "color:" + this.color + ";font-size:" + this.size + "px;";
  }
}
export default PIcon;
