import { IDisclaimerService } from "@/services/saldosonline/disclaimer/disclaimer.type";
import { IResponseError } from "@/entities";
import { BACKEND_BASE_URL } from "@/services";
import { handleResponse } from "@/services/handlers";
import Vue from "vue";
import { APPLICATION_JSON } from "@/constans";

export class DisclaimerService implements IDisclaimerService {
  async getDisclaimer(): Promise<string | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<string, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/disclaimer/download/base64`, { headers })
    );
    if (error) {
      return undefined;
    } else {
      return response?.data;
    }
  }
}
