
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "icon-add",
  components: {},
})
export class IconPlay extends Vue {
  @Prop({ default: 26 }) public size!: number;
  @Prop({ default: "#ffffff" }) public color!: string;

  constructor() {
    super();
  }
}
export default IconPlay;
