
import { Component, Vue } from "vue-property-decorator";
import VideosFilterForm from "./components/VideosFilterForm.vue";
import VideosUploadForm from "./components/VideosUploadForm.vue";
import VideosPlayForm from "./components/VideosPlayForm.vue";
import VideosTable from "./components/VideosTable.vue";
import {
  EDirection,
  IColumnaResponse,
  iniVideoParams,
  IPaged,
  IVideoFilter,
  IVideoResponse,
} from "@/services";
import { iniVideo, IVideo } from "@/entities/centros-efectivo-backend/video.types";
import VueMethods from "@/vue-methods";
import QuickFilter from "@/pages/videos/components/QuickFilter.vue";
import { Action, Getter } from "vuex-class";
import { ICliente } from "@/entities";
import { Dictionary } from "vue-router/types/router";

@Component({
  components: {
    VideosFilterForm,
    VideosUploadForm,
    VideosPlayForm,
    VideosTable,
    QuickFilter,
  },
  mixins: [VueMethods],
})
export class VideosPage extends Vue {
  @Getter("getClienteSelected") clienteSelected: ICliente | undefined;
  @Action("fetchColumna") fetchColumna!: (
    codTabla: string
  ) => Promise<IColumnaResponse | undefined>;

  codTabla = "videos";
  videosConfig: IColumnaResponse | null = null;
  videoNew = iniVideoParams;
  mostrarFiltros = false;
  mostrarVideosPlayForm = false;
  mostrarVideosUploadForm = false;
  actionsFloat = [{ action: "newVideo", label: this.$t("label.subirVideo") as string }];
  progress: { total: number; parcial: number } = { total: 0, parcial: 0 };
  verModal = false;
  titleModal = "";
  queryParams = this.$route.query;
  params: IVideoFilter = {
    pkCliente: this.queryParams.pkCliente ? Number(this.queryParams.pkCliente) : undefined,
    fechaNovedad:
      this.getStartDate(this.queryParams.fechaNovedad?.toString()) ??
      this.getStartDate(new Date().toISOString()),
    fechaSubida: this.queryParams.fechaSubida
      ? this.getStartDate(this.queryParams.fechaSubida?.toString())
      : undefined,
    ciudad: this.queryParams.ciudad?.toString(),
    sucursal: this.queryParams.sucursal?.toString(),
    puntoServicio: this.queryParams.puntoServicio?.toString(),
    nombreRevisores: this.queryParams.nombreRevisores?.toString(),
    cedulasCiudadania: this.queryParams.cedulasCiudadania?.toString(),
    observaciones: this.queryParams.observaciones?.toString(),
    nombreArchivo: this.queryParams.nombreArchivo?.toString(),
    estado:
      this.queryParams.estado
        ?.toString()
        .split(",")
        .filter((s) => s !== "") ?? [],
    pkDivisa: this.queryParams.pkDivisa ? Number(this.queryParams.pkDivisa) : undefined,
  };
  filtersVideos: IVideoFilter = {
    ciudad: this.params.ciudad,
    sucursal: this.params.sucursal,
    puntoServicio: this.params.puntoServicio,
    fechaNovedad: this.params.fechaNovedad,
    fechaSubida: this.params.fechaSubida,
    nombreRevisores: this.params.nombreRevisores,
    cedulasCiudadania: this.params.cedulasCiudadania,
    observaciones: this.params.observaciones,
    nombreArchivo: this.params.nombreArchivo,
    estados: this.params.estado,
  };
  pagination: IPaged = {
    direction: EDirection.DESC,
    orderBy: "fecha",
    page: 1,
    size: 10,
  };
  watchVideo: IVideo = iniVideo;
  videos: IVideo[] = [];
  totalElements = 0;
  estados: any[] = [];

  resetFilters(): void {
    this.filtersVideos.delegationsSelected = [];
  }

  mounted(): void {
    this.getEstados();
  }

  get dameTextoBotonFiltros(): string {
    let numFiltros = 0;
    Object.keys(this.filtersVideos).forEach((key: string) => {
      if (this.filtersVideos[key] != "" && this.filtersVideos[key] != undefined) {
        numFiltros = numFiltros + 1;
      }
    });

    return numFiltros
      ? `(${numFiltros}) ${this.$t("label.filtros")}`
      : this.$t("label.filtros").toString();
  }

  verSubirVideo(accion: string): void {
    if (accion === "newVideo") {
      this.videoNew = iniVideoParams; //inicializamos el video
      this.progress.parcial = 0;
      this.titleModal = this.$t("label.subirVideo") as string;
      this.mostrarVideosPlayForm = false;
      this.mostrarVideosUploadForm = true;
      this.verModal = true;
    }
  }

  async getEstados(): Promise<void> {
    let aux = await this.$services.videos.getEstadosVideos();
    if (aux) {
      this.estados = aux.sort((a, b) => this.sortAscending(a, b, "desParametro"));
    }
  }

  async fetchVideos(): Promise<void> {
    this.params.page = this.pagination.page!;
    this.params.size = this.pagination.size;
    this.params.orderBy = this.pagination.orderBy;
    this.params.direction = this.pagination.direction;

    this.$router
      .replace({ path: this.$route.path, query: { ...this.params } as Dictionary<any> })
      .catch(() => {
        // Do nothing
      });

    this.params.page = this.pagination.page! - 1;

    await this.$services.videos
      .getVideos(this.params)
      .then((response: IVideoResponse | undefined) => {
        if (response) {
          this.videos = response.content;
          this.totalElements = response.totalElements;
        }
      });
  }

  get progressComp(): { total: number; parcial: number } {
    return this.progress;
  }

  actions(obj: { action: string; data: any; file?: File }): void {
    if (obj.action === "filtrar" || obj.action === "clean") {
      this.params.page = 1;
      this.params.pkCliente = this.clienteSelected?.id
        ? Number(this.clienteSelected?.id)
        : undefined;
      this.params.ciudad = this.filtersVideos.ciudad;
      this.params.sucursal = this.filtersVideos.sucursal;
      this.params.puntoServicio = this.filtersVideos.puntoServicio;
      this.params.fechaNovedad = this.getStartDate(this.filtersVideos.fechaNovedad);
      this.params.fechaSubida = this.getStartDate(this.filtersVideos.fechaSubida);
      this.params.nombreRevisores = this.filtersVideos.nombreRevisores;
      this.params.cedulasCiudadania = this.filtersVideos.cedulasCiudadania;
      this.params.observaciones = this.filtersVideos.observaciones;
      this.params.nombreArchivo = this.filtersVideos.nombreArchivo;
      this.params.estado = this.filtersVideos.estados;

      this.fetchVideos();
      this.mostrarFiltros = false;
    } else if (obj.action === "reject") {
      this.fetchVideos();
      this.verModal = false;
    } else if (obj.action === "uploadVideo") {
      this.verModal = false;
      //rellenamos lo que ocupa el archivo
      this.progress.total = obj.file!.size;

      this.$services.videos.save(obj.data, obj.file!, this.progress).then((response?: IVideo) => {
        this.progress = { total: 0, parcial: 0 };
        if (response) {
          this.fetchVideos();
          this.makeToast("success", this.$t("title.videos.subidoCorrectamente").toString());
        } else {
          this.$swal({
            icon: "error",
            title: this.$i18n.t("unexpectedError.title") as string,
            html: this.$i18n.t("unexpectedError.description") as string,
          });
        }
      });
    } else if (obj.action === "watch") {
      this.$services.videos.getVideo(obj.data.id).then((response?: IVideo) => {
        if (response) {
          this.watchVideo = response;
          this.titleModal = this.$t("label.verVideo") as string;
          this.mostrarVideosPlayForm = true;
          this.mostrarVideosUploadForm = false;
          this.verModal = true;
        }
      });
    } else if (obj.action === "download") {
      this.$services.videos.downloadInforme(obj.data.id, navigator.language.split("-")[0]);
    } else if (obj.action === "aceptarDatos") {
      this.$services.videos
        .saveAcceptData(obj.data.listUsuarios[0])
        .then((response: IVideo | undefined) => {
          if (response) {
            this.verModal = false;
            this.watchVideo = response;
            this.verModal = true;
            this.fetchVideos();
          }
        });
    } else if (obj.action === "aceptar") {
      this.$services.videos.saveVisionado(obj.data.listUsuarios[0]).then(() => {
        this.verModal = false;
      });
    } else if (obj.action === "quickFilter_client") {
      this.fetchVideos();
    }
  }

  async created(): Promise<void> {
    const responses = await this.fetchColumna(this.codTabla);

    this.videosConfig = responses || {
      codTabla: this.codTabla,
      codColumna: "",
    };
  }
}

export default VideosPage;
