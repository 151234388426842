import { ITransferenciasQuickFilter } from "@/pages/transferencias/types";
import { ISaldosQuickFilter } from "@/pages/saldos/types";
import { IMutations, IState, Locale } from "@/store";
import {
  ICliente,
  IClienteAll,
  ICountryConfigData,
  IDelegacion,
  IDivisa,
  IPais,
  IParametro,
  IUsuario,
} from "@/entities";
import { IColumnaResponse } from "@/services";

export const mutations: IMutations = {
  SET_CURRENT_LOCALE(state: IState, payload: Locale): void {
    state.locale = payload;
  },
  SET_CURRENT_LOCATION(state: IState, payload: string): void {
    state.currentLocation = payload;
  },
  SET_SIGNED_IN(state: IState, payload: boolean): void {
    state.signedIn = payload;
  },
  SET_LOGGED_USER(state: IState, payload: IUsuario): void {
    state.loggedUser = payload;
  },
  SET_SALDOS_PAGE_QUICK_FILTER(state: IState, payload: ISaldosQuickFilter): void {
    state.saldosPageQuickFilter = payload;
  },
  SET_CLIENTES(state: IState, payload: ICliente[]): void {
    state.clientes = payload;
  },
  SET_CLIENTESALL(state: IState, payload: IClienteAll[]): void {
    state.clientesAll = payload;
  },
  SET_CLIENTE_X_DIVISA(state: IState, payload: IDivisa[]): void {
    state.clienteXDivisa = payload;
  },
  SET_DELEGACION_X_CLIENTE(state: IState, payload: IDelegacion[]): void {
    state.delegacionesCliente = payload;
  },
  SET_DIVISAS(state: IState, payload: IDivisa[]): void {
    state.divisas = payload;
  },
  SET_COLUMNAS(state: IState, payload: { codTabla: string; columnas: IColumnaResponse }): void {
    let config = state.columnas.find((obj) => obj.codTabla === payload.codTabla);

    const columnas = payload.columnas?.codColumna?.split(",") ?? [];

    if (!config) {
      config = { codTabla: payload.codTabla, columnas: columnas };
      state.columnas.push(config);
    } else {
      config.columnas = columnas;
    }
  },
  SET_CLIENTE_SELECTED(state: IState, payload: ICliente): void {
    state.clienteSelected = payload;
  },
  SET_COUNTRY_CONFIG(state: IState, payload: ICountryConfigData): void {
    state.countryConfig = payload;
  },
  SET_TRANSFERENCIAS_PAGE_QUICK_FILTER(state: IState, payload: ITransferenciasQuickFilter): void {
    state.transferenciasPageQuickFilter = payload;
  },
  SET_COSTES(state: IState, payload: IParametro[]): void {
    state.costes = payload;
  },
  SET_PAISES(state: IState, payload: IPais[]): void {
    state.paises = payload;
  },
  SET_DISCLAIMER(state: IState, payload: string): void {
    state.disclaimer = payload;
  },
};
