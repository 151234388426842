
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";

@Component({ components: { draggable } })
export class PDragAndDrop extends Vue {
  @Prop({ required: true }) value!: any[];
  @Prop({ required: true }) id!: string;
  @Watch("value", { deep: true, immediate: true })
  onChangeValue(): void {
    this.valueLocal = [];
    this.value.forEach((fi: any) => {
      this.valueLocal.push({ ...fi });
    });
  }
  drag = false;
  valueLocal: any[] = [];
  change(): void {
    this.$emit("input", this.valueLocal);
    this.$emit("change", this.valueLocal);
  }
}
export default PDragAndDrop;
