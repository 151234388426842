import { EDirection, IUsuarioFilter, IUsuarioServiceMock, mockPaisResponse } from "@/services";
import { IUsuario } from "@/entities";

export const mockUsuarioService = (): IUsuarioServiceMock => ({
  _me: jest.fn(),
  _fetch: jest.fn(),
  _update: jest.fn(),
  updateAcceptDisclaimer: jest.fn(),
});

export const mockUsuarioParams = (): IUsuarioFilter => ({
  direction: EDirection.ASC,
  orderBy: "",
  page: 0,
  size: 0,
  active: false,
  cliente: 0,
  codEmail: "",
  codUsuario: "",
  configurado: false,
  desNombre: "",
  fecCrearFin: "",
  fecCrearInicio: "",
  id: 0,
  ntfActiva: false,
  rol: 0,
});

export const mockUsuario = (): IUsuario => ({
  active: true,
  codEmail: "",
  codUsuario: "1",
  desNombre: "",
  desApellidos: "",
  fecBaja: "",
  fecCrear: "",
  fecModif: "",
  id: 1,
  ntfActiva: true,
  celular: "",
  ntfContactoActiva: false,
  permisos: [],
  nivel: 1,
  roles: [],
  pais: mockPaisResponse().content[0],
  sub: "",
  acceptDisclaimer: false,
});
