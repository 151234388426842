import { EDirection, ISaldoParams, ISaldoServiceMock } from "@/services";

export const mockSaldoService = (): ISaldoServiceMock => ({
  _fetch: jest.fn(),
  _fetchBCR: jest.fn(),
  _fetchDetalle: jest.fn(),
  _fetchSaldoActual: jest.fn(),
  _fetchSaldosActuales: jest.fn(),
  _downloadSaldos: jest.fn(),
  _downloadSaldosBCR: jest.fn(),
  _downloadFull: jest.fn(),
  _downloadFullBCR: jest.fn(),
  _downloadSaldosPDF: jest.fn(),
  _downloadSaldosPDFBCR: jest.fn(),
  _downloadSaldosPDFWithoutDenominations: jest.fn(),
  _downloadSaldosPDFWithoutDenominationsBCR: jest.fn(),
  _downloadSaldosExcelWithoutDenominations: jest.fn(),
  _downloadSaldosExcelWithoutDenominationsBCR: jest.fn(),
});

export const mockSaldoParams = (): ISaldoParams => ({
  direction: EDirection.ASC,
  orderBy: "",
  page: 0,
  size: 0,
  fecCertificadoFin: "",
  fecCertificadoInicio: "",
  pkCliente: 0,
  pkDelegacion: [0],
  pkDivisa: 0, //no funciona con array pese a estar definido así en el swagger ¿?
  saldoActualMaximo: 0,
  saldoActualMinimo: 0,
});
