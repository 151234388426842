
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { ICliente, IClienteAll, IDivisa } from "@/entities";
import {
  iniQuickFilter,
  IQuickFilter,
  ITransferenciasQuickFilter,
} from "@/pages/transferencias/types";
import { todosClienteAll } from "@/constans";

@Component
export class QuickFilter extends Vue {
  @Prop({ required: true }) value!: IQuickFilter;
  valueLocal: IQuickFilter = iniQuickFilter;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }

  @Getter("getTransferenciasPageQuickFilter")
  getTransferenciasPageQuickFilter!: ITransferenciasQuickFilter;
  @Getter("getClienteXDivisa") getClienteXDivisa!: IDivisa[];
  @Getter("getClientesAll") getClientesAll!: IClienteAll[];
  @Getter("getClienteSelected") getClienteSelected!: ICliente;

  @Action("setClienteSelected") setClienteSelected!: (cliente?: ICliente) => Promise<void>;
  @Action("setTransferenciasPageQuickFilter") setTransferenciasPageQuickFilter!: (
    filter: ITransferenciasQuickFilter
  ) => void;

  @Ref("clienteSelect") clienteSelect: any;

  private admin = false;
  private optionTodos: IClienteAll = todosClienteAll;

  async mounted(): Promise<void> {
    this.admin = this.getClientesAll.length > 1;
  }

  openSelectClientes(): void {
    this.clienteSelect.pussOption = true;
  }

  giveMeClassDivisa(divisa: string): string {
    //recoremos todos a ver si estan seleccionados
    let allSelected = true;
    this.getClienteXDivisa.forEach((value) => {
      if (this.valueLocal.divisas.findIndex((div) => div === value.codDivisa) < 0) {
        allSelected = false;
      }
    });
    if (allSelected && divisa === "todas") {
      return "buttonSelected_QuickFilter";
    } else if (!allSelected) {
      const indexSelectedDivisa = this.valueLocal.divisas.findIndex((div) => div === divisa);
      if (indexSelectedDivisa > -1) {
        return "buttonSelected_QuickFilter";
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  giveMeClassTipo(tipoId: string): string {
    let allSelected = true;
    //si no encontramos alguno es que no estan selecionados
    if (this.valueLocal.tipos.findIndex((tip) => tip === "OFERTA") < 0) {
      allSelected = false;
    }
    if (this.valueLocal.tipos.findIndex((tip) => tip === "DEMANDA") < 0) {
      allSelected = false;
    }
    if (allSelected && tipoId === "todas") {
      return "buttonSelected_QuickFilter";
    } else if (!allSelected) {
      const indexSelectedtipo = this.valueLocal.tipos.findIndex((tipId) => tipId === tipoId);
      if (indexSelectedtipo > -1) {
        return "buttonSelected_QuickFilter";
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  get clientesAll(): Partial<IClienteAll>[] {
    const clientes: Partial<IClienteAll>[] = Object.assign([], this.getClientesAll);

    if (this.admin) {
      clientes.unshift(this.optionTodos);
    }

    return clientes;
  }

  get clienteSelected(): number {
    let cliente = this.getClienteSelected;
    return (cliente?.id ?? this.valueLocal.cliente) || -1;
  }

  set clienteSelected(clienteId: number) {
    if (clienteId && clienteId !== -1) {
      this.setClienteSelected(
        this.clientesAll.find((cliente) => cliente.id === clienteId) as ICliente
      );
    } else {
      this.setClienteSelected();
    }

    this.valueLocal.cliente = clienteId;
    this.$emit("input", this.valueLocal);
    this.$emit("change", { action: "quickFilter_cliente", data: this.valueLocal });
  }

  clickDivisa(divisaSelected: string): void {
    let allSelected = true;
    this.getClienteXDivisa.forEach((divisa) => {
      if (this.valueLocal.divisas.findIndex((div) => div === divisa.codDivisa) < 0) {
        allSelected = false;
      }
    });
    if (divisaSelected === "todas") {
      this.valueLocal.divisas = [];
      this.getClienteXDivisa.forEach((divisa) => {
        this.valueLocal.divisas.push(divisa.codDivisa);
      });
      //seleccionamos todos
    } else if (allSelected) {
      //si pasamos de todos a seleccionar solo uno
      this.valueLocal.divisas = [];
      this.valueLocal.divisas.push(divisaSelected);
    } else {
      const divisaSeleccionada = this.getClienteXDivisa.find(
        (divisa) => divisa.codDivisa === divisaSelected
      );
      const indexSelectedDivisa = this.valueLocal.divisas.findIndex(
        (divisaId) => divisaId === divisaSelected
      );
      if (indexSelectedDivisa > -1) {
        //como exite la quitamos
        this.valueLocal.divisas.splice(indexSelectedDivisa, 1);
        if (!this.valueLocal.divisas.length) {
          this.getClienteXDivisa.forEach((divisa) => {
            this.valueLocal.divisas.push(divisa.codDivisa);
          });
        }
      } else {
        //como no exite lo Añadiremos
        if (divisaSeleccionada) this.valueLocal.divisas.push(divisaSeleccionada.codDivisa);
      }
    }
    this.$emit("input", this.valueLocal);
    this.$emit("change", { action: "quickFilter_divisa", data: this.valueLocal });
  }

  clickTipo(tipoSelected: "todas" | "OFERTA" | "DEMANDA"): void {
    let allSelected = true;
    //sino encontramos alguno esque no estan selecionados
    if (this.valueLocal.tipos.findIndex((tip) => tip === "OFERTA") < 0) {
      allSelected = false;
    }
    if (this.valueLocal.tipos.findIndex((tip) => tip === "DEMANDA") < 0) {
      allSelected = false;
    }
    if (tipoSelected === "todas") {
      this.valueLocal.tipos = [];
      this.valueLocal.tipos.push("OFERTA");
      this.valueLocal.tipos.push("DEMANDA");

      //seleccionamos todos
    } else if (allSelected) {
      //sipasamos de todos a seleccionar solo uno
      this.valueLocal.tipos = [];
      this.valueLocal.tipos.push(tipoSelected);
    } else {
      const indexSelectedtipo = this.valueLocal.tipos.findIndex(
        (tipoId) => tipoId === tipoSelected
      );
      if (indexSelectedtipo > -1) {
        //como existe la quitamos
        this.valueLocal.tipos.splice(indexSelectedtipo, 1);
        if (!this.valueLocal.tipos.length) {
          this.valueLocal.tipos.push("OFERTA");
          this.valueLocal.tipos.push("DEMANDA");
        }
      } else {
        //como no existe lo Añadiremos
        this.valueLocal.tipos.push(tipoSelected);
      }
    }
    this.$emit("input", this.valueLocal);
    this.$emit("change", { action: "quickFilter_tipo", data: this.valueLocal });
  }
}

export default QuickFilter;
