import { render, staticRenderFns } from "./TransferenciasPublicasFiltersForm.vue?vue&type=template&id=3654aeee&scoped=true&"
import script from "./TransferenciasPublicasFiltersForm.vue?vue&type=script&lang=ts&"
export * from "./TransferenciasPublicasFiltersForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3654aeee",
  null
  
)

export default component.exports