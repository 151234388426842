
import { VARIANT } from "@/entities";
import { Component, Vue } from "vue-property-decorator";
import { EventBus } from "@/event-bus";

@Component
export default class PToast extends Vue {
  mounted(): void {
    EventBus.$on("p-toast", (p: { variant: VARIANT; message: string }) => {
      this.makeToast(p.variant, p.message);
    });
  }

  makeToast(variant: VARIANT, message: string): void {
    this.$bvToast.toast(message, {
      autoHideDelay: 4000,
      variant,
      toaster: "b-toaster-bottom-center",
      noCloseButton: true,
    });
  }
}
