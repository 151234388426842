
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "icon-inbox",
  components: {},
})
export class IconInbox extends Vue {
  @Prop({ default: 26 }) public size!: number;
  @Prop({ default: "#ffffff" }) public color!: string;
  @Prop() public msgColor!: string;
  @Prop() public msgActive!: boolean;

  constructor() {
    super();
  }
}
export default IconInbox;
