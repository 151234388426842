
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IClienteFilter, IClienteTableContent } from "@/services";
import VueMethods from "@/vue-methods";
import { Getter } from "vuex-class";
import { Locale } from "@/store";
import { IPais } from "@/entities";

@Component({
  mixins: [VueMethods],
})
export class ClienteFilterForm extends Vue {
  @Prop({ required: true }) value!: IClienteFilter;
  @Prop({ required: true }) clientes!: IClienteTableContent[];
  @Getter("getLocale") locale!: Locale;
  @Getter("getPaises") paises!: IPais[];
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }
  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;
  valueLocal = {
    paises: [],
    cliente: "",
    fechaBaja: "",
    fechaModificacion: "",
    fechaCreacion: "",
    activeDesc: "",
    active: false,
  };
  initialValueLocal = {
    paises: [],
    cliente: "",
    fechaBaja: "",
    fechaModificacion: "",
    fechaCreacion: "",
    activeDesc: "",
    active: false,
  };
  optionsActiva = [
    { label: this.$t("label.si"), value: "SI" },
    { label: this.$t("label.no"), value: "NO" },
  ];
  limpiar(): void {
    this.valueLocal.paises = [];
    this.valueLocal.cliente = "";
    this.valueLocal.fechaBaja = "";
    this.valueLocal.fechaModificacion = "";
    this.valueLocal.fechaCreacion = "";
    this.valueLocal.activeDesc = "";
    this.valueLocal.active = false;
    if (!this.isInitialObject(this.value, this.initialValueLocal)) {
      this.$emit("input", this.valueLocal);
      this.$emit("cancel", { action: "clean" });
    }
  }
  get paisesAll(): IPais[] {
    let paisesList: IPais[] = [];
    if (this.clientes) {
      paisesList = this.paises;
    }
    return paisesList.sort((a, b) => this.sortAscending(a, b, "codPais"));
  }
  action(action: string): void {
    this.$emit("input", this.valueLocal);
    this.$emit("action", { data: this.valueLocal, action: action });
  }
  validado = false;
}
export default ClienteFilterForm;
