
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
})
export class PCard extends Vue {
  @Prop({ required: false, default: "p_card" }) variant!: string;
  @Prop({ required: false, default: false }) header!: boolean;
  @Prop({ required: false, default: false }) footer!: boolean;
}
export default PCard;
