
import { Component, Vue } from "vue-property-decorator";
import VueFilters from "@/vue-filters";
import { Getter } from "vuex-class";
import { iniUsuarioUpdateDisclaimer, IUsuarioUpdateDisclaimer } from "@/services";
import { IUsuario } from "@/entities";

@Component({
  name: "disclaimer-modal",
  components: { VueFilters },
})
export default class DisclaimerModal extends Vue {
  @Getter("getDisclaimer") getDisclaimer!: string;
  @Getter("getLoggedUser") getLoggedUser!: IUsuario;
  usuarioAcceptDisclaimer: IUsuarioUpdateDisclaimer = iniUsuarioUpdateDisclaimer;

  async mounted(): Promise<void> {
    if (this.getDisclaimer && !this.getLoggedUser.acceptDisclaimer) {
      this.openDisclaimerModal();
    }
  }

  get hideHeaderClose(): boolean | undefined {
    return this.getLoggedUser?.acceptDisclaimer;
  }

  async onUpdateAcceptDisclaimer(): Promise<void> {
    this.usuarioAcceptDisclaimer.acceptDisclaimer = this.getLoggedUser.acceptDisclaimer;

    await this.$services.usuarios.updateAcceptDisclaimer(this.usuarioAcceptDisclaimer);
  }

  openDisclaimerModal(): void {
    return this.$bvModal.show("disclaimer-modal");
  }
}
