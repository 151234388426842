
import { Component, Vue } from "vue-property-decorator";
import Sidebar from "./Sidebar.vue";
import TopNavbar from "./TopNavbar.vue";

import LayoutContent from "./LayoutContent.vue";

@Component({
  name: "default-layout",
  components: {
    Sidebar,
    TopNavbar,
    LayoutContent,
  },
})
export class DefaultLayout extends Vue {
  constructor() {
    super();
  }
}
export default DefaultLayout;
