
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Locale } from "@/store";
import { ICliente, IDelegacion } from "@/entities";
import VueMethods from "@/vue-methods";
import { MAX_RANGE_MONTH_DATE } from "@/constans";

@Component({
  mixins: [VueMethods],
})
export class SaldosTodosFiltersForm extends Vue {
  @Prop({ required: true }) value!: any;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.valueLocal = JSON.parse(JSON.stringify(this.value));
    this.$set(
      this.valueLocal,
      "fecCertificadoInicio",
      this.valueLocal.fecCertificadoInicio
        ? new Date(this.valueLocal.fecCertificadoInicio!)
        : this.getStartDate(new Date().toISOString())
    );
    this.$set(
      this.valueLocal,
      "fecCertificadoFin",
      this.valueLocal.fecCertificadoFin
        ? new Date(this.valueLocal.fecCertificadoFin!)
        : this.getEndDate(new Date().toISOString())
    );
  }
  valueLocal = {
    fecCertificadoInicio: this.getStartDate(new Date().toISOString()),
    fecCertificadoFin: this.getEndDate(new Date().toISOString()),
    delegationsSelected: [],
  };
  initialValueLocal = {
    fecCertificadoInicio: this.getStartDate(new Date().toISOString()),
    fecCertificadoFin: this.getEndDate(new Date().toISOString()),
    delegationsSelected: [],
  };
  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;
  @Getter("getLocale") locale!: Locale;
  @Getter("getClienteSelected") clienteSelected!: ICliente;
  validado = false;

  maxRange = MAX_RANGE_MONTH_DATE;

  limpiar(): void {
    this.$set(this.valueLocal, "fecCertificadoInicio", this.getStartDate(new Date().toISOString()));
    this.$set(this.valueLocal, "fecCertificadoFin", this.getEndDate(new Date().toISOString()));
    this.valueLocal.delegationsSelected = [];
    if (!this.isInitialObject(this.value, this.initialValueLocal)) {
      this.$emit("input", this.valueLocal);
      this.$emit("cancel", { action: "clean" });
    }
  }
  action(action: string): void {
    this.$emit("input", this.valueLocal);
    this.$emit("action", { data: this.valueLocal, action: action });
  }

  get delegations(): IDelegacion[] {
    let delegationsList: IDelegacion[] = [];
    this.clienteSelected?.clientexdels.forEach((clientexdel) => {
      if (clientexdel.active) delegationsList.push(clientexdel.delegacion);
    });
    return delegationsList.sort((a, b) => this.sortAscending(a, b, "desDelegacion"));
  }

  get fechaFinMax(): string {
    let fechaInicio = new Date(this.valueLocal.fecCertificadoInicio!);
    let fechaFin = new Date(this.valueLocal.fecCertificadoFin!);
    fechaInicio.setMonth(fechaInicio.getMonth() + MAX_RANGE_MONTH_DATE);
    let today = new Date();
    if (today > fechaInicio) {
      if (fechaFin > fechaInicio) {
        this.$set(this.valueLocal, "fecCertificadoFin", this.getEndDate(fechaInicio.toISOString()));
      }
      return this.getEndDate(fechaInicio.toISOString())!;
    } else {
      if (fechaFin > today) {
        this.$set(this.valueLocal, "fecCertificadoFin", this.getEndDate(today.toISOString()));
      }
      return this.getEndDate(today.toISOString())!;
    }
  }
}
export default SaldosTodosFiltersForm;
