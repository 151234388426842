
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PIcon from "../PIcon/PIcon.vue";

@Component({
  components: { PIcon },
})
export class PColapsable extends Vue {
  @Prop({ required: false }) value!: boolean;
  @Watch("value", { immediate: true })
  onChangeValue(): void {
    this.valueLocal = this.value;
  }
  @Prop({ required: false, default: "gray" }) variant!: "gray" | "yellow";
  valueLocal = false;
  get classHr(): string {
    return this.variant + "Hr_PColapsable";
  }

  onClickColapsable(): void {
    this.valueLocal = !this.valueLocal;
    this.$emit("change", this.valueLocal);
  }
}
export default PColapsable;
