
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import {
  ICliente,
  IDelegacion,
  IField,
  IParametro,
  ISoConfColumnasUpdateDTO,
  IUsuario,
} from "@/entities";
import { Action, Getter } from "vuex-class";
import Pagination from "@/components/common/Pagination.vue";
import VueFilters from "@/vue-filters";

import SelectColumns from "@/components/common/SelectColumns/SelectColumns.vue";
import QuickFilter from "@/components/documentacion/common/QuickFilter.vue";
import {
  EDirection,
  IColumnaResponse,
  IDocumentacionNonHistoricalFilters,
  IDocumentacionNonHistoricalParams,
  IDocumentacionNonHistoricalResponse,
} from "@/services";
import { IDocumentoNonHistoricalTableItem } from "@/entities/centros-efectivo-backend/documento.types";
import DocumentacionNonHistoricalTableFilters from "@/components/documentacion/DocumentacionNonHistoricalTableFilters.vue";
import VueMethods from "@/vue-methods";
import { SweetAlertResult } from "sweetalert2";
import { Dictionary } from "vue-router/types/router";
import { IconDownload, IconTrash } from "@/components/look";
import { MAX_RANGE_MONTH_DATE, ROLE_PAIS } from "@/constans";

@Component({
  components: {
    DocumentacionNonHistoricalTableFilters,
    IconDownload,
    IconTrash,
    SelectColumns,
    QuickFilter,
    Pagination,
    MAX_RANGE_MONTH_DATE,
  },
  mixins: [VueFilters, VueMethods],
})
export default class DocumentosNonHistoricalTable extends Vue {
  @Getter("getClienteSelected") getClienteSelected!: ICliente;
  @Action("saveColumna") saveColumna!: (
    params: ISoConfColumnasUpdateDTO
  ) => Promise<IColumnaResponse | undefined>;

  @Getter("getDelegacionesCliente") delegations!: IDelegacion[];

  @Getter("getLoggedUser") getLoggerUser!: IUsuario;
  usuarioLogado: Partial<IUsuario> = {};

  @Prop({ required: false, default: {} })
  fieldsI!: IField[];

  @Prop({ required: true, default: {} })
  params!: IDocumentacionNonHistoricalParams;

  public maxRangeMonthDate = MAX_RANGE_MONTH_DATE;

  public filters: IDocumentacionNonHistoricalFilters = {
    documentTitle: this.params.documentTitle,
    documentType: this.params.documentType,
    delegaciones: this.params.delegaciones || [],
    fechaDocumentoInicio: this.params.fechaDocumentoInicio,
    fechaDocumentoFin: this.params.fechaDocumentoFin,
  };

  public mostrarFiltros = false;
  public documentacionResponse?: IDocumentacionNonHistoricalResponse | null = null;
  private documentacionResponseList: IDocumentoNonHistoricalTableItem[] = [];
  public codTable = "documentosNonHistorical";
  public sortDesc = this.params.direction === EDirection.DESC;

  mounted(): void {
    this.usuarioLogado = this.getLoggerUser;
  }

  async loadInfo(): Promise<void> {
    const delegacionsSelected = this.delegations?.filter((d) =>
      this.params.delegaciones?.find((code) => code === d.codDelegacionSol)
    );

    this.params.delegaciones = delegacionsSelected.map((d) => d.codDelegacionSol);
    this.filters.delegaciones = delegacionsSelected.map((d) => d.codDelegacionSol);

    await this.updateTiposDocumentos();
    await this.getDocumentacion();
  }

  async onChangeQuickFilter(): Promise<void> {
    this.params.page = 1;
    await this.loadInfo();
  }

  get items(): IDocumentoNonHistoricalTableItem[] {
    return this.documentacionResponseList ?? [];
  }

  public get visibleFields(): IField[] {
    return this.fieldsI.filter((item) => item.visible);
  }

  get fields(): IField[] {
    return JSON.parse(JSON.stringify(this.fieldsI));
  }

  set fields(fields: IField[]) {
    this.$emit("update:fieldsI", fields);
  }

  get dameTextoBotonFiltros(): string {
    let numFiltros = 0;
    Object.keys(this.filters).forEach((key: string) => {
      if ((this.filters as any)[key] != "" && (this.filters as any)[key] != null) {
        numFiltros = numFiltros + 1;
      }
    });

    return numFiltros
      ? `(${numFiltros}) ${this.$t("label.filtros")}`
      : this.$t("label.filtros").toString();
  }

  tiposDocumentos: IParametro[] = [];

  async updateTiposDocumentos(): Promise<void> {
    this.tiposDocumentos = [];
    if (this.getClienteSelected) {
      this.tiposDocumentos =
        (await this.$services.documentacion.fetchTiposDocumentosByPais(
          this.getClienteSelected?.pais?.id
        )) ?? [];
      this.params.documentType = this.tiposDocumentos?.find(
        (tipoDoc) => tipoDoc.codParametro == this.params.documentType
      )?.codParametro;

      this.filters.documentType = this.params.documentType;
    }
  }

  onChangeCurrentPage(currentPage: number): void {
    this.params.page = currentPage;
    this.getDocumentacion();
  }

  onSortingChange(sort: { sortBy: string; sortDesc: boolean }): void {
    this.$nextTick(async () => {
      this.params.direction = sort.sortDesc ? EDirection.DESC : EDirection.ASC;
      await this.getDocumentacion();
      await this.saveColumnsConfig();
    });
  }

  async saveColumnsConfig(): Promise<void> {
    const params: ISoConfColumnasUpdateDTO = {
      codColumna: this.visibleFields
        .filter((f) => !f.aux)
        .map((f) => f.code)
        .toString(),
      codTabla: this.codTable,
      codTipoOrden: this.params.direction,
      numPagElements: this.params.size,
      codOrden: this.params.orderBy,
    };

    await this.saveColumna(params);
  }

  async getDocumentacion(): Promise<void> {
    this.params.pkCliente = this.getClienteSelected?.id?.toString();

    this.$nextTick(() => {
      this.$router
        .replace({ path: this.$route.path, query: { ...this.params } as Dictionary<any> })
        .catch(() => {
          // Do nothing
        });
    });

    const params = JSON.parse(JSON.stringify(this.params)) as IDocumentacionNonHistoricalParams;
    params.page = params.page! - 1 ?? 0;

    this.documentacionResponse = await this.$services.documentacion.fetchDocumentos(params);
    this.documentacionResponseList = [];
    this.documentacionResponse?.content.forEach((documentacion) => {
      this.documentacionResponseList.push({
        id: documentacion.id,
        documentTitle: documentacion.documentTitle,
        documentType: documentacion.documentType,
        pais: documentacion.pais.descripcion,
        cliente: documentacion.cliente.desCliente,
        delegacion: documentacion.delegacion.desDelegacion,
        fechaDocumento: documentacion.fechaDocumento,
      });
    });
  }

  async download(doc: IDocumentoNonHistoricalTableItem): Promise<void> {
    const response = await this.$services.documentacion.downloadFileNonHistorical(doc);

    if (response) {
      this.downloadFile(response);
    } else {
      this.makeToast("danger", this.$t("label.documentation.download.error").toString());
    }
  }

  async remove(doc: IDocumentoNonHistoricalTableItem): Promise<void> {
    const { isConfirmed } = await this.modalConfirmDeleteDocument();
    if (isConfirmed) {
      await this.$services.documentacion.removeNonHistorical(doc);
      await this.getDocumentacion();
      this.makeToast("success", this.$t("document.removed").toString());
    }
  }

  action(action: { action: string; data?: any }): void {
    if (action.action === "download") {
      alert("download");
    } else if (action.action === "filtrar" || action.action === "clean") {
      this.params.page = 1;

      this.params.documentTitle = this.filters.documentTitle ?? undefined;
      this.params.documentType = this.filters.documentType ?? undefined;
      this.params.delegaciones = this.filters.delegaciones;
      this.params.fechaDocumentoInicio = this.getStartDate(this.filters.fechaDocumentoInicio);
      this.params.fechaDocumentoFin = this.getEndDate(this.filters.fechaDocumentoFin);

      this.mostrarFiltros = false;

      this.getDocumentacion();
    }
  }

  async modalConfirmDeleteDocument(): Promise<SweetAlertResult> {
    return this.$swal({
      title: this.$t("label.documentation.deletion").toString(),
      text: this.$t("label.documentation.deletion.confirmation.message").toString(),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: this.$t("label.yes").toString(),
      cancelButtonText: this.$t("label.no").toString(),
    });
  }

  get showDelete(): boolean {
    return this.usuarioLogado.nivel === ROLE_PAIS;
  }
}
