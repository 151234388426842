import { mockAuthService } from "@/services/auth/auth.mock";
import { IProviderMock } from "./provider.types";
import { mockColumnaService } from "@/services/common/columna/columna.mock";
import {
  mockClientesService,
  mockDivisasService,
  mockDocumentacionService,
  mockI18nService,
  mockMovimientosService,
  mockOfertasService,
  mockPaisService,
  mockSaldoService,
  mockUsuarioService,
  mockVideosService,
  mockCargaService,
} from "@/services";
import { mockTransferenciasService } from "@/services/saldosonline/transferencias";
import { mockDemandasService } from "@/services/saldosonline/demandas";
import { mockDisclaimerService } from "@/services/saldosonline/disclaimer";
import { mockReportesService } from "@/services/saldosonline/reportes";

export const mockProvider = (): IProviderMock => ({
  saldos: mockSaldoService(),
  clientes: mockClientesService(),
  divisas: mockDivisasService(),
  auth: mockAuthService(),
  movimientos: mockMovimientosService(),
  columna: mockColumnaService(),
  usuarios: mockUsuarioService(),
  pais: mockPaisService(),
  i18n: mockI18nService(),
  documentacion: mockDocumentacionService(),
  transferencias: mockTransferenciasService(),
  demandas: mockDemandasService(),
  ofertas: mockOfertasService(),
  videos: mockVideosService(),
  carga: mockCargaService(),
  disclaimer: mockDisclaimerService(),
  reporte: mockReportesService(),
});
