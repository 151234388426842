
import { Component, Prop, Vue } from "vue-property-decorator";
import PIcon from "@/components/look/PIcon/PIcon.vue";

@Component({
  name: "p-modal",
  components: { PIcon },
  inheritAttrs: false,
})
/**
 *
 */
export default class PModalOld extends Vue {
  @Prop({ required: true, default: "Title of modal" })
  title!: string;
  @Prop({ required: false, default: "md" })
  size!: string;
  @Prop({ required: false, default: "p-modal-content" })
  classModalContent!: string;
  @Prop({ required: false, default: true })
  hideFooter!: boolean;
  @Prop({ required: true })
  modalId!: string;
}
