import { ICliente, IClienteAll, IDenominacion, IDivisa } from "@/entities";
import { IPaged, IResponse } from "@/services";
import { AxiosResponse } from "axios";

export interface IClientesService {
  _fetchValidateCliente(
    params?: IClienteFilter
  ): Promise<AxiosResponse<IRespuestaBuscarEntidad> | undefined>;
  _fetchCliente(idCliente: number): Promise<ICliente | undefined>;
  _fetchClientes(params?: IClienteFilter): Promise<IClienteResponse | undefined>;
  _fetchClientesAll(params?: IClienteFilter): Promise<IClienteAll[] | undefined>;
  _fetchAllClienteXDivisa(idCliente: number): Promise<IDivisa[] | undefined>;
  _fetchAllClienteXDelegacion(idDelegacion: number): Promise<ICliente[] | undefined>;
  _fetchAllClienteXDelegacionAndDivisa(
    idDelegacion: number,
    idDivisa: number
  ): Promise<ICliente[] | undefined>;
  _fetchClientesByPais(idPais: number): Promise<ICliente[] | undefined>;
  _saveCliente(soClienteUpdateDTO: IClienteParams): Promise<ICliente | undefined>;
  _fetchDenominacionesCliente(
    params: IClienteDenominacionParams
  ): Promise<IDenominacion[] | undefined>;
}

export interface IRespuestaBuscarEntidad {
  entidad: IEntidad;
  exito: boolean;
}

export interface IEntidad {
  idEntidad: string;
  codPais: string;
  codEntidad: string;
  codAjeno?: string;
  desNobreCorto: string;
  desNombre: string;
  codTipoActividad?: string;
  desTipoActividad?: string;
  codRamoActividad?: string;
  desRamoActividad?: string;
  codEmpresaGlobal?: string;
  desEmpresaGlobal?: string;
  codEmpresaLocal?: string;
  desEpresaLocal?: string;
}

export interface IClienteResponse extends IResponse<ICliente> {}

export interface IClienteTableContent {
  items: ICliente[];
  orderBy?: string;
  sortDesc: boolean;
  codPais: string;
  idPais: number;
  pagination: IPaged;
  collapsed: boolean;
  totalElements: number;
}

export interface IClienteFilter extends IPaged {
  [key: string]: any;
  id?: number | null;
  cliente?: string;
  fecCrearInicio?: string;
  fecCrearFin?: string;
  active?: boolean;
  pais?: number;
  fakeCerts?: boolean;
  delegaciones?: number[];
  fechaBaja?: string;
  fechaModificacion?: string;
  fechaCreacion?: string;
  paises?: number[];
}
export const iniClienteParams: IClienteParams = {
  id: 0,
  codCliente: "",
  desCliente: "",
  delegaciones: [],
  pais: 0,
  active: true,
  fakeCerts: false,
};

export interface IClienteDenominacionParams {
  idCliente: number;
  idDivisa: number;
}

export interface IClienteParams {
  id?: number | null;
  codCliente: string;
  desCliente: string;
  delegaciones: number[];
  pais: number;
  active: boolean;
  fakeCerts: boolean;
}

export interface IClientesServiceMock {
  _fetchValidateCliente: jest.Mock<Promise<AxiosResponse<IRespuestaBuscarEntidad> | undefined>>;
  _fetchCliente: jest.Mock<Promise<ICliente | undefined>>;
  _fetchClientes: jest.Mock<Promise<IClienteResponse | undefined>>;
  _fetchClientesAll: jest.Mock<Promise<IClienteAll[] | undefined>>;
  _fetchAllClienteXDivisa: jest.Mock<Promise<IDivisa[] | undefined>>;
  _fetchAllClienteXDelegacion: jest.Mock<Promise<ICliente[] | undefined>>;
  _fetchAllClienteXDelegacionAndDivisa: jest.Mock<Promise<ICliente[] | undefined>>;
  _saveCliente: jest.Mock<Promise<ICliente | undefined>>;
  _fetchDenominacionesCliente: jest.Mock<Promise<IDenominacion[] | undefined>>;
  _fetchClientesByPais: jest.Mock<Promise<ICliente[] | undefined>>;
}
