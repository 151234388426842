export interface IResponseError {
  timestamp: string;
  status: number;
  error: string;
  message: string;
  path: string;
}
export enum VARIANT {
  primary = "primary",
  secondary = "secondary",
  danger = "danger",
  warning = "warning",
  success = "success",
  info = "info",
}

export interface KeyValuePair {
  key: string;
  value: any;
}
