
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export class PCol extends Vue {
  @Prop({ required: false }) col!: string;
  @Prop({ required: false }) xs!: string;
  @Prop({ required: false }) sm!: string;
  @Prop({ required: false }) md!: string;
  @Prop({ required: false }) lg!: string;
  @Prop({ required: false }) xl!: string;
  @Prop({ required: false }) xxl!: string;
  get giveMeClass(): string {
    let classString = "divPCol divPColPadding";

    if (this.col) {
      classString += ` divPCol_${this.col}`;
    }
    if (this.sm) {
      classString += ` divPCol_sm_${this.sm}`;
    }
    if (this.md) {
      classString += ` divPCol_md_${this.md}`;
    }
    if (this.lg) {
      classString += ` divPCol_lg_${this.lg}`;
    }
    if (this.xl) {
      classString += ` divPCol_xl_${this.xl}`;
    }
    if (this.xxl) {
      classString += ` divPCol_xxl_${this.xxl}`;
    }

    return classString;
  }
}
export default PCol;
