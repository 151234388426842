import Vue from "vue";
import { configure, extend, ValidationProvider } from "vee-validate";
import {
  alpha_spaces,
  between,
  confirmed,
  email,
  integer,
  max,
  min,
  min_value,
  max_value,
  regex,
  required,
  length,
  numeric,
} from "vee-validate/dist/rules";
import { i18n } from "@/i18n";

configure({
  defaultMessage: (field, values): string =>
    i18n.t(`validation.messages.${values?._rule_}`, values) as string,
});

extend("required", required);
extend("email", email);
extend("min", min);
extend("max", max);
extend("min_value", min_value);
extend("max_value", max_value);
extend("between", between);
extend("confirmed", confirmed);
extend("alpha_spaces", alpha_spaces);
extend("regex", regex);
extend("integer", integer);
extend("length", length);
extend("numeric", numeric);

extend("phone", {
  validate: (value) => {
    const patt = new RegExp(/^[+]{0,1}[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g);
    return patt.test(value);
  },
  message: () => i18n.t("errorValidator.phone") as string,
});

extend("valid-denomination", {
  validate: (value, args) => {
    return (Number(value) * 100) % (Number(args) * 100) === 0;
  },
  message: () => i18n.t("validation.messages.denomination") as string,
});

extend("cuenta_corriente_pattern", {
  validate: async (value) => {
    return evaluateRegex(/^[A-Z0-9-]{1,14}$/, value);
  },
  message: () => i18n.t("validation.messages.cuenta_corriente_pattern") as string,
});

extend("ag_numero_sucursal_pattern", {
  validate: async (value) => {
    return evaluateRegex(/^[A-Z0-9-]{1,11}$/, value);
  },
  message: () => i18n.t("validation.messages.ag_numero_sucursal_pattern") as string,
});

extend("cnpj_persona_pattern", {
  validate: async (value) => {
    return evaluateRegex(/^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/, value);
  },
  message: () => i18n.t("validation.messages.cnpj_persona_pattern") as string,
});

function evaluateRegex(pattern: RegExp | string, value: string): boolean {
  const patternEvaluated = new RegExp(pattern);
  return patternEvaluated.test(value);
}

Vue.component("ValidationProvider", ValidationProvider);
