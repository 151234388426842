
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PSidebar from "@/components/look/PSidebar/PSidebar.vue";
import { Getter } from "vuex-class";
import { ICliente, IDelegacion, IParametro } from "@/entities";
import { Locale } from "@/store";
import VueMethods from "@/vue-methods";
import { IDocumentacionNonHistoricalFilters } from "@/services";

@Component({
  components: { PSidebar },
  mixins: [VueMethods],
})
export default class DocumentacionNonHistoricalTableFilters extends Vue {
  @Prop({ required: true })
  tiposDocumentos!: IParametro[];
  @Prop({ required: false })
  textActionName!: string;
  @Prop({ required: false })
  actionName!: string;
  @Prop({ required: false })
  maxRangeMonth?: number;

  @Getter("getLocale") locale!: Locale;
  @Getter("getClienteSelected") clienteSelected!: ICliente;

  @Prop({ required: true }) value!: any;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.valueLocal = JSON.parse(JSON.stringify(this.value)) as IDocumentacionNonHistoricalFilters;

    this.$set(
      this.valueLocal,
      "fechaDocumentoInicio",
      this.getStartDate(this.valueLocal.fechaDocumentoInicio)
    );
    this.$set(
      this.valueLocal,
      "fechaDocumentoFin",
      this.getEndDate(this.valueLocal.fechaDocumentoFin)
    );
  }
  validado = false;
  valueLocal: IDocumentacionNonHistoricalFilters = {
    delegaciones: [],
  };
  initialValueLocal: IDocumentacionNonHistoricalFilters = {
    delegaciones: [],
  };

  get delegations(): IDelegacion[] {
    let delegationsList: IDelegacion[] = [];
    this.clienteSelected?.clientexdels.forEach((clientexdel) => {
      if (clientexdel.active) delegationsList.push(clientexdel.delegacion);
    });
    return delegationsList.sort((a, b) => this.sortAscending(a, b, "desDelegacion"));
  }

  get aTipoDocumentoOption(): IParametro[] {
    const tipos = JSON.parse(JSON.stringify(this.tiposDocumentos)) as IParametro[];
    return tipos
      .map((tipoDocumento) => {
        tipoDocumento.desParametro = this.$t(
          `documento.tipo.${tipoDocumento.desParametro?.toLowerCase()}`
        ).toString();
        return tipoDocumento;
      })
      .sort((a, b) => this.sortAscending(a, b, "desParametro"));
  }

  limpiar(): void {
    this.$set(this.valueLocal, "fechaDocumentoInicio", undefined);
    this.$set(this.valueLocal, "fechaDocumentoFin", undefined);
    this.valueLocal.documentTitle = undefined;
    this.valueLocal.documentType = undefined;
    this.valueLocal.delegaciones = [];

    if (!this.isInitialObject(this.value, this.initialValueLocal)) {
      this.$emit("input", this.valueLocal);
      this.$emit("cancel", { action: "clean" });
    }
  }
  action(action: string): void {
    this.$emit("input", this.valueLocal);
    this.$emit("action", { action: action });
  }
}
