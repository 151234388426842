export interface ISort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export const mockSort = (): ISort => ({
  empty: false,
  sorted: true,
  unsorted: false,
});
