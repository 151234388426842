
import { Component, Vue } from "vue-property-decorator";
import ResumenDemanda from "./ResumenDemanda.vue";
import {
  ICalidadDenominacion,
  ICliente,
  IDelegacion,
  IDenominacion,
  IDetallesSaldo,
  IDivisa,
  IParametro,
  ISaldo,
} from "@/entities";
import Multiselect from "vue-multiselect";
import { Action, Getter } from "vuex-class";
import {
  IDemandaParams,
  IDemandaPaso4Params,
  IDetalleDemandaParams,
  ISaldoActualParams,
} from "@/services";
import { EventBus } from "@/event-bus";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import AsyncComputed from "vue-async-computed-decorator";
import VueMethods from "@/vue-methods";
import VueFilters from "@/vue-filters";

@Component({
  name: "demandar-form",
  mixins: [VueMethods, VueFilters],
  components: {
    ResumenDemanda,
    Multiselect,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class DemandarForm extends Vue {
  @Action("fetchSaldoActual") fetchSaldoActual!: (
    params?: ISaldoActualParams
  ) => Promise<ISaldo | undefined>;
  @Getter("getClienteSelected") getClienteSelected!: ICliente;
  @Getter("getClientesAll") getClientesAll!: ICliente[];
  @Getter("getCostes") getCostes!: IParametro[];

  $refs!: {
    formDemandar: InstanceType<typeof ValidationObserver>;
  };

  public phase1 = true;
  public phase2 = false;
  public phase3 = false;
  public phase4 = false;
  public phase5 = false;

  public clienteSelected: ICliente | null = null;
  public delegationSelected: IDelegacion | null = null;
  public divisaSelected: IDivisa | null = null;

  public saldoActual: ISaldo | null = null;
  public denominaciones: IDenominacion[] | undefined = [];
  public calidadesDenominacionSelected: ICalidadDenominacion[] | undefined = [];
  public detallesSaldo: IDetallesSaldo[] | undefined = [];
  public saldoDisponible: number | undefined = 0;

  public costeSelected: IParametro | null = null;
  public fechaExpiracion: string | null = null;
  public horaExpiracion: string | null = null;
  public anonimo = false;
  public parcial = false;
  public tasa: number | undefined = 0;
  isShowBankField = false;

  delegationsOptional: number[] = [];

  demandaPaso4Params: IDemandaPaso4Params = {
    relleno: false,
    cuentaCorriente: "",
    agNumeroSucursal: "",
    ispbBanco: "",
    cnpjPersona: "",
    observacionesDemanda: "",
  };

  get delegationsOptionalFiltered(): IDelegacion[] {
    return this.delegations.filter((del) => del.id != this.delegationSelected?.id);
  }

  get delegationsOptionalSelected(): number[] {
    return this.delegationsOptional.filter((id) => id != this.delegationSelected?.id);
  }

  get divisas(): IDivisa[] {
    return this.clienteSelected?.divisas ?? [];
  }

  async mounted(): Promise<void> {
    extend("date_future", {
      validate: async () => {
        if (this.fechaExpiracion && this.horaExpiracion) {
          return (
            new Date(
              Number(this.fechaExpiracion.split("-")[0]),
              Number(this.fechaExpiracion.split("-")[1]) - 1,
              Number(this.fechaExpiracion.split("-")[2]),
              Number(this.horaExpiracion.split(":")[0]),
              Number(this.horaExpiracion.split(":")[1])
            ) > new Date()
          );
        }
        return false;
      },
      message: () => this.$t("validation.messages.date_must_future") as string,
    });

    this.clienteSelected = this.getClienteSelected;
  }

  get delegations(): IDelegacion[] {
    const d: IDelegacion[] = [];

    if (this.clienteSelected?.clientexdels) {
      this.clienteSelected?.clientexdels?.forEach((clientexdel) => {
        if (clientexdel?.delegacion && clientexdel.active) {
          d.push(clientexdel.delegacion);
        }
      });
      return d.sort((a, b) => this.sortAscending(a, b, "desDelegacion"));
    }
    return [];
  }

  async clienteSelectedInput(): Promise<void> {
    let aux = await this.$services.clientes._fetchCliente(Number(this.clienteSelected?.id));
    if (aux) {
      this.clienteSelected = aux;
      this.isShowBankField = aux.pais.hasInfoBancaria;
    }
    this.delegationSelected = null;
    this.divisaSelected = null;
  }

  get calidadesDenominacion(): ICalidadDenominacion[] {
    const calidades: ICalidadDenominacion[] = [];
    const denominaciones = Object.assign([], this.divisaSelected?.denominaciones);
    denominaciones?.sort((a, b) => this.sortDescending(a, b, "numValue"));
    denominaciones?.forEach((denominacion: IDenominacion) => {
      denominacion.calidades?.forEach((calidad) => {
        if (calidad) {
          this.$set(calidad, "importe", 0);
          this.$set(calidad, "importeSelected", false);
          this.$set(calidad, "cantidad", 0);
          this.$set(calidad, "cantidadSelected", false);
          calidades.push(calidad);
        }
      });
    });
    return calidades;
  }

  async searchSaldosActuales(): Promise<void> {
    if (this.clienteSelected && this.delegationSelected && this.divisaSelected) {
      const res = await this.fetchSaldoActual({
        pkCliente: this.clienteSelected.id!,
        pkDelegacion: this.delegationSelected.id,
        pkDivisa: this.divisaSelected.id,
      });
      if (res) {
        this.saldoActual = res;
        if (this.saldoActual) {
          this.saldoDisponible = this.saldoActual.saldoActual;
          this.detallesSaldo = this.saldoActual.detallesSaldo;
          this.denominaciones = this.saldoActual.divisa.denominaciones;
          this.denominaciones.forEach((denominacion) => {
            denominacion.calidades.forEach((calidad) => {
              if (calidad) {
                this.$set(calidad, "importe", 0);
                this.calidadesDenominacion?.push(calidad);
              }
            });
          });
        }
      } else {
        EventBus.$emit("p-toast", { variant: "danger", message: res });
      }
    }
  }

  fechaDefecto(): void {
    let currentDate = new Date();
    const currentDateIn17Hours = new Date();
    currentDateIn17Hours.setHours(17);
    currentDateIn17Hours.setMinutes(0);

    if (currentDate.getTime() > currentDateIn17Hours.getTime()) {
      currentDate.setDate(currentDate.getDate() + 1);
    }

    this.fechaExpiracion = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}`;

    this.horaExpiracion = "17:00:00";
  }

  customLabelDenominacion(denominacion: ICalidadDenominacion): string {
    return denominacion?.denominacion?.codDenominacion + " " + denominacion?.desCalidad;
  }

  get saldoDemandar(): number {
    let sumaImportes = 0;
    this.calidadesDenominacionSelected?.forEach((calidadDenominacion) => {
      if (calidadDenominacion.importe) {
        sumaImportes = Number(sumaImportes) + Number(calidadDenominacion.importe);
      }
    });

    return sumaImportes;
  }

  get contieneImporte(): boolean {
    return !this.calidadesDenominacionSelected?.some((calidad) => calidad.importe == 0);
  }

  @AsyncComputed()
  async continuarCondition(): Promise<boolean> {
    if (this.phase1) {
      return false;
    } else if (this.phase2) {
      if (
        this.saldoDemandar > 0 &&
        this.contieneImporte &&
        this.calidadesDenominacionSelected &&
        this.calidadesDenominacionSelected?.length > 0 &&
        (await this.$refs.formDemandar.validate())
      ) {
        return false;
      }
    } else if (this.phase3) {
      if (
        this.fechaExpiracion &&
        this.horaExpiracion &&
        this.costeSelected &&
        (await this.$refs.formDemandar.validate({ silent: true }))
      ) {
        return false;
      }
    } else if (this.phase4) {
      if (await this.$refs.formDemandar.validate({ silent: true })) {
        return false;
      }
    }

    return true;
  }

  atras(): void {
    if (this.phase2) {
      this.phase1 = true;
      this.phase2 = false;
      this.calidadesDenominacionSelected = [];
    } else if (this.phase3) {
      this.phase2 = true;
      this.phase3 = false;
      this.fechaExpiracion = null;
      this.horaExpiracion = null;
      this.costeSelected = null;
      this.anonimo = false;
      this.parcial = false;
    } else if (this.phase4) {
      this.phase3 = true;
      this.phase4 = false;
      this.limpiarDemandaPaso4();
    } else if (this.phase5) {
      this.phase4 = true;
      this.phase5 = false;
    } else if (!this.phase1 && !this.phase2 && !this.phase3 && !this.phase4 && !this.phase5) {
      this.phase5 = true;
    }
  }

  continuar(): void {
    if (this.phase1) {
      if (this.clienteSelected && this.delegationSelected && this.divisaSelected) {
        this.phase1 = false;
        this.phase2 = true;
        if (!this.clienteSelected?.fakeCerts) {
          this.searchSaldosActuales();
        }
      }
    } else if (this.phase2) {
      this.phase2 = false;
      this.phase3 = true;
      this.fechaDefecto();
    } else if (this.phase3) {
      this.phase3 = false;
      this.phase4 = true;
      this.demandaPaso4Params.relleno = true;
    } else if (this.phase4) {
      this.phase4 = false;
      this.phase5 = true;
    }
  }

  cancelar(): void {
    this.phase5 = false;
    this.phase4 = true;
  }

  infoDemandar(): void {
    this.phase1 = false;
    this.phase2 = false;
    this.phase3 = false;
    this.phase4 = false;
    this.phase5 = false;
  }

  limpiarDemandaPaso4(): void {
    this.demandaPaso4Params.cuentaCorriente = "";
    this.demandaPaso4Params.agNumeroSucursal = "";
    this.demandaPaso4Params.ispbBanco = "";
    this.demandaPaso4Params.cnpjPersona = "";
    this.demandaPaso4Params.observacionesDemanda = "";
    this.demandaPaso4Params.relleno = false;
  }

  async demandar(): Promise<void> {
    const detallesDemanda: IDetalleDemandaParams[] = [];
    this.calidadesDenominacionSelected?.forEach((calidadDenominacion) => {
      if (calidadDenominacion.importe > 0) {
        detallesDemanda.push({
          numCantidad: calidadDenominacion.importe,
          calidadDenominacion: calidadDenominacion,
        });
      }
    });
    if (
      this.clienteSelected &&
      this.delegationSelected &&
      this.divisaSelected &&
      this.costeSelected
    ) {
      const demandaParams: IDemandaParams = {
        numCantidad: this.saldoDemandar,
        fecExpirar: new Date(this.fechaExpiracion + " " + this.horaExpiracion),
        checkAnonimo: this.anonimo,
        checkParcial: this.parcial,
        coste: this.costeSelected.id,
        pkDivisa: this.divisaSelected.id,
        pkCliente: this.clienteSelected.id!,
        pkDelegacion: this.delegationSelected.id,
        detallesDemanda: detallesDemanda,
        numTasa: this.clienteSelected.pais.hasTasa ? Number(this.tasa) : undefined,
        pkDelegaciones: this.delegationsOptionalSelected,
        observacionesDemanda: this.demandaPaso4Params.observacionesDemanda,
        cuentaCorriente: this.demandaPaso4Params.cuentaCorriente || undefined,
        agNumeroSucursal: this.demandaPaso4Params.agNumeroSucursal || undefined,
        ispbBanco: this.demandaPaso4Params.ispbBanco || undefined,
        cnpjPersona: this.demandaPaso4Params.cnpjPersona || undefined,
      };

      this.$emit("action", { action: "demandarConfirm", data: demandaParams });
    }
  }

  get titleLeftToDelegacionesOpcionales(): string {
    const textLeft = this.delegationSelected?.desDelegacion ?? "";
    const title = this.$t("label.delegaciones.opcionales.form").toString().replace("X", textLeft);
    return this.$t(title).toString();
  }
}
