
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export class PFormInputMultipleFile extends Vue {
  @Prop({ required: true }) value!: File[];
  @Prop({ required: false }) accept!: string;
  @Prop({ required: false, default: "" }) label!: string;
  @Prop({ required: false, default: "Seleccionar ficheros" }) placeholder!: string;
  @Prop({ required: false, default: undefined }) rules!: string;
  @Prop({ required: false, default: "" }) invalid_feedback!: string;
  files: any = "";
  fileInputValidation = "";
  click(): void {
    (this.$refs.file as any).click();
  }
  handleFileUpload(): void {
    this.files = (this.$refs.file as any).files;
    this.fileInputValidation = this.files[0].name;
    this.$emit("input", this.files);
    this.$nextTick(() => {
      //esto es para que funcione la validacion
      this.$emit("change", this.files);
    });
  }
  get textUpload(): string {
    const cantidadFiles = this.files.length;
    if (cantidadFiles <= 1) {
      return this.files[0]?.name || this.placeholder;
    }

    return `${this.files.length} archivos`;
  }
}
export default PFormInputMultipleFile;
