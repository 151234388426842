
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "icon-lower-than",
  components: {},
})
export class IconLowerThan extends Vue {
  @Prop({ default: 26 }) public size!: number;
  @Prop({ default: "#ffffff" }) public color!: string;

  constructor() {
    super();
  }
}
export default IconLowerThan;
