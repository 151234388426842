
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Loading from "vue-loading-overlay";
import { EventBus } from "./event-bus";
import { Locale } from "./store";

import "vue-loading-overlay/dist/vue-loading.css";
import PToast from "@/components/common/PToast.vue";
import { PButton } from "@/components/look";
import { IUsuario } from "@/entities";
import AsyncComputed from "vue-async-computed-decorator";
import { defineRulesFor } from "@/services";

@Component({
  name: "app",
  components: {
    PToast,
    Loading,
    PButton,
  },
})
export default class App extends Vue {
  @Getter("getSignedIn") getSignedIn!: boolean;
  @Getter("getLoggedUser") getLoggedUser!: IUsuario;
  @Action("setCurrentLocale") setCurrentLocale!: (locale: string) => void;

  accepted: boolean = localStorage.getItem("acceptCookies") === "true";
  loadings = 0;

  constructor() {
    super();
  }

  get isLoading(): boolean {
    return this.loadings > 0;
  }

  async created(): Promise<void> {
    this.setCurrentLocale(this.$i18n.locale);

    Vue.$axios.interceptors.request.use(
      (config) => {
        this.loadings++;
        return config;
      },
      (error) => {
        this.loadings--;
        return Promise.reject(error);
      }
    );

    Vue.$axios.interceptors.response.use(
      (response) => {
        this.loadings--;
        return response;
      },
      (error) => {
        this.loadings--;
        return Promise.reject(error);
      }
    );
  }

  mounted(): void {
    EventBus.$on("language-change", (newLocale: Locale) => {
      {
        this.$i18n.locale = newLocale;
        this.setCurrentLocale(newLocale);
        document.cookie = `LOCALE=${newLocale}; path=/`;
        const html = document.documentElement;
        html.setAttribute("lang", newLocale);
      }
    });
  }

  acceptCookies(): void {
    localStorage.setItem("acceptCookies", "true");
    this.accepted = true;
  }

  @AsyncComputed()
  async updateAbilitys(): Promise<void> {
    this.$ability.update(defineRulesFor(this.getLoggedUser));
  }
}
