
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IDelegacion, IPais } from "@/entities";
import { IClienteParams, iniClienteParams, IRespuestaBuscarEntidad } from "@/services";
import { Getter } from "vuex-class";
import { AxiosResponse } from "axios";

@Component({
  components: {},
})
export class ClienteForm extends Vue {
  @Prop({ required: true }) value!: IClienteParams;

  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }

  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;
  @Getter("getPaises") getPaises!: IPais[];
  valueLocal: IClienteParams = iniClienteParams;
  optionsPaises: IPais[] = [];
  optionsDelegaciones: IDelegacion[] = [];
  validado = false;

  async mounted(): Promise<void> {
    this.optionsPaises = this.getPaises;
    this.fetchDelegaciones();
  }

  fetchDelegaciones(): void {
    this.$services.pais._fetchDelegaciones(this.valueLocal.pais).then((response) => {
      if (response) {
        this.optionsDelegaciones = response.content;
      }
    });
  }

  validarCliente(): void {
    this.$services.clientes
      ._fetchValidateCliente({
        idPais: this.valueLocal.pais,
        codCliente: this.valueLocal.codCliente,
      })
      .then((response?: AxiosResponse<IRespuestaBuscarEntidad>) => {
        if (response?.status === 208) {
          this.$swal({
            icon: "info",
            title: this.$i18n.t("administracion.cliente.yaExiste.title") as string,
            html: this.$i18n.t("administracion.cliente.yaExiste.message") as string,
          });
        } else if (response?.data?.exito) {
          this.valueLocal.desCliente = response.data.entidad.desNombre;
        } else {
          this.$swal({
            icon: "warning",
            title: this.$i18n.t("administracion.cliente.noEncontrado.title") as string,
            html: this.$i18n.t("administracion.cliente.noEncontrado.message") as string,
          });
        }
      });
  }

  cancel(): void {
    this.$emit("cancel");
  }

  action(action: string): void {
    this.$emit("action", { data: this.valueLocal, action: action });
  }
}

export default ClienteForm;
