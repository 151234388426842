
import { Component, Prop, Vue } from "vue-property-decorator";

import VueApexCharts from "vue-apexcharts";
import RadialBar from "@/components/common/widgets/RadialBar.vue";

Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
@Component({
  components: { RadialBar },
})
export default class OfertasDemandas extends Vue {
  @Prop({ required: false, default: "ofertas y demandas" })
  title!: string;
  data: {
    servicePoint: string;
    currency: string;
    ofertas: { ofertado: number; sinTransferir: number };
    demandas: { ofertado: number; sinDemandar: number };
  } = {
    servicePoint: "mar de plata",
    currency: "EUR",
    ofertas: { ofertado: 350812.38, sinTransferir: 350812.38 },
    demandas: { ofertado: 350812.38, sinDemandar: 350812.38 },
  };
}
