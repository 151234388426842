
import { Component, Prop, Vue } from "vue-property-decorator";
import { EDirection, IColumnaResponse, IPaged } from "@/services";
import { IDivisa, IField } from "@/entities";
import SelectColumns from "@/components/common/SelectColumns/SelectColumns.vue";
import VueFilters from "@/vue-filters";
import { IOfertaDemanda } from "@/entities/centros-efectivo-backend/ofertaDemanda.types";
import Pagination from "@/components/common/Pagination.vue";

@Component({
  components: {
    Pagination,
    SelectColumns,
  },
  mixins: [VueFilters],
})
export class TransferenciasPublicasTable extends Vue {
  @Prop({ required: false, default: 0 }) totalElements!: number;
  @Prop({ required: true }) value!: IPaged;
  @Prop({ required: true }) transferencias!: IOfertaDemanda[];
  @Prop({ required: true }) divisa!: IDivisa;
  @Prop({ required: true }) tipo!: "OFERTA" | "DEMANDA";
  @Prop({ required: true }) cantidadDisponible!: number;
  @Prop({ required: true }) columnsConfig!: IColumnaResponse;

  public sortDesc = false;
  public pagination: IPaged = {
    direction: EDirection.ASC,
    orderBy: "fecCrear",
    totalElements: 0,
    page: 1,
    size: 10,
  };

  onSortingChange(): void {
    this.$nextTick(async () => {
      this.pagination.direction = this.sortDesc ? EDirection.DESC : EDirection.ASC;
      this.$emit("input", this.pagination);
      this.$emit("change", {
        action: "changeTable",
        data: { tipo: this.tipo, divisa: this.divisa },
      });
    });
  }
  isVisible(visibleKeys: string[], column: string): boolean {
    return !visibleKeys.length || !!visibleKeys.find((key) => key === column);
  }

  demandarSobreOferta(oferta: IOfertaDemanda, divisa: IDivisa): void {
    this.$emit("demandarSobreOferta", {
      action: "demandarSobreOferta",
      data: { oferta: oferta, divisa: divisa },
    });
  }
  ofertarSobreDemanda(demanda: IOfertaDemanda, divisa: IDivisa): void {
    this.$emit("ofertarSobreDemanda", {
      action: "ofertarSobreDemanda",
      data: { demanda: demanda, divisa: divisa },
    });
  }
  get fields(): IField[] {
    const visibleKeys = this.columnsConfig?.codColumna?.split(",").filter((s) => s !== "") || [];
    const columns = [
      {
        key: "actions-left",
        label: this.$t("label.acciones") as string,
        sortable: false,
        thClass: "table-header table-header-active pl-4",
        tdClass: "table-cell-first actions",
        visible: true,
        class: "table-cell",
        aux: true,
      },

      {
        key: "clientexdel.cliente.desCliente",
        label: this.$t("label.cliente") as string,
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: this.isVisible(visibleKeys, "clientexdel.cliente.desCliente"),
        class: "table-cell",
        aux: false,
      },
      {
        key: "clientexdel.delegacion.desDelegacion",
        label: this.$t("label.delegacion") as string,
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: this.isVisible(visibleKeys, "clientexdel.delegacion.desDelegacion"),
        class: "table-cell",
        aux: false,
      },

      {
        key: "cantidadDisponible",
        label: this.$t("label.cantidadDisponible") as string,
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: this.isVisible(visibleKeys, "cantidadDisponible"),
        class: "table-cell",
        formatter: (value: number): string => this.filterNumber(value, 2),
        aux: false,
      },
      {
        key: "fecExpirar",
        label: this.$t("label.expiracion") as string,
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: this.isVisible(visibleKeys, "fecExpirar"),
        class: "table-cell",
        formatter: (value: string): string =>
          this.filterDate(value) + " - " + this.filterTime(value),
        aux: false,
      },
      {
        key: "coste",
        label: this.$t("label.coste") as string,
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: this.isVisible(visibleKeys, "coste"),
        class: "table-cell",
        aux: false,
      },
      {
        key: "numTasa",
        label: this.$t("label.tasa") as string,
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: this.isVisible(visibleKeys, "numTasa") && this.hasNumTasa(),
        class: "table-cell",
        formatter: (value: number): string => value.toString() + " %",
        aux: false,
      },
      {
        key: "checkParcial",
        label: this.$t("label.parcial") as string,
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: this.isVisible(visibleKeys, "checkParcial"),
        class: "table-cell",
        aux: false,
      },
      {
        key: "add-columns",
        label: "",
        sortable: false,
        thClass: "table-header table-header-add-columnas",
        tdClass: "table-cell table-add-columns",
        visible: true,
        aux: true,
      },
    ];
    if (this.tipo === "OFERTA") {
      return columns.filter(function (value) {
        return value.key != "checkParcial" && value.key != "cantidadDemandada";
      });
    }
    return columns;
  }

  set fields(fields: IField[]) {
    this.columnsConfig.codColumna =
      fields
        .filter((item) => item.visible)
        .map((item) => item.key)
        .join(",") || "";
  }

  private get visibleFields(): IField[] {
    return this.fields.filter((item) => item.visible);
  }

  hasNumTasa(): boolean {
    const hasNumTasaList: IOfertaDemanda[] = [];
    this.transferencias.forEach((transferencia) => {
      if (transferencia.numTasa != null) {
        hasNumTasaList.push(transferencia);
      }
    });
    return hasNumTasaList.length > 0;
  }

  onChangeCurrentPage(currentPage: number): void {
    this.pagination.page = currentPage;
    this.$emit("input", this.pagination);
    this.$emit("change", { action: "changeTable", data: { tipo: this.tipo, divisa: this.divisa } });
  }
}
export default TransferenciasPublicasTable;
