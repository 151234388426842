import { IMutationsMock } from "@/store";

export const mockMutations = (): IMutationsMock => ({
  SET_CURRENT_LOCALE: jest.fn(),
  SET_CURRENT_LOCATION: jest.fn(),
  SET_SIGNED_IN: jest.fn(),
  SET_LOGGED_USER: jest.fn(),
  SET_SALDOS_PAGE_QUICK_FILTER: jest.fn(),
  SET_CLIENTES: jest.fn(),
  SET_CLIENTESALL: jest.fn(),
  SET_CLIENTE_X_DIVISA: jest.fn(),
  SET_COUNTRY_CONFIG: jest.fn(),
  SET_DELEGACION_X_CLIENTE: jest.fn(),
  SET_DIVISAS: jest.fn(),
  SET_COLUMNAS: jest.fn(),
  SET_CLIENTE_SELECTED: jest.fn(),
  SET_TRANSFERENCIAS_PAGE_QUICK_FILTER: jest.fn(),
  SET_COSTES: jest.fn(),
  SET_PAISES: jest.fn(),
  SET_DISCLAIMER: jest.fn(),
});
