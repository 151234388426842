
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export class PProgress extends Vue {
  @Prop({ required: true }) totalSize!: number;
  @Prop({ required: true }) progressSize!: number;
  get porcentage(): number {
    if (this.totalSize && this.totalSize > 0 && this.progressSize > 0) {
      const porcent = (this.progressSize * 100) / this.totalSize;
      return porcent >= 100 ? 100 : Math.trunc(porcent);
    } else {
      return 0;
    }
  }
}
export default PProgress;
