
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IOptionBarItem } from "@/components/look";

@Component
export class POptionBarNew extends Vue {
  @Prop({ required: true }) value!: string;
  @Watch("value", { immediate: true })
  onChangeValue(): void {
    this.valueLocal = this.value;
  }
  @Prop({ required: true }) options!: IOptionBarItem[];

  valueLocal = "";

  get optionsComp(): IOptionBarItem[] {
    return this.options.filter((option) => !option.hidden);
  }

  giveMeClass(value: string): string {
    if (value === this.valueLocal) {
      return "active";
    } else {
      return "";
    }
  }
  clickOption(value: string): void {
    this.$emit("input", value);
    this.$emit("change", value);
  }
}
export default POptionBarNew;
