import { APPLICATION_JSON } from "@/constans";
import { EventBus } from "@/event-bus";
import Vue from "vue";
import { handleResponse } from "../../handlers";
import { BACKEND_BASE_URL } from "../backend.types";
import {
  IClienteDenominacionParams,
  IClienteFilter,
  IClienteParams,
  IClienteResponse,
  IClientesService,
  IRespuestaBuscarEntidad,
} from "./clientes.types";
import { IResponseError } from "@/entities/common/common.types";
import { IDivisaResponse, serialize } from "@/services";
import { ICliente, IClienteAll, IDenominacion, IDivisa } from "@/entities";
import { AxiosResponse } from "axios";

export class ClientesService implements IClientesService {
  async _fetchValidateCliente(
    params?: IClienteFilter
  ): Promise<AxiosResponse<IRespuestaBuscarEntidad> | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IRespuestaBuscarEntidad, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/cliente/validate`, { headers, params })
    );

    if (error) {
      return undefined;
    } else {
      return response;
    }
  }
  async _fetchCliente(idCliente?: number): Promise<ICliente | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<ICliente, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/cliente/${idCliente}`, {
        headers,
      })
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-clientes-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _fetchClientes(params?: IClienteFilter): Promise<IClienteResponse | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IClienteResponse, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/cliente`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-clientes-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _fetchClientesAll(params?: IClienteFilter): Promise<IClienteAll[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IClienteAll[], IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/cliente/all`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-clientes-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _fetchAllClienteXDivisa(idCliente: number): Promise<IDivisa[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    if (idCliente === -1) {
      const { response } = await handleResponse<IDivisaResponse, IResponseError>(
        Vue.$axios.get(`${BACKEND_BASE_URL}/api/divisa`, { headers })
      );
      return response!.data.content;
    }
    const { response, error } = await handleResponse<IDivisa[], IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/cliente/${idCliente}/divisa`, { headers })
    );
    if (error) {
      EventBus.$emit("on-service-saldosonline-clientes-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _saveCliente(clienteParams: IClienteParams): Promise<ICliente | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    if (clienteParams.id) {
      const { response, error } = await handleResponse<ICliente, IResponseError>(
        Vue.$axios.put(`${BACKEND_BASE_URL}/api/cliente`, clienteParams, { headers })
      );

      if (error) {
        EventBus.$emit("on-service-saldosonline-clientes-error", error.data);
        return undefined;
      } else {
        return response!.data;
      }
    } else {
      const { response, error } = await handleResponse<ICliente, IResponseError>(
        Vue.$axios.post(`${BACKEND_BASE_URL}/api/cliente`, clienteParams, { headers })
      );

      if (error) {
        EventBus.$emit("on-service-saldosonline-clientes-error", error.data);
        return undefined;
      } else {
        return response!.data;
      }
    }
  }

  async _fetchDenominacionesCliente(
    params: IClienteDenominacionParams
  ): Promise<IDenominacion[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IDenominacion[] | undefined, IResponseError>(
      Vue.$axios.get(
        `${BACKEND_BASE_URL}/api/cliente/` +
          params.idCliente +
          `/divisa/` +
          params.idDivisa +
          `/denominacion`,
        { headers }
      )
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-denominacione-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _fetchAllClienteXDelegacion(idDelegacion: number): Promise<ICliente[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<ICliente[], IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/cliente/delegacion/${idDelegacion}`, { headers })
    );
    if (error) {
      EventBus.$emit("on-service-saldosonline-clientes-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _fetchAllClienteXDelegacionAndDivisa(
    idDelegacion: number,
    idDivisa: number
  ): Promise<ICliente[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<ICliente[], IResponseError>(
      Vue.$axios.get(
        `${BACKEND_BASE_URL}/api/cliente/delegacion/${idDelegacion}/divisa/${idDivisa}`,
        { headers }
      )
    );
    if (error) {
      EventBus.$emit("on-service-saldosonline-clientes-error", error.data);
      return undefined;
    } else {
      return response?.data?.sort((a, b) => a.desCliente.localeCompare(b.desCliente));
    }
  }

  async _fetchClientesByPais(idPais: number): Promise<ICliente[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };

    const { response, error } = await handleResponse<ICliente[], IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/cliente/pais/` + idPais, { headers })
    );

    if (error) {
      EventBus.$emit("on-service-saldosonline-clientes-error", error.data);
      return undefined;
    } else {
      return response!.data;
    }
  }
}
