
import { Component, Prop, Vue } from "vue-property-decorator";

import VueApexCharts from "vue-apexcharts";

Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
@Component
export default class RadialBar extends Vue {
  @Prop({ required: true })
  divisor!: number;
  @Prop({ required: true })
  dividendo!: number;
  @Prop({ required: true })
  label!: string;

  series = [(this.divisor / this.dividendo) * 100];
  chartOptions = {
    chart: {
      type: "radialBar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: 0,
        endAngle: 360,
        hollow: {
          margin: 0,
          size: "90%",
          //background: '#fff',
          //image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: false,
          },
        },
        track: {
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: false,
          },
        },
        dataLabels: {
          show: false,
        },
      },
    },
    colors: ["#ffd102"],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        gradientToColors: ["#4c4c4c"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["Percent"],
  };
}
