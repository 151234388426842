
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IPais, IParametro } from "@/entities";
import { Getter } from "vuex-class";
import { Locale } from "@/store";
import VueMethods from "@/vue-methods";
import { ICargaFilter } from "@/services";

@Component({
  mixins: [VueMethods],
})
export default class CargaFilterForm extends Vue {
  @Getter("getLocale") locale!: Locale;
  @Getter("getPaises") paises!: IPais[];

  @Prop({ required: true }) value!: ICargaFilter;
  @Prop({ required: false, default: {} }) optionsCertificados!: { key: string; value: string }[];
  @Prop({ required: false, default: {} }) optionEstados!: IParametro[];
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.valueLocal = JSON.parse(JSON.stringify(this.value));
    this.$set(
      this.valueLocal,
      "fecCarga",
      this.valueLocal.fecCarga
        ? new Date(this.valueLocal.fecCarga)
        : this.getThreeDate(new Date().toISOString())
    );
  }
  @Prop({ required: false })
  actionName!: string;
  @Prop({ required: false }) textActionName!: string;

  validado = false;

  valueLocal: ICargaFilter = {
    codFilename: "",
    fecCarga: "",
    estadoSelected: "",
    paises: [],
    codCliente: "",
    codDelegacion: "",
    fecFichero: "",
    fechaNormalizacion: "",
    tipoCertificado: "",
    codigoCertificado: "",
  };
  initialValueLocal: ICargaFilter = {
    codFilename: "",
    fecCarga: this.getThreeDate(new Date().toISOString()) ?? "",
    estadoSelected: "",
    paises: [],
    codCliente: "",
    codDelegacion: "",
    fecFichero: "",
    fechaNormalizacion: "",
    tipoCertificado: "",
    codigoCertificado: "",
  };

  get paisesAll(): IPais[] {
    let paisesList: IPais[] = [];
    if (this.paises) {
      this.paises?.forEach((pais) => {
        paisesList.push(pais);
      });
    }
    return paisesList.sort((a, b) => this.sortAscending(a, b, "codPaisCorto"));
  }

  limpiar(): void {
    this.valueLocal.codFilename = "";
    this.valueLocal.fecCarga = "";
    this.valueLocal.estadoSelected = "";
    this.valueLocal.paises = [];
    this.valueLocal.codCliente = "";
    this.valueLocal.codDelegacion = "";
    this.valueLocal.fecFichero = "";
    this.valueLocal.fechaNormalizacion = "";
    this.valueLocal.tipoCertificado = "";
    this.valueLocal.codigoCertificado = "";

    if (!this.isInitialObject(this.value, this.initialValueLocal)) {
      this.$emit("input", this.valueLocal);
      this.$emit("cancel", { action: "clean" });
    }
  }
  action(action: string): void {
    this.$emit("input", this.valueLocal);
    this.$emit("action", { data: this.valueLocal, action: action });
  }
}
