
import { ISaldosQuickFilter } from "@/pages/saldos/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { ICliente, IClienteAll, IDivisa, IPuntoservicio } from "@/entities";
import { Dictionary } from "vue-router/types/router";
import { IMovimientoParams } from "@/services";
import { todosCliente } from "@/constans";

@Component({
  name: "quick-filter",
})
export default class QuickFilter extends Vue {
  @Prop() public params!: IMovimientoParams;
  @Prop({ required: false, default: false }) isBCR!: boolean;

  @Action("setClienteSelected") setClienteSelected!: (cliente?: ICliente) => Promise<void>;
  @Action("setSaldosPageQuickFilter") setSaldosPageQuickFilter!: (
    filter: ISaldosQuickFilter
  ) => void;

  @Getter("getSaldosPageQuickFilter") getSaldosPageQuickFilter!: ISaldosQuickFilter;
  @Getter("getClientesAll") getClientesAll!: IClienteAll[];
  @Getter("getClienteSelected") getClienteSelected!: ICliente;
  @Getter("getClienteXDivisa") getClienteXDivisa!: IDivisa[];

  private admin = false;

  public divisa: IDivisa[] = [];

  private optionTodos = todosCliente;

  @Watch("clienteSelected")
  @Watch("divisa")
  onModelChanged(): void {
    this.setSaldosPageQuickFilter({
      id: this.clienteSelected,
      divisa: this.divisa,
    });
  }

  get clienteSelected(): number {
    let cliente = this.getClienteSelected;
    const clienteId = cliente?.id ?? this.params.pkCliente ?? this.optionTodos.id!;
    return this.getCliente(clienteId)?.id ?? this.optionTodos.id!;
  }

  set clienteSelected(clienteId: number) {
    if (clienteId && clienteId !== this.optionTodos.id) {
      this.setClienteSelected(this.getCliente(clienteId)).then(() => {
        this.onChangeCliente(this.clienteSelected);
      });
    } else {
      this.setClienteSelected().then(() => {
        this.onChangeCliente();
      });
    }
  }

  getCliente(clienteId: number): ICliente {
    return this.clientes.find((cliente) => cliente.id === clienteId) as ICliente;
  }

  get clientes(): ICliente[] {
    let clientes = Object.assign([], this.getClientesAll);
    if (this.isBCR) {
      clientes = clientes.filter((cliente: ICliente) => cliente.pais.bcr);
    }
    if (clientes.length > 1) {
      clientes.unshift(this.optionTodos);
    }

    return clientes;
  }

  get divisas(): IDivisa[] {
    return this.getClienteXDivisa;
  }

  get isAllDivisas(): boolean {
    return this.divisa.length === 0;
  }
  set isAllDivisas(all: boolean) {
    if (all) {
      this.divisa.forEach((divisa) => {
        divisa.selected = false;
      });

      this.divisa = [];
    }
  }

  public async mounted(): Promise<void> {
    this.admin = this.clientes.length > 1;

    if (!this.getClienteSelected) {
      const idCliente = this.params.pkCliente ?? (this.admin ? -1 : this.clientes[0].id!);
      const cliente = this.getCliente(idCliente);
      if (cliente && cliente.id !== -1) {
        await this.setClienteSelected(cliente);
      } else {
        await this.setClienteSelected();
      }
    }
    this.divisa = this.getSaldosPageQuickFilter.divisa;
    this.divisa.forEach((divisa) => {
      divisa.selected = true;
    });
  }

  async onChangeCliente(clienteId?: number): Promise<void> {
    if (clienteId && clienteId !== -1) {
      this.params.pkCliente = clienteId;
      const cliente = this.getClienteSelected;
      const delegacionesCliente = cliente.clientexdels?.map((cxd) => cxd.delegacion);
      const delegacionesAEliminar =
        this.params.pkDelegacion?.filter((pk) => !delegacionesCliente.find((d) => pk === d.id)) ||
        [];

      delegacionesAEliminar.forEach((delegacion) =>
        this.params.pkDelegacion?.splice(this.params.pkDelegacion?.indexOf(delegacion), 1)
      );
      this.$emit("resetClient", this.params.pkDelegacion || []);
      if ("fkPuntoServ" in this.params) {
        const puntosCliente: IPuntoservicio[] | undefined = cliente.clientexdels
          ?.map((cxd) => cxd.puntosservicio)
          .map((ps) => ps)
          .flat();
        const puntosAEliminar =
          this.params.fkPuntoServ?.filter((fk) => !puntosCliente?.find((p) => p.id === fk)) || [];

        puntosAEliminar.forEach((punto) =>
          this.params.fkPuntoServ?.splice(this.params.fkPuntoServ?.indexOf(punto), 1)
        );
      }
    } else {
      this.$emit("resetClient", []);

      if (this.params.pkDelegacion) {
        this.params.pkDelegacion.length = 0;
      } else {
        this.params.pkDelegacion = [];
      }

      if ("fkPuntoServ" in this.params) {
        if (this.params.fkPuntoServ) {
          this.params.fkPuntoServ.length = 0;
        } else {
          this.params.fkPuntoServ = [];
        }
      }
      delete this.params["pkCliente"];
    }
    this.$router
      .replace({
        path: this.$route.path,
        query: { ...this.params } as Dictionary<any>,
      })
      .catch(() => {
        // Do nothing
      });

    this.resetDivisas();
  }

  onClickDivisa(divisa: IDivisa): void {
    const index = this.divisa.indexOf(divisa);
    if (index > -1) {
      divisa.selected = false;
      this.divisa.splice(index, 1);
    } else {
      divisa.selected = true;
      this.divisa.push(divisa);
    }
  }

  resetDivisas(): void {
    this.divisas.forEach((it) => {
      it.selected = false;
    });

    this.divisa = [];
  }
}
