import { IGettersMock } from "@/store";

export const mockGetters = (): IGettersMock => ({
  getLocale: jest.fn(),
  getCurrentLocation: jest.fn(),
  getSignedIn: jest.fn(),
  getLoggedUser: jest.fn(),
  getColumnas: jest.fn(),
  getSaldosPageQuickFilter: jest.fn(),
  getClientes: jest.fn(),
  getClientesAll: jest.fn(),
  getClienteSelected: jest.fn(),
  getClienteXDivisa: jest.fn(),
  getCountryConfig: jest.fn(),
  getDelegacionesCliente: jest.fn(),
  getDivisas: jest.fn(),
  getTransferenciasPageQuickFilter: jest.fn(),
  getCostes: jest.fn(),
  getDisclaimer: jest.fn(),
});
