
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import PIcon from "../PIcon/PIcon.vue";

@Component({
  components: { PIcon },
})
export class PFormInputTextSearch extends Vue {
  @Prop({ required: true }) value!: string;
  @Watch("value", { immediate: true })
  onChangeValue(): void {
    this.valueLocal = this.value;
  }
  @Prop({ required: false, default: "" }) placeholder!: string;
  @Prop({ required: false, default: "" }) label!: string;
  @Prop({ required: false, default: false }) readonly!: boolean;
  @Prop({ required: false, default: true }) show!: boolean;
  @Ref("inputRef") readonly inputRef!: any;
  valueLocal = "";
  showInput = false;
  mounted(): void {
    this.showInput = this.show;
  }
  get classComp(): string {
    if (this.valueLocal != "") {
      return "search_PFormInputTextSearch active_PFormInputTextSearch";
    } else {
      return "search_PFormInputTextSearch";
    }
  }
  input(): void {
    this.$emit("input", this.valueLocal);
  }
  click(): void {
    this.showInput = true;
    this.$nextTick(() => {
      this.inputRef.focus();
    });
  }
  mouseleave(): void {
    if (this.valueLocal === "" && !this.show) {
      this.showInput = false;
    }
  }
}
export default PFormInputTextSearch;
