
import { Component, Prop, Vue } from "vue-property-decorator";
import { IDelegacion, IOfertaDemanda } from "@/entities";
import VueMethods from "@/vue-methods";

@Component({
  name: "delegaciones-opcionales",
  mixins: [VueMethods],
})
export default class DelegacionesOpcionales extends Vue {
  @Prop({ required: true }) ofertaDemanda!: IOfertaDemanda;

  get delegaciones(): IDelegacion[] {
    return (
      this.ofertaDemanda.ubicaciones
        ?.map((ubicacion) => {
          return ubicacion.delegacion;
        })
        .filter((delegacion) => delegacion.id !== this.ofertaDemanda.clientexdel.delegacion.id)
        .sort((a, b) => this.sortAscending(a, b, "desDelegacion")) ?? []
    );
  }

  get delegacionesString(): string {
    return this.delegaciones.map((delegacion) => delegacion.desDelegacion).join(", ");
  }
}
