import { IClientesServiceMock } from "@/services";

export const mockClientesService = (): IClientesServiceMock => ({
  _fetchValidateCliente: jest.fn(),
  _fetchCliente: jest.fn(),
  _fetchClientes: jest.fn(),
  _fetchClientesAll: jest.fn(),
  _fetchAllClienteXDivisa: jest.fn(),
  _saveCliente: jest.fn(),
  _fetchDenominacionesCliente: jest.fn(),
  _fetchAllClienteXDelegacion: jest.fn(),
  _fetchAllClienteXDelegacionAndDivisa: jest.fn(),
  _fetchClientesByPais: jest.fn(),
});
