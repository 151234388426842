
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { ICliente, IDelegacion, IField, ISoConfColumnasUpdateDTO, IUsuario } from "@/entities";
import { Action, Getter } from "vuex-class";
import VueFilters from "@/vue-filters";
import SelectColumns from "@/components/common/SelectColumns/SelectColumns.vue";
import QuickFilter from "@/components/documentacion/common/QuickFilter.vue";
import { EventBus } from "@/event-bus";

import {
  EDirection,
  IColumnaResponse,
  IDocumentacionImportacionFilters,
  IDocumentacionImportacionParams,
  IDocumentacionImportacionResponse,
} from "@/services";
import { IconDownload, IconTrash } from "@/components/look";
import { IDocumentoByImportacionTableItem } from "@/entities/centros-efectivo-backend/documento.types";
import VueMethods from "@/vue-methods";
import { Dictionary } from "vue-router/types/router";
import DocumentacionImportacionTableFilters from "@/components/documentacion/DocumentacionImportacionTableFilters.vue";
import { SweetAlertResult } from "sweetalert2";
import Pagination from "@/components/common/Pagination.vue";

@Component({
  components: {
    Pagination,
    IconTrash,
    DocumentacionImportacionTableFilters,
    IconDownload,
    SelectColumns,
    QuickFilter,
  },
  mixins: [VueFilters, VueMethods],
})
export default class DocumentosImportacionTable extends Vue {
  @Getter("getClienteSelected") getClienteSelected!: ICliente;
  @Getter("getDelegacionesCliente") delegations!: IDelegacion[];
  @Action("saveColumna") saveColumna!: (
    params: ISoConfColumnasUpdateDTO
  ) => Promise<IColumnaResponse | undefined>;

  @Prop({ required: false, default: {} })
  fieldsI!: IField[];

  @Prop({ required: true, default: {} })
  params!: IDocumentacionImportacionParams;

  public codTable = "documentosImportados";
  private ERROR = "ERROR";
  private PENDIENTE = "PENDIENTE";
  private PENDIENTE_APROBACION = "PENDIENTE_APROBACION";
  private PROCESADO = "PROCESADO";

  public filtersDocumentos: IDocumentacionImportacionFilters = {
    documentTitle: this.params.documentTitle,
    documentType: this.params.documentType,
    delegationsSelected: this.params.delegaciones || [],
    fechaArchivoInicio: this.params.fechaArchivoInicio,
    fechaArchivoFin: this.params.fechaArchivoFin,
    fechaSubidaInicio: this.params.fechaSubidaInicio,
    fechaSubidaFin: this.params.fechaSubidaFin,
    usuario: this.params.usuario,
    estado: this.params.estado,
    aprobadoPor: this.params.aprobadoPor,
  };

  public mostrarFiltros = false;
  private documentacionResponse?: IDocumentacionImportacionResponse | null = null;
  private documentacionResponseList?: IDocumentoByImportacionTableItem[] = [];
  public sortDesc = this.params.direction === EDirection.DESC;

  async mounted(): Promise<void> {
    EventBus.$on("update-documentos-importacion-table", () => {
      this.getDocumentos();
    });
  }

  async loadInfo(): Promise<void> {
    const delegacionsSelected = this.delegations?.filter((d) =>
      this.params.delegaciones?.find((code) => code === d.codDelegacionSol)
    );

    this.params.delegaciones = delegacionsSelected.map((d) => d.codDelegacionSol);
    this.filtersDocumentos.delegationsSelected = delegacionsSelected.map((d) => d.codDelegacionSol);

    await this.getDocumentos();
  }

  get items(): IDocumentoByImportacionTableItem[] {
    return this.documentacionResponseList || [];
  }

  async onChangeQuickFilter(): Promise<void> {
    await this.loadInfo();
  }

  public get visibleFields(): IField[] {
    return this.fieldsI.filter((item) => item.visible);
  }

  get fields(): IField[] {
    return JSON.parse(JSON.stringify(this.fieldsI));
  }

  set fields(fields: IField[]) {
    this.$emit("update:fieldsI", fields);
  }

  get dameTextoBotonFiltros(): string {
    let numFiltros = 0;
    Object.keys(this.filtersDocumentos).forEach((key: string) => {
      if (
        (this.filtersDocumentos as any)[key] != "" &&
        (this.filtersDocumentos as any)[key] != null
      ) {
        numFiltros = numFiltros + 1;
      }
    });

    return numFiltros
      ? `(${numFiltros}) ${this.$t("label.filtros")}`
      : this.$t("label.filtros").toString();
  }

  onChangeCurrentPage(currentPage: number): void {
    this.params.page = currentPage;
    this.getDocumentacion();
  }

  onSortingChange(sort: { sortBy: string; sortDesc: boolean }): void {
    this.$nextTick(async () => {
      this.params.direction = sort.sortDesc ? EDirection.DESC : EDirection.ASC;
      await this.getDocumentacion();
      await this.saveColumnsConfig();
    });
  }

  async saveColumnsConfig(): Promise<void> {
    const params: ISoConfColumnasUpdateDTO = {
      codColumna: this.visibleFields
        .filter((f) => !f.aux)
        .map((f) => f.code)
        .toString(),
      codTabla: this.codTable,
      codTipoOrden: this.params.direction,
      numPagElements: this.params.size,
      codOrden: this.params.orderBy,
    };

    await this.saveColumna(params);
  }

  async getDocumentacion(): Promise<void> {
    this.params.pkCliente = this.getClienteSelected?.id?.toString();

    this.$nextTick(() => {
      this.$router
        .replace({ path: this.$route.path, query: { ...this.params } as Dictionary<any> })
        .catch(() => {
          // Do nothing
        });
    });

    const params = JSON.parse(JSON.stringify(this.params)) as IDocumentacionImportacionParams;
    params.page = params.page! - 1 ?? 0;

    this.documentacionResponse = await this.$services.documentacion._fetchImportacion(params);
    this.documentacionResponseList = [];
    this.documentacionResponse?.content.forEach((documentacion) => {
      this.documentacionResponseList?.push({
        id: documentacion.id,
        documentTitle: documentacion.documentTitle,
        documentType: documentacion?.documentType,
        codCliente: documentacion.cliente?.codCliente.toString(),
        cliente: documentacion.cliente?.desCliente,
        delegacion: documentacion.delegacion?.desDelegacion,
        fechaArchivo: documentacion.fechaArchivo,
        fechaSubida: documentacion.fechaSubida,
        usuario: documentacion.usuario?.codUsuario.toString(),
        message: documentacion.message,
        estado: this.estadoDescripcion(documentacion.estado),
        mensajeEstado: documentacion.mensajeEstado,
        aprobadoPor: this.aprobadoPorDescripcion(
          documentacion.estado,
          documentacion.usuarioAprobador
        ),
      });
    });
  }

  async download(doc: IDocumentoByImportacionTableItem): Promise<void> {
    const response = await this.$services.documentacion.downloadFileOfImportation(doc);

    if (response) {
      this.downloadFile(response);
    } else {
      this.makeToast("danger", this.$t("label.documentation.download.error").toString());
    }
  }

  async remove(doc: IDocumentoByImportacionTableItem): Promise<void> {
    const { isConfirmed } = await this.modalConfirmDeleteDocument();
    if (isConfirmed) {
      await this.$services.documentacion.removeImported(doc);
      await this.getDocumentacion();
      this.makeToast("success", this.$t("document.removed").toString());
    }
  }

  async approve(doc: IDocumentoByImportacionTableItem): Promise<void> {
    const { isConfirmed } = await this.modalConfirmApproveDocument();
    if (isConfirmed) {
      await this.$services.documentacion.approveImported(doc);
      await this.getDocumentacion();
      this.makeToast("success", this.$t("document.approved").toString());
    }
  }

  action(action: { action: string }): void {
    if (action.action === "filtrar" || action.action === "clean") {
      this.params.fechaArchivoInicio = this.getStartDate(this.filtersDocumentos.fechaArchivoInicio);
      this.params.fechaArchivoFin = this.getEndDate(this.filtersDocumentos.fechaArchivoFin);
      this.params.fechaSubidaInicio = this.getStartDate(this.filtersDocumentos.fechaSubidaInicio);
      this.params.fechaSubidaFin = this.getEndDate(this.filtersDocumentos.fechaSubidaFin);
      this.params.delegaciones = this.filtersDocumentos.delegationsSelected;
      this.params.documentType = this.filtersDocumentos.documentType ?? undefined;
      this.params.documentTitle = this.filtersDocumentos.documentTitle ?? undefined;
      this.params.usuario = this.filtersDocumentos.usuario ?? undefined;
      this.params.estado = this.filtersDocumentos.estado ?? undefined;
      this.params.aprobadoPor = this.filtersDocumentos.aprobadoPor ?? undefined;

      this.mostrarFiltros = false;
      this.getDocumentos();
    }
  }

  async modalConfirmDeleteDocument(): Promise<SweetAlertResult> {
    return this.$swal({
      title: this.$t("label.documentation.deletion").toString(),
      text: this.$t("label.documentation.deletion.confirmation.message").toString(),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: this.$t("label.yes").toString(),
      cancelButtonText: this.$t("label.no").toString(),
    });
  }

  private async modalConfirmApproveDocument() {
    return this.$swal({
      title: this.$t("label.documentation.approval").toString(),
      text: this.$t("label.documentation.approval.confirmation.message").toString(),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: this.$t("label.yes").toString(),
      cancelButtonText: this.$t("label.no").toString(),
    });
  }

  async getDocumentos(): Promise<void> {
    this.params.page = 1;
    await this.getDocumentacion();
  }

  hasAnyValidationError(item: IDocumentoByImportacionTableItem): boolean {
    return item.estado.toUpperCase() === this.ERROR;
  }

  isApprovalPending(item: IDocumentoByImportacionTableItem): boolean {
    return item.estado === this.$t("label.estado.documento.pendiente-aprobacion").toString();
  }

  extractErrorMessage(item: IDocumentoByImportacionTableItem): string {
    if (item.mensajeEstado) {
      const toLowerCase = item.mensajeEstado.toLowerCase();
      const key = `file.validationError.${toLowerCase}`;
      return this.$te(key, this.$i18n.fallbackLocale.toString())
        ? this.$t(key).toString()
        : item.mensajeEstado;
    }
    return "";
  }
  private estadoDescripcion(estado: string): string {
    let res = estado;
    if (estado == this.PENDIENTE) {
      res = this.$t("label.estado.documento.pendiente").toString();
    } else if (estado == this.PENDIENTE_APROBACION) {
      res = this.$t("label.estado.documento.pendiente-aprobacion").toString();
    } else if (estado == this.PROCESADO) {
      res = this.$t("label.estado.documento.procesado").toString();
    } else if (estado == this.ERROR) {
      res = this.$t("error").toString();
    }
    return res;
  }

  private aprobadoPorDescripcion(estado: string, aprobadoPor?: IUsuario): string {
    if (![this.PENDIENTE, this.PROCESADO].includes(estado)) {
      return "";
    }
    return aprobadoPor?.codUsuario ?? this.$t("label.documento.aprobacion-automatica").toString();
  }
}
